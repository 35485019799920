<!-- eslint-disable no-param-reassign -->
<!-- eslint-disable no-param-reassign -->
<template>
  <div v-if="showPublishTest === true">
    <div class="component-heading flex flex-row">
      <button class="section-button">5</button>
      <div class="flex flex-column mt-2">
        <span class="content-heading">Test Setup & Publish</span>
      </div>
    </div>
    <div style="background: #f7f8fd; padding: 20px 25px 20px 40px">
      <span style="padding: 15px" class="fw-700 fs-14">Generation Type</span>
      <div style="padding: 15px" class="flex flex-wrap">
        <button
          class="course-button"
          :class="{ active: createTestData.questionGeneration === 0 }"
          @click="setManual(0)"
        >
          Automatic question generation
        </button>
        <button
          class="course-button"
          :class="{ active: createTestData.questionGeneration === 1 }"
          @click="setManual(1)"
        >
          Manual question selection
        </button>
      </div>
    </div>
    <div class="flex between flex-wrap" style="padding: 20px 25px 20px 55px">
      <div class="">
        <button class="next-button" @click="next()">NEXT</button>
      </div>
      <div>
        <button class="cancel-main-button" @click="cancel()">CANCEL</button>
      </div>
    </div>
    <Modal2 :show="showNoQuestionModal" :showHeader="false" :showCloseBtn="false">
      <template v-slot:body>
        <div class="no-question-modal">
          <div class="flex content-cntr" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" />
          </div>
          <span class="flex content-cntr fs-20 fw-600 ln-27" style="margin-bottom: 8px">{{
            this.messages.messageTitle
          }}</span>
          <div class="flex content-cntr" style="padding-left: 40px">
            <span
              class="mesage-body fs-13 fw-400 ln-17"
              style="margin-bottom: 25px; white-space: pre-line; width: 424px"
            >
              {{ this.messages.messageBody }}
              <!-- <span class="flex content-cntr fs-13 fw-400 ln-17">You will not be able to resume this session again.</span> -->
            </span>
          </div>
          <div class="flex content-even" v-if="this.messages.buttons.length === 2">
            <button
              class="end-cancel-button fs-13 fw-600 ln-18"
              @click="
                noQuestionModal = false;
                cancelManualTest();
              "
            >
              {{ this.messages.buttons[0].text }}
            </button>
            <button
              class="end-confirm-button fs-13 fw-600 ln-18"
              @click="
                noQuestionModal = false;
                goForward();
              "
            >
              {{ this.messages.buttons[1].text }}
            </button>
          </div>
          <div class="flex content-even" v-if="this.messages.buttons.length === 1">
            <button
              class="cancel-button fs-13 fw-600 ln-18"
              @click="
                showNoQuestionModal = false;
                cancelManualTest();
              "
              style=""
            >
              OK, I WILL PICK A DIFFERENT SUBJECT / CHAPTER
            </button>
          </div>
        </div>
      </template>
    </Modal2>
  </div>
</template>

<script>
import moment from "moment";
import Modal2 from "./Model2.vue";
import Mobileapi from "../Mobileapi";
import DataApi from "../Dataapi";

export default {
  data() {
    return {
      createTestData: "",
      isAutomatic: "",
      testResponse: "",
      showPublishTest: true,
      showNoQuestionModal: false,
      messages: [],
      questionList: [],
    };
  },
  props: {
    isPublishTestOpen: String,
    instituteTestData: String,
  },
  components: {
    Modal2,
  },
  mounted() {
    this.emitter.on("close-batch-component", (data) => {
      this.showCourse = data.showCourse;
      this.showTest = data.showTest;
      this.showBatchSelect = data.showBatchSelect;
      this.showManageSection = data.showManageSection;
      this.showPublishTest = data.showPublishTest;
    });
    this.emitter.on("open-test-component", (data) => {
      this.showCourse = data.showCourse;
      this.showTest = data.showTest;
      this.showBatchSelect = data.showBatchSelect;
      this.showManageSection = data.showManageSection;
      this.showPublishTest = data.showPublishTest;
    });
    this.emitter.on("open-course-component", (data) => {
      this.showCourse = data.showCourse;
      this.showTest = data.showTest;
      this.showBatchSelect = data.showBatchSelect;
      this.showManageSection = data.showManageSection;
      this.showPublishTest = data.showPublishTest;
    });
    this.emitter.on("open-section-component", (data) => {
      this.showCourse = data.showCourse;
      this.showTest = data.showTest;
      this.showBatchSelect = data.showBatchSelect;
      this.showManageSection = data.showManageSection;
      this.showPublishTest = data.showPublishTest;
    });
    this.emitter.on("open-batch-component", (data) => {
      this.showCourse = data.showCourse;
      this.showTest = data.showTest;
      this.showBatchSelect = data.showBatchSelect;
      this.showManageSection = data.showManageSection;
      this.showPublishTest = data.showPublishTest;
    });
    this.emitter.on("changes-batch-data", (data) => {
      this.createTestData = "";
      if (data.isDataChanged === true) {
        this.createTestData = JSON.parse(data.testMainData);
      }
    });
  },
  created() {
    if (this.instituteTestData !== undefined) {
      this.createTestData = JSON.parse(this.instituteTestData);
    }
  },
  methods: {
    setManual(data) {
      this.createTestData.questionGeneration = data;
    },
    next() {
      if (
        this.createTestData != null &&
        this.createTestData.parts != null &&
        this.createTestData.parts.length > 0
      ) {
        this.createTestData.parts.forEach((element) => {
          // eslint-disable-next-line no-param-reassign
          element.topics = [];
          // eslint-disable-next-line no-param-reassign
          element.chapters = [];
          // eslint-disable-next-line no-param-reassign
          element.subjects = [];

          // eslint-disable-next-line no-param-reassign
          element.subjectTopicChapterDetails = [];
        });
      }
      this.createTestData.startDateTime = moment().add(30, "minute").format("DD-MM-YYYY h:mma");
      this.createTestData.endDateTime = moment().add(1, "days").format("DD-MM-YYYY h:mma");
      this.$store.dispatch("showLoader", true);
      Mobileapi.addUpdateInstituteTestV2(this.createTestData, (response) => {
        this.$store.dispatch("showLoader", false);
        if (response.responseCode === 200) {
          this.testResponse = response.data;
          // console.log("abcd", this.testResponse);
          if (Number(this.testResponse) > 0 && this.createTestData.questionGeneration === 0) {
            this.$store.dispatch("showLoader", true);
            Mobileapi.createTestPreviewQuestions(
              {
                instituteTestId: Number(this.testResponse),
              },
              (question) => {
                if (question.responseCode === 200) {
                  this.questionList = question.data;
                  if (
                    question.data.userMessages !== undefined &&
                    question.data.userMessages.length > 0
                  ) {
                    this.messages = question.data.userMessages[0];
                    this.showNoQuestionModal = true;
                    this.$store.dispatch("showLoader", false);
                  } else {
                    this.questionList = question.data;
                    this.$emit("questionData", this.questionList);
                    this.$emit("instituteTestId", this.testResponse);
                    this.$emit("isManual", this.createTestData.questionGeneration);
                    this.$store.dispatch("showLoader", false);
                  }
                }
              },
            );
          }
          if (Number(this.testResponse) > 0 && this.createTestData.questionGeneration === 1) {
            this.$emit("instituteTestId", this.testResponse);
            this.$emit("isManual", this.createTestData.questionGeneration);
            this.$store.dispatch("showLoader", false);
          }
        }
        if (response.responseCode === 500) {
          window.M.toast({
            html: `${response.data}`,
          });
        }
      });
    },
    cancel() {
      this.$forceUpdate();
      this.emitter.emit("open-course-component", {
        showCourse: true,
        showTest: undefined,
        showBatchSelect: undefined,
        showManageSection: undefined,
        showPublishTest: false,
      });
    },
    goForward() {
      this.$emit("questionData", this.questionList);
      this.$emit("instituteTestId", this.testResponse);
      this.$emit("isManual", this.createTestData.questionGeneration);
    },
    cancelManualTest() {
      this.$store.dispatch("showLoader", true);
      DataApi.deleteInstituteTest(
        {
          instituteTestId: this.selectedInstituteTestId,
        },
        () => {
          this.showNoQuestionModal = false;
          this.$store.dispatch("showLoader", false);
        },
        (error) => {
          console.log(error);
          this.showNoQuestionModal = false;
          this.$store.dispatch("showLoader", false);
        },
      );
    },
  },
};
</script>

<style scoped>
span {
  margin: 0;
}
.mt-2 {
  margin-top: -2px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.pd20 {
  padding: 20px !important;
}
.flex {
  display: flex !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.between {
  justify-content: space-between !important;
}
.content-cntr {
  justify-content: center !important;
}
.flex-end {
  justify-content: flex-end !important;
}
.fs-12 {
  font-size: 12 !important;
}
.fs-14 {
  font-size: 14 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.main-section {
  border: 1px solid #e0e4f0;
}
.close-component-heading {
  height: 48px;
  background: #ffffff;
  border-bottom: 1px solid #e0e4f0;
  padding-left: 22px;
  padding-top: 14px;
  overflow-x: hidden;
}
.close-section-button {
  width: 20px;
  height: 20px;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #3751ff;
  background: #ffffff;
}
.close-section-heading {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #3751ff;
  margin-left: 18px;
  min-width: 100px;
}
.blk-text {
  color: #404458 !important;
}
.createtest {
  min-width: 500px;
  width: 100%;
  border-radius: 5px;
  margin-right: 20px;
}
.component-heading {
  background: #ffffff;
  height: 65px;
  padding: 15px;
  border-bottom: 1px solid #e0e4f0;
}
.section-button {
  border: none;
  width: 33px;
  height: 33px;
  background: #3751ff;
  border-radius: 3px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-right: 12px;
}
.content-heading {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #3751ff;
}
.content-main-section {
  height: 626px;
}
.content-left-section {
  background: #ffffff;
  width: 307px !important;
  border-right: 1px solid #e0e4f0;
}
.content-right-section {
  background: #f7f8fd;
  width: 100%;
}
.content-selection-button {
  width: 223px;
  height: 50px;
  background: #f7f8fd;
  border-radius: 4px;
  margin-top: 10px;
  margin-right: 24px;
  padding-top: 8px;
  padding-left: 15px;
  position: relative;
  border: none;
}
.content-selection-button.active {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #3751ff;
}
.content-selection-button.active::after {
  position: absolute;
  width: 3px;
  height: 50px;
  content: "";
  background: #3751ff;
  right: 0;
  top: 0;
  border-radius: 0 2px 2px 0;
}

.content-search {
  margin-left: 10px;
  margin-right: 10px;
  height: 40px;
  border: 1px solid #e0e4f0;
  background: white;
  border-radius: 4px;
  margin-top: 20px;
  justify-content: center;
  align-content: center;
  position: relative;
  border: none;
}
.course-button {
  height: 28px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 18px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #333333;
  cursor: pointer;
}
.course-button.active {
  background: #3751ff !important;
  color: #ffffff;
}
.course-button:hover {
  background: #3751ff !important;
  color: #ffffff;
}
.selected {
  background: #3751ff !important;
  color: #ffffff;
}
.next-button {
  width: 179px;
  height: 40px;
  background: #37841c;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  margin-right: 30px;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.seperator {
  width: 0px;
  height: 15px;
  border-left: 2px solid #e0e4f0;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 3px;
}
.subject-name {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #3751ff;
}
.subject-name-header {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
}
.topic-name {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #3751ff;
}
.save-template-button {
  width: 179px;
  height: 40px;
  background: #f3f5fc;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  color: #3751ff;
  border: none;
}
.cancel-button {
  /* width: 125px; */
  padding-right: 5px;
  padding-left: 5px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #ff7675;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ff7675;
  cursor: pointer;
}
.cancel-main-button {
  width: 125px;
  padding-right: 5px;
  padding-left: 5px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #ff7675;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ff7675;
  cursor: pointer;
}
::-webkit-scrollbar {
  display: none;
}
input[type="text"]:not(.browser-default):focus:not([readonly]) {
  box-shadow: none;
  border-bottom: none;
  height: 39px;
}

input[type="text"]:not(.browser-default):focus:not([readonly]) {
  border-bottom: none !important;
  height: 39px;
}

input[type="text"]:not(.browser-default) {
  padding-right: 41px;
  max-width: 200px;
  width: 100%;
  border-bottom: none !important;
  height: 39px !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  /* margin: 0px !important; */
}

input[type="number"]:not(.browser-default) {
  border-bottom: none;
  width: 95%;
  /* margin: 0px !important; */
  height: 39px !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
}

input[type="number"]:not(.browser-default):focus:not([readonly]) {
  border-bottom: none;
  box-shadow: none;
}

input[type="date"]:not(.browser-default) {
  border-bottom: none;
  width: 92% !important;
}

input[type="date"]:not(.browser-default):focus:not([readonly]) {
  border-bottom: none;
  box-shadow: none;
  width: 92% !important;
}

input[type="time"]:not(.browser-default) {
  border-bottom: none;
  width: 92% !important;
}

input[type="time"]:not(.browser-default):focus:not([readonly]) {
  border-bottom: none;
  box-shadow: none;
  width: 92% !important;
}
.no-question-modal {
  width: 450px;
  height: 356px;
  background: #ffffff;
  border-radius: 10px;
}
.content-even {
  justify-content: space-evenly !important;
}
.end-confirm-button {
  /* width: 114px; */
  height: 34px;
  background: #ffffff;
  border: 1px solid #ff7675;
  border-radius: 4px;
  color: #ff7675;
  cursor: pointer;
}

.end-confirm-button:hover {
  background: #ff7675 !important;
  color: #ffffff !important;
}
.end-cancel-button {
  /* width: 114px; */
  height: 34px;
  background: #ffffff;
  border: 1px solid #37841c;
  border-radius: 4px;
  background: #ffffff;
  color: #37841c;
  cursor: pointer;
}

.end-cancel-button:hover {
  background: #37841c !important;
  color: #ffffff !important;
}

@media screen and (max-width: 500px) {
  .next-button {
    width: 125px;
  }
}
@media screen and (max-width: 361px) {
  .cancel-main-button {
    margin-top: 10px;
  }
}
</style>
