<template>
  <div class="manual-test box-shadow box-border radius-5">
    <!-- <h3 class="dark-text fs-18 fw-700 main-head">Create Test</h3> -->
    <!-- <div class="box-shadow box-border radius-5"> -->
    <div class="manual-top flex items-center white">
      <div
        class="flex items-center box-border-right"
        style="width: 215px; padding: 16px 40px 16px 16px"
        title="back"
      >
        <img
          :src="require(`../assets/back-arrow.svg`)"
          class="pointer"
          alt="go back"
          @click="
            cancelManualTestModal = true;
            cancelText = 'go back';
          "
        />
        <h3 class="skyblue-text fs-16 fw-700 m-0 nowrap" style="margin-left: 12px">
          Select Question
        </h3>
      </div>
      <h3
        class="heading flex items-center relative dark-text m-0 fs-14 fw-400 pointer nowrap h-100"
        :class="{ active: showInstruction }"
        @click="
          showInstruction = true;
          sectionId = -1;
        "
      >
        Test Instructions
      </h3>
      <div
        class="sections scroll-horizontal h-100"
        v-dragscroll="true"
        v-if="sectionList.length > 0"
      >
        <h3
          class="heading flex items-center relative dark-text m-0 fs-14 fw-400 h-100 pointer nowrap"
          :class="{ active: item.InstituteTestPartId === sectionId }"
          @click="goToSection(item, index)"
          v-for="(item, index) in sectionList"
          :key="index"
        >
          {{ item.SectionName !== "" ? item.SectionName : `Section-${index + 1}` }}
          <a class="tooltip" data-tooltip="I am a tooltip">
            <img
              :src="require(`../assets/Warning-new.svg`)"
              class="red-text pointer warning-img"
              v-if="sectionList[index].QuestionCount < sectionList[index].NoOfQuestions"
              style="width: 20px; margin-left: 8px; margin-top: 2px"
            />
            <span class="tooltiptext">You have not selected total question of this section.</span>
          </a>
        </h3>
      </div>
      <div class="manual-search hide">
        <input type="text" placeholder="Search from questiion" />
        <img class="search_img" :src="require(`../assets/Search.svg`)" alt="Search" />
      </div>
    </div>
    <!-- Test Instructions -->
    <div class="" style="height: calc(100vh - 245px)">
      <div class="previewBody_content" v-if="showInstruction && testSummary.length !== 0">
        <TestInstructionComponent :instituteTestId="instituteTestId" />
      </div>
      <!-- Manual Contents -->
      <div class="manual-contents flex h-100" v-if="!showInstruction">
        <div class="filter-sec white">
          <div class="filter-body">
            <div class="w-100 h-100 white">
              <p class="fs-16 fw-700 dark-font" style="padding-left: 15px">Filter</p>
              <div
                class="flex flex-column scroll-verticle-bar"
                :style="
                  qnsTypeIndex === 2 ? 'height:calc(100vh - 460px)' : 'height:calc(100vh - 301px)'
                "
              >
                <div class="filter-item filter-chapter">
                  <CommonFilterComponent
                    id="chapterFilter"
                    :entityDataObject="chapterDetails[currSecIndex]"
                    :showAll="false"
                    :isSingleSelect="true"
                    :key="selCourseChapterId"
                    :selectedId="selCourseChapterId"
                    @selectedEntityId="filterByChapter($event)"
                    v-if="chapterDetails[currSecIndex] && selCourseChapterId"
                  />
                </div>
                <div class="filter-item filter-chapter" v-if="qnsTypeIndex !== 3">
                  <CommonFilterComponent
                    id="chapterFilter"
                    :entityDataObject="Object.keys(kscClusterData).length > 0 ? kscClusterData : {}"
                    :key="kscClusterData"
                    @selectedEntityId="filterByKscCluster($event)"
                    v-if="Object.keys(kscClusterData).length > 0"
                  />
                </div>

                <DifficultLevelComponent
                  :selectedClusterIdQuestionslist="qnsListForDiffGrp"
                  :key="qnsListForDiffGrp"
                  @selectedDiffLevel="filterByDiff($event)"
                  v-if="qnsTypeIndex !== 3"
                />
                <div
                  class="filter-item hide"
                  style="padding: 15px 15px 15px 15px"
                  v-if="qnsTypeIndex == 2"
                >
                  <div class="flex flex-between">
                    <label class="pointer dark-text fs-13 fw-700" for="used-qns"
                      >Exclude Used Questions</label
                    >
                    <input
                      type="checkbox"
                      :checked="isExcludeUsedQns"
                      id="used-qns"
                      @change="excludeUsedQns()"
                      style="top: 3px"
                    />
                  </div>
                </div>
              </div>
              <div
                class="filter-item"
                style="padding: 20px 20px 20px 17px"
                :class="{ hide: qnsTypeIndex !== 2 }"
              >
                <div class="flex flex-between">
                  <label class="pointer dark-text fs-13 fw-700" for="past-year-qns"
                    >Past Year Questions</label
                  >
                  <input
                    type="checkbox"
                    v-model="isPastYearQns"
                    :checked="isPastYearQns"
                    id="past-year-qns"
                    @change="pastYearQns()"
                    style="top: 3px"
                  />
                </div>
              </div>
              <div
                class="filter-item"
                v-if="qnsTypeIndex === 2 && this.toggleChecked"
                style="padding: 15px"
              >
                <input
                  type="text"
                  v-model="pdfUuid"
                  placeholder="PDF Uuid"
                  class="input-control"
                  style="width: 150px; height: 30px"
                />
                <button
                  class="end-cancel-button"
                  style="width: 100px; height: 30px"
                  @click="submitPdfUUID()"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="question-sec">
          <div class="flex flex-between">
            <div class="manual-middle w-100">
              <div class="flex flex-between flex-wrap items-center">
                <div class="flex" style="gap: 10px">
                  <a
                    class="manual-qns-btn"
                    :class="{ active: qnsTypeIndex === 1 }"
                    @click="getRecommendedData(1)"
                    >Recommended
                  </a>
                  <a
                    class="manual-qns-btn"
                    :class="{ active: qnsTypeIndex === 2 }"
                    @click="
                      qnsCategoryId = 5;
                      getAllQns(2);
                    "
                    >All
                  </a>
                  <a
                    class="manual-qns-btn"
                    :class="{ active: qnsTypeIndex === 3 }"
                    @click="getInstituteQns(3)"
                    >Institute
                  </a>
                  <div
                    class="flex items-center"
                    style="
                      width: 100%;
                      justify-content: space-around;
                      margin-bottom: 10px;
                      border: 1px solid #e0e4f0;
                      background: white;
                      border-radius: 5px;
                      border: 1px solid #e0e4f0;
                      padding: 0 10px;
                    "
                    v-if="qnsTypeIndex === 2"
                  >
                    <span class="flex question-header flex-right">All Qs</span>
                    <label class="switch">
                      <input
                        type="checkbox"
                        @click="toggleButton($event)"
                        :checked="toggleChecked"
                      />
                      <div class="slider round"></div>
                    </label>
                    <span class="question-header">Test Qs</span>
                  </div>

                  <span
                    class="material-icons material-symbols-outlined pointer"
                    v-if="qnsTypeIndex === 3"
                    @click="getInstituteQns(3)"
                    title="Refresh"
                    style="margin-top: 5px"
                    >cached</span
                  >
                </div>
                <div class="flex">
                  <router-link
                    class="manual-qns-btn active"
                    :to="{ name: 'AddCustomQuestion' }"
                    target="_blank"
                    v-if="qnsTypeIndex === 3"
                    style="margin-right: 10px"
                    >Add Custom Question</router-link
                  >
                  <div
                    class="flex manual-qns-btn items-center"
                    style="width: 231px; height: 30px; padding: 4px 8px; padding-right: 0"
                  >
                    <img
                      :src="require(`../assets/sort 1.svg`)"
                      alt=""
                      class="pointer"
                      @click="sortQnsList()"
                    />
                    <span class="sort-by relative nowrap">Sort By</span>
                    <div class="select-box">
                      <div class="select-header" @click="showDropdown = !showDropdown">
                        <p class="fs-13 fw-600 m-0" style="color: #3751ff; margin-left: 5px">
                          {{ sortText }}
                        </p>
                        <img
                          :src="require(`../assets/downarrow-1.svg`)"
                          alt=""
                          style="margin-right: 5px"
                        />
                      </div>
                      <div
                        class="select-dropdown"
                        v-if="showDropdown && loadedQuestions.length > 0"
                      >
                        <p @click="sortQuestionList('Difficulty')" v-if="qnsTypeIndex !== 3">
                          Difficulty
                        </p>
                        <p @click="sortQuestionList('Concept')" v-if="qnsTypeIndex !== 3">
                          Concept
                        </p>
                        <p @click="sortQuestionList('Recently Uploaded')" v-if="qnsTypeIndex !== 1">
                          Recently Uploaded
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="manual-bottom w-100">
                <h3 class="fs-12 m-0 fw-600 dark-text" style="margin: 10px 0 15px">
                  Questions: {{ questionDetails.length }}
                </h3>
                <!-- Questions -->
                <div class="scroll-verticle qns-list" v-if="loadedQuestions.length > 0">
                  <ManualQuestion
                    v-for="item in loadedQuestions"
                    :key="item.QuestionId"
                    :selectedDiffGrp="
                      item.DifficultyGroup ? item.DifficultyGroup.toLowerCase() : 'easy'
                    "
                    :Difficulty="item.DifficultyGroup ? item.DifficultyGroup.toLowerCase() : 'easy'"
                    :QuestionDetail="item"
                    @click="selectQns(item)"
                    :isQustionSelected="
                      selectedQnsData[currSecIndex].includes(item.QuestionId) ? true : false
                    "
                    :SelectedQns="checkSelectedQns(item)"
                  />
                  <h3
                    class="fs-20 fw-600 skyblue-text pointer text-center"
                    v-if="questionDetails.length > loadedQnsLength && qnsTypeIndex === 2"
                    @click="loadMoreQns()"
                  >
                    Load more ...
                  </h3>
                </div>
                <div style="text-align: center; max-width: 450px" class="flex items-center" v-else>
                  <span
                    class="material-icons material-symbols-outlined red-text"
                    style="font-size: 60px"
                    >error</span
                  >
                  <p
                    class="fs-18 red-text fw-600"
                    style="margin-left: 10px !important; text-align: left"
                  >
                    No questions available for the selected filters.
                  </p>
                </div>
              </div>
            </div>
            <div class="selected-que-detail radius-5 white h-100">
              <div style="padding: 10px; border-bottom: 1px solid #e0e4f0">
                <div class="top radius-5 flex flex-column items-center flex-center">
                  <img :src="require(`../assets/check-circle 1.svg`)" alt="" />
                  <h3
                    class="m-0 fs-12 fw-400 white-text truncate"
                    style="margin-top: 5px; max-width: 80%"
                  >
                    {{ sectionList[currSecIndex].SectionName }}
                  </h3>
                  <h3 class="m-0 fs-14 fw-700 white-text" style="margin-top: 5px !important">
                    Question Selected
                  </h3>
                  <h3 class="m-0 top-counter radius-5 white fs-16 fw-700 skyblue-text text-center">
                    {{ sectionList[currSecIndex].QuestionCount }} <span>/</span>
                    {{ sectionList[currSecIndex].NoOfQuestions }}
                  </h3>
                </div>
              </div>
              <div class="white radius-5 scroll-verticle" style="max-height: calc(100vh - 450px)">
                <div class="bottom" v-for="(item, index) in chapterDetails" :key="index">
                  <div
                    class="flex flex-between items-center pointer pb-15"
                    @click="showQnsIndicator(index)"
                  >
                    <h3 class="m-0 fs-13 fw-700 skyblue-text">
                      {{ sectionList[index].SubjectName }}
                    </h3>
                    <div class="flex items-center">
                      <h3 class="m-0 sub-counter fs-10 fw-600 white-text">
                        {{ sectionList[index].QuestionCount }}
                      </h3>
                      <span
                        class="material-icons material-symbols-outlined pointer"
                        style="color: #333333; opacity: 0.6; font-size: 20px; margin-left: 15px"
                        >{{ qnsIdicatorIndex === index ? "expand_less" : "expand_more" }}</span
                      >
                    </div>
                  </div>
                  <div class="chapter-content scroll-verticle" v-if="qnsIdicatorIndex === index">
                    <div
                      class="chapter-detail"
                      v-for="(chapterDetail, index) in chapterDetails[index].entityArray"
                      :key="index"
                    >
                      <p class="fs-12 m-0 fw-400 dark-text" style="white-space: break-spaces">
                        {{
                          chapterDetail.ChapterName.length > 55
                            ? chapterDetail.ChapterName.slice(0, 55) + ".."
                            : chapterDetail.ChapterName
                        }}
                      </p>
                      <div class="flex items-center" style="gap: 20px; margin-top: 12px">
                        <h6 class="m-0 flex items-center diff-counter" style="gap: 5px">
                          <span class="block diff-indicator"></span
                          ><span class="fs-10 fw-600 black-text">{{ chapterDetail.Easy }}</span>
                        </h6>
                        <h6 class="m-0 flex items-center diff-counter" style="gap: 5px">
                          <span class="block diff-indicator"></span
                          ><span class="fs-10 fw-600 black-text">{{ chapterDetail.Normal }}</span>
                        </h6>
                        <h6 class="m-0 flex items-center diff-counter" style="gap: 5px">
                          <span class="block diff-indicator"></span
                          ><span class="fs-10 fw-600 black-text">{{ chapterDetail.Hard }}</span>
                        </h6>
                        <h3
                          class="m-0 sub-counter fs-10 fw-600 skyblue-text"
                          style="background: #f3f5fc"
                        >
                          {{ chapterDetail.Easy + chapterDetail.Normal + chapterDetail.Hard }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Manual Footer -->
    <div class="flex w-100 flex-between items-center white manual-footer">
      <div class="flex flex-between">
        <a
          class="draft-btn radius-5 fs-12 fw-600 text-center uppercase white-text"
          style="margin-right: 30px"
          @click="createDraft()"
          >Create Draft</a
        >
        <a
          class="publish-btn radius-5 fs-12 fw-600 text-center uppercase white-text"
          @click="openPublishModal()"
          :class="{ disable: totalQuestions !== questionIdCsv.length }"
          >Publish Now</a
        >
      </div>
      <a
        class="cencel-btn fs-12 radius-5 fw-600 text-center uppercase"
        @click="
          cancelManualTestModal = true;
          cancelText = 'cancel';
        "
        >Cancel</a
      >
    </div>
    <!-- </div> -->
    <Modal2
      :show="showErrorModal"
      :showHeader="false"
      :showCloseBtn="false"
      @click="showErrorModal = false"
      :radius="'5px !important'"
    >
      <template v-slot:body>
        <div
          style="padding: 25px; text-align: center; max-width: 450px; min-width: 320px"
          class="flex items-center"
        >
          <span class="material-icons material-symbols-outlined red-text" style="font-size: 60px"
            >error</span
          >
          <p
            class="fs-18 red-text fw-600"
            style="white-space: break-spaces; margin-left: 10px !important; text-align: left"
          >
            {{ errorMsg }}
          </p>
        </div>
      </template>
    </Modal2>

    <Modal2
      :show="cancelManualTestModal"
      :showHeader="false"
      :showCloseBtn="false"
      @close="cancelManualTestModal = false"
    >
      <template v-slot:body>
        <div class="delete-section-modal">
          <div class="flex content-cntr" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" />
          </div>
          <span class="flex content-cntr fs-20 fw-600 ln-27" style="margin-bottom: 8px"
            >Warning</span
          >
          <div class="flex content-cntr" style="flex-direction: column">
            <span
              class="mesage-body fs-13 fw-400 ln-17 flex content-cntr"
              style="white-space: pre-line"
            >
              All unsaved progress will be lost.
            </span>
            <span
              class="mesage-body fs-13 fw-400 ln-17 flex content-cntr"
              style="white-space: pre-line"
            >
              Are you sure want to {{ cancelText }} ?
            </span>
          </div>
          <div class="flex content-even" style="padding: 20px">
            <button class="end-cancel-button fs-13 fw-600 ln-18" @click="cancelManualTest()">
              YES
            </button>
            <button
              class="end-confirm-button fs-13 fw-600 ln-18"
              @click="cancelManualTestModal = false"
            >
              NO
            </button>
          </div>
        </div>
      </template>
    </Modal2>
    <Modal3
      :show="publishTestModal"
      :showHeader="false"
      :showCloseBtn="false"
      @close="conceptModal = false"
    >
      <template v-slot:body>
        <div class="concept-modal">
          <div class="flex flex-between publish-head">
            <span class="fs-16 fw-700" style="margin-top: 2px">Publish Test</span>
            <div @click="publishTestModal = false" style="cursor: pointer">
              <span class="material-icons publish-close" style="color: #ffffff !important"
                >close</span
              >
            </div>
          </div>
          <div class="modal-content">
            <div class="flex flex-between">
              <div class="flex column">
                <span class="fs-14 fw-700">Test Name</span>
                <input
                  type="text"
                  v-model="title"
                  placeholder="Test Name"
                  style="border-bottom: 1px solid #e0e4f0 !important"
                />
              </div>
            </div>
            <div class="flex flex-between">
              <div class="flex column" style="margin-top: 10px">
                <span class="fs-14 fw-700">Start DateTime</span>
                <v-date-picker
                  v-model="testStartDate"
                  mode="dateTime"
                  :min-date="minDate"
                  :minute-increment="5"
                  is-required
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      class="browser-default date-column"
                      style="border: 1px solid #e0e4f0 !important"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly
                    />
                  </template>
                </v-date-picker>
              </div>
              <div class="flex column" style="margin-top: 10px">
                <span class="fs-14 fw-700">End DateTime</span>
                <v-date-picker
                  v-model="testEndDate"
                  mode="dateTime"
                  :min-date="minDate"
                  :minute-increment="5"
                  is-required
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      class="browser-default date-column"
                      style="border: 1px solid #e0e4f0 !important"
                      :value="inputValue"
                      v-on="inputEvents"
                      readonly
                    />
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="flex column" style="margin-top: 10px">
              <span class="fs-14 fw-700 mb8">Duration <span class="red-text">*</span></span>
              <div class="flex flex-row mt8">
                <button
                  class="duration-button"
                  :class="{ selected: duration === '30' }"
                  @click="selectTime('30')"
                  value="30"
                >
                  30min
                </button>
                <button
                  class="duration-button"
                  :class="{ selected: duration === '45' }"
                  @click="selectTime('45')"
                  value="45"
                >
                  45min
                </button>
                <button
                  class="duration-button"
                  :class="{ selected: duration === '60' }"
                  @click="selectTime('60')"
                  value="60"
                >
                  60min
                </button>
                <button
                  class="duration-button"
                  :class="{ selected: duration === '90' }"
                  @click="selectTime('90')"
                  value="90"
                >
                  1hr 30min
                </button>
                <button
                  class="duration-button"
                  :class="{ selected: duration === '120' }"
                  @click="selectTime('120')"
                  value="120"
                >
                  2hrs
                </button>
                <button
                  class="duration-button"
                  :class="{ selected: duration === '180' }"
                  @click="selectTime('180')"
                  value="180"
                >
                  3hrs
                </button>
                <button
                  class="duration-button"
                  :class="{ selected: duration === '200' }"
                  @click="selectTime('200')"
                  value="200"
                >
                  3hr 20min
                </button>
              </div>
            </div>
            <div class="flex column" style="margin-top: 10px">
              <span class="fs-14 fw-700">Batches <span class="red-text">*</span></span>
              <div class="course-btn-container" v-if="this.batchListNew.length > 0">
                <div class="scroll-section">
                  <div v-for="(data, index1) in this.batchListNew" :key="index1" class="mt10">
                    <div class="flex flex-between items-center" style="padding-right: 20px">
                      <p class="subject-name" v-if="data.batches.length > 0">
                        {{ data.AffiliationName }}
                      </p>
                      <div class="select-all flex items-center" v-if="data.batches.length > 0">
                        <input
                          type="checkbox"
                          :id="index1 + 'chapter'"
                          class="pointer publish-input"
                          :checked="checkIsAllBatchSelected(index1, data.AffiliationId)"
                          @change="selectAllBatch($event, data, index1, data.AffiliationId)"
                          name="chapter"
                        />
                        <label :for="index1 + 'chapter'" class="pointer fw-400 fs-13 dark-text"
                          >Select All</label
                        >
                      </div>
                    </div>
                    <div class="flex mt10" style="flex-wrap: wrap">
                      <button
                        class="course-button"
                        :class="{ selected: batchIdList.includes(topic) }"
                        v-for="(topic, index) in data.batches"
                        :key="index"
                        @click="onSelectBatch(topic, data.Name, index1)"
                      >
                        {{ topic.Name }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex column" style="margin-top: 10px">
                <div class="flex">
                  <input
                    type="checkbox"
                    id="shuffle'"
                    class="pointer"
                    :checked="isShuffleQuestion === 1"
                    @change="shuffleQuestion($event)"
                    name="chapter"
                  />
                  <label
                    for="shuffle'"
                    class="pointer fs-14 fw-700"
                    style="color: #333333 !important; margin-left: 5px"
                    >Shuffle Questions</label
                  >
                </div>
              </div>
              <span
                v-if="batchError === true"
                style="font-size: 11px; font-style: italic; color: red"
                >Please Select at least one batch</span
              >
              <span
                v-if="questionsError === true"
                style="font-size: 11px; font-style: italic; color: red"
                >Please Select all the questions to publish the test</span
              >
              <div class="flex items-center" style="padding-top: 10px">
                <button class="publish-modal-button" @click="publishTest()">Publish</button>
                <button
                  class="cencel-btn"
                  style="font-size: 12px; font-weight: 600; background: #ffffff; border-radius: 5px"
                  @click="publishTestModal = false"
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal3>
    <Modal
      :show="showPdfUidModal"
      :showHeader="false"
      :showCloseBtn="false"
      :width="'100%'"
      :height="'100%'"
      :maxHeightBody="'800px'"
    >
      <template v-slot:body>
        <div class="flex flex-between publish-head">
          <span class="fs-16 fw-700" style="margin-top: 2px">Select PDF Questions</span>
          <div @click="showPdfUidModal = false" style="cursor: pointer">
            <span class="material-icons publish-close" style="color: #ffffff !important"
              >close</span
            >
          </div>
        </div>
        <div class="modalHeader flex flex-between" style="border-bottom: 1px solid #e0e4f0">
          <div>
            <h6 style="font-size: 18px; font-weight: 700; padding-left: 28px">PDF Questions</h6>
          </div>
        </div>
        <div class="flex flex-between" style="padding-left: 28px; padding-right: 25px">
          <div>
            <h6 style="padding-bottom: 5px; font-size: 15px; font-weight: 600">
              Total Questions: {{ this.pdfQuestions.length }}
            </h6>
          </div>
          <div>
            <h6 style="padding-bottom: 5px; font-size: 15px; font-weight: 600">
              Question Selected: {{ sectionList[currSecIndex].QuestionCount }}
            </h6>
          </div>
          <!-- <div style="margin-top: 11px; margin-bottom: 7px;"><button class="loadMoreQuestionsButton" @click="loadMoreQuestions()">Load More Questions</button></div> -->
        </div>
        <div class="row">
          <div class="w-100 col s12 l9">
            <div class="scroll-verticle-bar" v-if="pdfQuestions.length > 0">
              <ManualQuestion
                v-for="item in pdfQuestions"
                :key="item.QuestionId"
                :selectedDiffGrp="
                  item.DifficultyGroup ? item.DifficultyGroup.toLowerCase() : 'easy'
                "
                :Difficulty="item.DifficultyGroup ? item.DifficultyGroup.toLowerCase() : 'easy'"
                :QuestionDetail="item"
                @click="selectQns(item)"
                :isQustionSelected="
                  selectedQnsData[currSecIndex].includes(item.QuestionId) ? true : false
                "
                :SelectedQns="checkSelectedQns(item)"
              />
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import { useToast } from "vue-toastification";
import MobileApi from "../Mobileapi";
import DataApi from "../Dataapi";
import TestInstructionComponent from "../components/TestInstructionComponent.vue";
import ManualQuestion from "../components/ManualQuestion.vue";
import Modal3 from "../components/Modal3.vue";
import Modal2 from "../components/Model2";
import Modal from "../components/Modal.vue";
import CommonFilterComponent from "../components/CommonFilterComponent.vue";
import DifficultLevelComponent from "../components/DifficultLevelComponent.vue";

const toast = useToast();

export default {
  data() {
    return {
      affiliationId: null,
      showInstruction: true,
      selectedDiffGrp: [],
      kscClusterData: [],
      recommendedData: [],
      tempRecommendedData: [],
      manualQnsData: [],
      instituteQnsData: [],
      qnsTypeIndex: 1,
      sectionList: [],
      sectionId: -1,
      chapterDetails: [],
      currSecIndex: 0,
      qnsIdicatorIndex: null,
      questionDetails: [],
      FilteredQnsList: [],
      selectedQnsData: [],
      serialNumber: 0,
      questionIdCsv: [],
      isExcludeUsedQns: false,
      isPastYearQns: false,
      sortText: "Select",
      diffiSortTrigger: true,
      kscClusterSortTrigger: true,
      recentlyUploadedSortTrigger: true,
      cancelManualTestModal: false,
      showErrorModal: false,
      errorMsg: "",
      selCourseChapterId: null,
      selChapterId: null,
      selKscClusterId: [],
      selectedCourseId: null,
      selSubjectId: null,
      totalQuestion: null,
      marksPerQuestion: null,
      batches: null,
      qnsTypeId: null,
      difficultyLevelId: null,
      isDifficultyTriggered: false,
      loadedQnsLength: 20,
      qnsListForDiffGrp: [],
      publishTestModal: false,
      minDate: moment().utcOffset("-00:00").startOf("day").toDate(),
      maxDate: moment().utcOffset("-00:00").add(3, "months").toDate(),
      startDate: moment().utcOffset("-00:00").add(30, "minutes").toDate(),
      endDate: moment().utcOffset("-00:00").add(7, "days").toDate(),
      testStartDate: "",
      testEndDate: "",
      publishBatches: [],
      batchIdList: [],
      isShuffleQuestion: 0,
      duration: "",
      title: "",
      batchError: false,
      totalQuestions: null,
      totalSelectedQuestions: null,
      questionsError: false,
      testDetails: [],
      testSummary: [],
      showDropdown: false,
      qnsCategoryId: 1,
      toggleChecked: true,
      pdfUuid: "",
      showPdfUidModal: false,
      pdfQuestions: [],
      selectedCommonQuestions: [],
      batchListNew: [],
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#F6F8FB");
  },
  components: {
    TestInstructionComponent,
    ManualQuestion,
    Modal2,
    Modal3,
    CommonFilterComponent,
    DifficultLevelComponent,
    Modal,
  },
  props: {
    instituteTestId: Number,
  },
  created() {
    this.affiliationId = this.$store.getters.user.AffiliationId;
    this.$store.dispatch("showLoader", true);
    MobileApi.getInstituteTestSummary(
      {
        instituteTestId: this.instituteTestId,
      },
      (response) => {
        this.testSummary = response.data;
        sessionStorage.setItem("summaryData", JSON.stringify(this.testSummary));
        this.$store.dispatch("showLoader", false);
        if (this.testSummary !== null || this.testSummary !== undefined) {
          this.getSummaryData(this.testSummary);
        }
      },
    );
  },
  computed: {
    loadedQuestions() {
      if (this.qnsTypeIndex !== 1) {
        return this.questionDetails.slice(0, this.loadedQnsLength);
      }
      return this.questionDetails;
    },
  },
  methods: {
    groupBatchesByAffiliation(chapterlist) {
      debugger;
      const batchListTemp = {};

      // eslint-disable-next-line no-restricted-syntax
      for (const chapter of chapterlist) {
        const affiliationId = chapter.AffiliationId;

        if (!batchListTemp[affiliationId]) {
          batchListTemp[affiliationId] = {
            AffiliationId: affiliationId,
            AffiliationName: chapter.AffiliationName,
            batches: [],
          };
        }

        batchListTemp[affiliationId].batches.push({
          Id: chapter.Id,
          Code: chapter.Code,
          Name: chapter.Name,
          AffiliationId: affiliationId,
        });
      }
      this.batchListNew = Object.values(batchListTemp);
    },
    checkIsAllBatchSelected(index, clusterId) {
      debugger;
      let returnData = false;
      const batchListTemp = this.batchIdList.filter((a) => a.AffiliationId === clusterId);
      const batchObj = this.batchListNew.filter((a) => a.AffiliationId === clusterId)[0];
      // ///this.tempClusterList = this.clusterListNew.filter((chapter) => batchListTemp.includes(chapter.Id));
      // const subjectChapters = this.tempClusterList.find((a) => a.CourseChapterId === clusterId);
      // const selectedChapters = this.selectedKscClusterIds.filter((a) => a.CourseChapterId === clusterId);
      if (
        batchListTemp != null &&
        batchObj.batchList != null &&
        batchObj.batchList.length > 0 &&
        batchListTemp.length > 0
      ) {
        if (batchObj.batchList.length === batchListTemp.length) {
          returnData = true;
        }
      }
      return returnData;
    },
    selectAllBatch(event, chapterData, index, subjectId) {
      debugger;
      if (event.target.checked) {
        const topicList = chapterData.batches.flat();
        topicList.forEach((item) => {
          if (!this.batchIdList.includes(item)) {
            this.batchIdList.push(item);
          }
        });
      } else {
        // eslint-disable-next-line no-plusplus, no-lonely-if
        if (this.batchIdList.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let i = this.batchIdList.length - 1; i >= 0; i--) {
            if (this.batchIdList[i].AffiliationId === subjectId) {
              this.batchIdList.splice(i, 1);
            }
          }
        }
      }
      this.checkIsAllBatchSelected(index, chapterData.AffiliationId);
    },
    onSelectBatch(selectedBatch, index) {
      debugger;
      this.chapterCountList = [];
      if (this.batchIdList.includes(selectedBatch)) {
        const index1 = this.batchIdList.indexOf(selectedBatch);
        this.batchIdList.splice(index1, 1);
      } else {
        this.batchIdList.push(selectedBatch);
      }
      this.checkIsAllBatchSelected(index, selectedBatch.AffiliationId);
    },
    checkSelectedQns(qnsData) {
      const selectedQnsIndex = this.questionIdCsv.findIndex(
        (item) => item.NewQuestionId === qnsData.QuestionId,
      );
      if (
        selectedQnsIndex > -1 &&
        this.questionIdCsv[selectedQnsIndex].SectionId !== this.sectionId
      ) {
        return true;
      }
      return false;
    },
    getSummaryData(summaryData) {
      this.chapterDetails = [];
      this.sectionList = [];
      this.$store.dispatch("showLoader", false);
      if (summaryData.testSectionDetails.length > 0) {
        this.sectionList = summaryData.testSectionDetails;
        this.sectionList = this.sectionList.map((item) => ({
          ...item,
          QuestionCount: 0,
          totalSelQns: 0,
        }));
        const courseChapterDetails = summaryData.courseChapterDetails;
        const chapterDetails = [];
        this.totalQuestions = null;
        this.totalSelectedQuestions = null;
        this.selectedCourseId = summaryData.testDetails[0].CourseId;
        this.batches = summaryData.testDetails[0].Batches;
        this.title = summaryData.testDetails[0].Title;
        this.duration = String(summaryData.testDetails[0].Duration);
        this.testDetails = summaryData.testDetails[0];
        this.sectionList.forEach((item) => {
          chapterDetails.push([]);
          this.selectedQnsData.push([]);
          this.totalQuestions += item.NoOfQuestions;
          this.totalSelectedQuestions += item.SelectedQuestions;
        });
        this.sectionList.forEach((item, index) => {
          courseChapterDetails.forEach((item1) => {
            if (item.InstituteTestPartId === item1.InstituteTestPartId) {
              chapterDetails[index].push({
                ChapterName: item1.ChapterName,
                CourseChapterId: item1.CourseChapterId,
                ChapterId: item1.ChapterId,
                entityId: item1.CourseChapterId,
                entityName: item1.ChapterName,
                Easy: 0,
                Normal: 0,
                Hard: 0,
              });
            }
          });
          this.chapterDetails.push({
            Name: "Chapter", // Component Title
            entityArray: chapterDetails[index], // array
          });
        });
      }
      this.getRecommendedData(1);
    },
    goToSection(sectionData, index) {
      this.showInstruction = false;
      this.sortText = "Select";
      this.currSecIndex = index;
      this.qnsCategoryId = 5;
      this.toggleChecked = true;
      this.kscClusterData = [];
      this.isPastYearQns = false;
      this.isDifficultyTriggered = false;
      this.sectionId = sectionData.InstituteTestPartId;
      this.selSubjectId = sectionData.SubjectId;
      this.marksPerQuestion = sectionData.MarksPerQuestion;
      this.qnsTypeId = sectionData.QuestionTypeId;
      this.difficultyLevelId = sectionData.DifficultyLevelId;
      this.manualQnsData = [];
      this.selCourseChapterId =
        this.chapterDetails[this.currSecIndex].entityArray[0].CourseChapterId;
      this.selChapterId = this.chapterDetails[this.currSecIndex].entityArray[0].ChapterId;
      this.getRecommendedData(1);
    },
    getRecommendedData(tabIndex) {
      this.qnsListForDiffGrp = [];
      this.qnsTypeIndex = tabIndex;
      this.tempRecommendedData = [];
      this.sortText = "Select";
      this.showDropdown = false;
      this.isDifficultyTriggered = false;
      this.questionDetails = [];
      if (this.recommendedData.length === 0) {
        this.$store.dispatch("showLoader", true);
        MobileApi.createTestPreviewQuestions(
          {
            instituteTestId: this.instituteTestId,
          },
          (response) => {
            this.$store.dispatch("showLoader", false);
            if (Object.keys(response.data).length > 0 && response.responseCode === 200) {
              this.recommendedData = response.data.questions;
            } else {
              this.recommendedData = [];
            }
          },
        );
      } else {
        this.recommendedData.forEach((item) => {
          if (item.sectionId === this.sectionId) {
            this.tempRecommendedData = item.questions;
          }
        });
        if (this.tempRecommendedData.length > 0) {
          this.tempRecommendedData = this.tempRecommendedData.filter(
            (item) => item.CourseChapterId === this.selCourseChapterId,
          );
          if (this.tempRecommendedData.length > 0) {
            const kscClusterData = Array.from(
              new Set(this.tempRecommendedData.map((item) => item.KSCClusterId)),
            ).map((KSCClusterId) => ({
              entityId: KSCClusterId,
              entityName: this.tempRecommendedData.find(
                (item) => item.KSCClusterId === KSCClusterId,
              ).KSCClusterName,
            }));
            this.kscClusterData = {
              Name: "Concept",
              entityArray: kscClusterData,
            };
          } else {
            this.kscClusterData = [];
          }
          this.questionDetails = this.tempRecommendedData;
          this.FilteredQnsList = [...this.questionDetails];
          if (this.sectionId !== -1 && this.questionDetails.length === 0) {
            this.questionDetails = [];
          }
        } else {
          this.tempRecommendedData = [];
          this.questionDetails = [];
        }
        this.qnsListForDiffGrp = this.tempRecommendedData;
      }
    },
    getAllQns(tabIndex) {
      this.qnsListForDiffGrp = [];
      this.qnsTypeIndex = tabIndex;
      this.isDifficultyTriggered = false;
      this.loadedQnsLength = 20;
      this.sortText = "Select";
      this.showDropdown = false;
      this.$store.dispatch("showLoader", true);
      MobileApi.getCreatTestManualQuestion(
        {
          courseId: this.selectedCourseId,
          batches: this.batches,
          courseChapterId: this.selCourseChapterId,
          isManual: 1,
          questionType: this.qnsTypeId,
          questionCategoryId: this.qnsCategoryId,
          instituteTestId: this.instituteTestId ? this.instituteTestId : 0,
        },
        (response) => {
          this.$store.dispatch("showLoader", false);
          if (response.data.length > 0 && response.responseCode === 200) {
            this.manualQnsData = response.data.map((item) => ({
              ...item,
              CreatedOn: item.CreatedOn ? moment(item.CreatedOn).format("X") : 0,
            }));
            this.qnsListForDiffGrp = this.manualQnsData;
            const kscClusterData = Array.from(
              new Set(this.manualQnsData.map((item) => item.KSCClusterId)),
            ).map((KSCClusterId) => ({
              entityId: KSCClusterId,
              entityName: this.manualQnsData.find((item) => item.KSCClusterId === KSCClusterId)
                .KSCClusterName,
            }));
            this.kscClusterData = {
              Name: "Concept",
              entityArray: kscClusterData,
            };
            if (this.manualQnsData.length > 0) {
              this.questionDetails = this.manualQnsData;
              this.FilteredQnsList = [...this.questionDetails];
            } else {
              this.questionDetails = [];
              this.$store.dispatch("showLoader", false);
            }
          } else {
            this.questionDetails = [];
            this.$store.dispatch("showLoader", false);
          }
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    getInstituteQns(tabIndex) {
      this.qnsTypeIndex = tabIndex;
      this.isDifficultyTriggered = false;
      this.loadedQnsLength = 20;
      this.sortText = "Select";
      this.showDropdown = false;
      this.$store.dispatch("showLoader", true);
      MobileApi.getCreatTestManualQuestion(
        {
          courseId: this.selectedCourseId,
          batches: this.batches,
          courseChapterId: this.selCourseChapterId,
          isManual: 0,
          questionType: this.qnsTypeId,
          instituteTestId: this.instituteTestId ? this.instituteTestId : 0,
        },
        (resp) => {
          this.$store.dispatch("showLoader", false);
          this.instituteQnsData = resp.data;
          if (this.instituteQnsData.length > 0 && resp.responseCode === 200) {
            this.questionDetails = this.instituteQnsData.map((item) => ({
              ...item,
              DifficultyGroup: "easy",
            }));
            this.questionDetails = this.questionDetails.map((item) => ({
              ...item,
              CreatedOn: item.CreatedOn ? moment(item.CreatedOn).format("X") : 0,
            }));
            this.instituteQnsData = this.questionDetails;
            this.FilteredQnsList = [...this.questionDetails];
          } else {
            this.questionDetails = [];
            this.$store.dispatch("showLoader", false);
          }
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    toggleButton(event) {
      this.toggleChecked = event.target.checked;
      if (this.toggleChecked) {
        this.qnsCategoryId = 5;
      } else {
        this.qnsCategoryId = 1;
      }
      this.getAllQns(2);
    },
    loadMoreQns() {
      if (this.qnsTypeIndex === 2) {
        this.loadedQnsLength += 20;
      }
    },
    selectQns(qnsData) {
      let chapterDetails = [];
      const selectedQnsIndex = this.questionIdCsv.findIndex(
        (item) => item.NewQuestionId === qnsData.QuestionId,
      );
      if (
        this.questionIdCsv[selectedQnsIndex]?.SectionId === this.sectionId ||
        (this.questionIdCsv.length === 0 && selectedQnsIndex === -1) ||
        selectedQnsIndex === -1
      ) {
        if (this.selectedQnsData[this.currSecIndex].includes(qnsData.QuestionId)) {
          const qnsIndex = this.selectedQnsData[this.currSecIndex].indexOf(qnsData.QuestionId);
          if (qnsIndex > -1) {
            this.selectedQnsData[this.currSecIndex].splice(qnsIndex, 1);
            this.sectionList = this.sectionList.map((item, index) => {
              if (index === this.currSecIndex) {
                return {
                  ...item,
                  QuestionCount: item.QuestionCount - 1,
                };
              }
              return item;
            });

            chapterDetails = this.chapterDetails.map((item1) =>
              item1.entityArray.map((item2) => {
                if (item2.CourseChapterId === qnsData.CourseChapterId) {
                  if (qnsData.DifficultyGroup.toLowerCase() === "easy") {
                    return {
                      ...item2,
                      Easy: item2.Easy - 1,
                    };
                  }
                  if (qnsData.DifficultyGroup.toLowerCase() === "normal") {
                    return {
                      ...item2,
                      Normal: item2.Normal - 1,
                    };
                  }
                  if (qnsData.DifficultyGroup.toLowerCase() === "hard") {
                    return {
                      ...item2,
                      Hard: item2.Hard - 1,
                    };
                  }
                }
                return item2;
              }),
            );
            this.serialNumber -= 1;
            this.questionIdCsv.forEach((item, index) => {
              if (item.NewQuestionId === qnsData.QuestionId) {
                this.questionIdCsv.splice(index, 1);
              }
            });

            this.chapterDetails = [];
            chapterDetails.forEach((item) => {
              this.chapterDetails.push({
                Name: "Chapter", // Component Title
                entityArray: item, // array
              });
            });
          }
        } else if (
          this.sectionList[this.currSecIndex].QuestionCount <
          this.sectionList[this.currSecIndex].NoOfQuestions
        ) {
          this.selectedQnsData[this.currSecIndex].push(qnsData.QuestionId);
          this.sectionList = this.sectionList.map((item, index) => {
            if (index === this.currSecIndex) {
              return {
                ...item,
                QuestionCount: item.QuestionCount + 1,
              };
            }
            return item;
          });

          chapterDetails = this.chapterDetails.map((item1, index1) =>
            item1.entityArray.map((item2) => {
              if (
                index1 === this.currSecIndex &&
                item2.CourseChapterId === qnsData.CourseChapterId
              ) {
                if (qnsData.DifficultyGroup.toLowerCase() === "easy") {
                  return {
                    ...item2,
                    Easy: item2.Easy + 1,
                  };
                }
                if (qnsData.DifficultyGroup.toLowerCase() === "normal") {
                  return {
                    ...item2,
                    Normal: item2.Normal + 1,
                  };
                }
                if (qnsData.DifficultyGroup.toLowerCase() === "hard") {
                  return {
                    ...item2,
                    Hard: item2.Hard + 1,
                  };
                }
              }
              return item2;
            }),
          );
          this.serialNumber += 1;
          this.questionIdCsv.push({
            OldQuestionId: null,
            NewQuestionId: qnsData.QuestionId,
            SectionId: this.sectionId,
            SerialNumber: this.serialNumber,
            CourseChapterId: qnsData.CourseChapterId,
            KSCClusterId: qnsData.KSCClusterId,
            DifficultyLevel: qnsData.DifficultyGroup,
            DifficultyScaled: qnsData.DifficultyScaled,
          });
          this.chapterDetails = [];
          chapterDetails.forEach((item) => {
            this.chapterDetails.push({
              Name: "Chapter", // Component Title
              entityArray: item, // array
            });
          });
        }
      }
    },
    filterByChapter(courseChapterId) {
      this.selCourseChapterId = courseChapterId;
      // this.selChapterId = chapterId;
      this.sortText = "Select";
      this.showDropdown = false;
      this.loadedQnsLength = 20;
      this.isDifficultyTriggered = false;
      this.selectedDiffGrp = [];
      this.qnsListForDiffGrp = [];
      this.isPastYearQns = false;
      if (this.qnsTypeIndex === 1) {
        this.getRecommendedData(1);
      }
      if (this.qnsTypeIndex === 2) {
        this.questionDetails = [];
        this.manualQnsData = [];
        this.getAllQns(2);
      }
      if (this.qnsTypeIndex === 3) {
        this.instituteQnsData = [];
        this.questionDetails = [];
        this.getInstituteQns(3);
      }
    },
    filterByDiff(diffGrpData) {
      this.selectedDiffGrp = diffGrpData;
      this.isDifficultyTriggered = true;
      this.loadedQnsLength = 20;
      let questionDetails = [];
      if (this.qnsTypeIndex === 1) {
        questionDetails = this.tempRecommendedData.filter((item1) =>
          this.selKscClusterId.some((item2) => item2 === item1.KSCClusterId),
        );
        this.questionDetails = questionDetails.filter((item1) =>
          this.selectedDiffGrp.some(
            (item2) => item2.toLowerCase() === item1.DifficultyGroup.toLowerCase(),
          ),
        );
      }
      if (this.qnsTypeIndex === 2) {
        questionDetails = this.manualQnsData.filter((item1) =>
          this.selKscClusterId.some((item2) => item2 === item1.KSCClusterId),
        );
        this.questionDetails = questionDetails.filter((item1) =>
          this.selectedDiffGrp.some(
            (item2) => item2.toLowerCase() === item1.DifficultyGroup.toLowerCase(),
          ),
        );
      }
      this.FilteredQnsList = [...this.questionDetails];
      if (this.isPastYearQns) {
        this.pastYearQns();
      }
      this.sortText = "Select";
    },
    filterByKscCluster(KSCClusterId) {
      this.selKscClusterId = KSCClusterId;
      this.loadedQnsLength = 20;
      let questionDetails = [];
      if (this.qnsTypeIndex === 2 && this.selKscClusterId.length > 0) {
        questionDetails = this.manualQnsData.filter((item1) =>
          this.selKscClusterId.some((item2) => item2 === item1.KSCClusterId),
        );
      }
      if (this.qnsTypeIndex === 1 && this.selKscClusterId.length > 0) {
        questionDetails = this.tempRecommendedData.filter((item1) =>
          this.selKscClusterId.some((item2) => item2 === item1.KSCClusterId),
        );
      }
      if (this.isDifficultyTriggered) {
        this.questionDetails = questionDetails.filter((item1) =>
          this.selectedDiffGrp.some(
            (item2) => item2.toLowerCase() === item1.DifficultyGroup.toLowerCase(),
          ),
        );
      } else {
        this.questionDetails = questionDetails;
      }
      this.FilteredQnsList = [...this.questionDetails];
      if (this.isPastYearQns) {
        this.pastYearQns();
      }
      this.sortText = "Select";
    },
    excludeUsedQns() {
      this.isExcludeUsedQns = !this.isExcludeUsedQns;
    },
    pastYearQns() {
      if (this.qnsTypeIndex === 2) {
        let questionDetails = this.questionDetails;
        if (this.isPastYearQns) {
          this.questionDetails = questionDetails.filter((item) => item.IsPastYearQuestion === 1);
        } else if (!this.isPastYearQns) {
          questionDetails = this.manualQnsData.filter((item1) =>
            this.selKscClusterId.some((item2) => item2 === item1.KSCClusterId),
          );
          this.questionDetails = questionDetails;
        }
        if (this.selKscClusterId.length > 0) {
          this.questionDetails = this.questionDetails.filter((item1) =>
            this.selKscClusterId.some((item2) => item2 === item1.KSCClusterId),
          );
        }
        if (this.isDifficultyTriggered) {
          this.questionDetails = this.questionDetails.filter((item1) =>
            this.selectedDiffGrp.some(
              (item2) => item2.toLowerCase() === item1.DifficultyGroup.toLowerCase(),
            ),
          );
        }
        this.FilteredQnsList = [...this.questionDetails];
        this.sortText = "Select";
      }
    },
    sortQuestionList(sortText) {
      this.sortText = sortText;
      this.sortQnsList();
    },
    sortQnsList() {
      if (this.sortText === "Difficulty") {
        if (this.diffiSortTrigger) {
          this.questionDetails = [...this.FilteredQnsList].sort(
            (a, b) => a.DifficultyScaled - b.DifficultyScaled,
          );
          this.diffiSortTrigger = false;
        } else {
          this.questionDetails = [...this.FilteredQnsList].sort(
            (a, b) => b.DifficultyScaled - a.DifficultyScaled,
          );
          this.diffiSortTrigger = true;
        }
      }
      if (this.sortText === "Concept") {
        if (this.kscClusterSortTrigger) {
          this.questionDetails = [...this.FilteredQnsList].sort(
            (a, b) => a.DisplayRank - b.DisplayRank,
          );
          this.kscClusterSortTrigger = false;
        } else {
          this.questionDetails = [...this.FilteredQnsList].sort(
            (a, b) => b.DisplayRank - a.DisplayRank,
          );
          this.kscClusterSortTrigger = true;
        }
      }
      if (this.sortText === "Recently Uploaded") {
        if (this.recentlyUploadedSortTrigger) {
          this.questionDetails = [...this.FilteredQnsList].sort(
            (a, b) => a.CreatedOn - b.CreatedOn,
          );
          this.recentlyUploadedSortTrigger = false;
        } else {
          this.questionDetails = [...this.FilteredQnsList].sort(
            (a, b) => b.CreatedOn - a.CreatedOn,
          );
          this.recentlyUploadedSortTrigger = true;
        }
      }
      this.showDropdown = false;
    },
    cancelManualTest() {
      this.$store.dispatch("showLoader", true);
      DataApi.deleteInstituteTest(
        {
          instituteTestId: this.instituteTestId,
        },
        (response) => {
          if (response) {
            this.showErrorModal = true;
            this.errorMsg = `${response.message}`;
            this.$store.dispatch("showLoader", false);
            this.cancelManualTestModal = false;
            window.location.reload();
          }
          this.$store.dispatch("showLoader", false);
        },
        (error) => {
          console.log(error);
          this.$store.dispatch("showLoader", false);
        },
      );
    },

    createDraft() {
      this.$store.dispatch("showLoader", true);
      this.questionIdCsv = this.questionIdCsv.map((item, index) => ({
        ...item,
        SerialNumber: index + 1,
      }));
      MobileApi.replaceInstituteQuestions(
        {
          instituteTestId: this.instituteTestId,
          isTestType: 1,
          questionIdCsv: this.questionIdCsv,
        },
        (response) => {
          if (response.responseCode === 200) {
            toast.success("Institute test draft created successfully!", {
              timeout: 4000,
            });
            this.$router.push({
              name: "EditInstituteTestNew",
              params: {
                testId: Number(this.instituteTestId),
              },
              query: {
                feedback: true,
              },
            });
            this.$store.dispatch("showLoader", false);
          }
          this.$store.dispatch("showLoader", false);
        },
      );
      // }
    },
    openPublishModal() {
      this.batchError = false;
      this.questionsError = false;
      this.publishTestModal = true;
      const tempBatchList = this.batches.split(",");
      this.testStartDate = moment(this.testDetails.StartDateTime, "YYYY-MM-DD h:mma").toDate();
      this.testEndDate = moment(this.testDetails.EndDateTime, "YYYY-MM-DD h:mma").toDate();
      // this.batchIdList.push(this.batches.split(","));
      this.batchIdList = this.batchIdList.map((item) => Number(item));
      this.$store.dispatch("showLoader", true);
      MobileApi.getBatches(
        {
          AffiliationId: this.$store.getters.user.AffiliationId,
          CourseId: this.selectedCourseId,
        },
        (response) => {
          this.$store.dispatch("showLoader", false);
          this.publishBatches = response.data;
          this.groupBatchesByAffiliation(this.publishBatches);
          debugger;
          // Iterate through the data array and extract batches based on Ids
          // eslint-disable-next-line no-restricted-syntax
          for (const item of this.batchListNew) {
            const batches = item.batches.filter((batch) =>
              tempBatchList.includes(String(batch.Id)),
            );
            this.batchIdList.push(...batches);
          }
        },
      );
    },
    selectBatches(data) {
      if (this.batchIdList.includes(data.Id)) {
        const index = this.batchIdList.indexOf(data.Id);
        this.batchIdList.splice(index, 1);
      } else {
        this.batchIdList.push(data.Id);
      }
    },
    selectAllBatches(event) {
      if (event.target.checked) {
        this.batchIdList = this.publishBatches.map((item) => item.Id);
      } else {
        this.batchIdList = [];
      }
    },
    shuffleQuestion(event) {
      if (event.target.checked) {
        this.isShuffleQuestion = 1;
      } else {
        this.isShuffleQuestion = 0;
      }
    },
    selectTime(time) {
      this.duration = time;
    },
    publishTest() {
      this.$store.dispatch("showLoader", true);
      if (this.batchIdList.length < 1) {
        this.batchError = true;
        this.$store.dispatch("showLoader", false);
      } else {
        this.batchError = false;
      }
      if (this.totalQuestions !== this.questionIdCsv.length) {
        this.questionsError = true;
        this.$store.dispatch("showLoader", false);
      } else if (this.title.trim() === "") {
        toast.error("Please enter the test title");
        this.$store.dispatch("showLoader", false);
      } else if (this.batchIdList.length > 0 && this.totalQuestions === this.questionIdCsv.length) {
        const data = {
          InstituteTestId: this.instituteTestId,
          StartDateTime: moment(this.testStartDate).format("YYYY-MM-DD HH:mm:ss.SSS"),
          EndDateTime: moment(this.testEndDate).format("YYYY-MM-DD HH:mm:ss.SSS"),
          Duration: Number(this.duration),
          BatchIds: this.batchIdList.map((a) => a.Id).join(","),
          Title: this.title,
          ShuffleQuestions: this.isShuffleQuestion,
        };
        this.$store.dispatch("showLoader", true);
        MobileApi.PublishInstituteTest(
          data,
          (resp) => {
            if (resp.responseCode === 200) {
              MobileApi.replaceInstituteQuestions(
                {
                  instituteTestId: this.instituteTestId,
                  isTestType: 1,
                  questionIdCsv: this.questionIdCsv,
                },
                (response) => {
                  this.$store.dispatch("showLoader", false);
                  if (response.responseCode === 200) {
                    this.publishTestModal = false;
                    toast.success("Institute test published Successfully", {
                      timeout: 4000,
                    });
                    this.$router.push({
                      // name: "ActionsInstituteTest",
                      name: "ManageInstituteTest",
                      params: { isNavigate: 0 },
                      query: { feedback: true },
                    });
                  }
                },
              );
            } else if (resp.responseCode === 500) {
              toast.error(`${resp.data.errors[0]}`, {
                timeout: 4000,
              });
              this.$store.dispatch("showLoader", false);
            }
          },
          () => {
            this.$store.dispatch("showLoader", false);
          },
        );
      }
    },
    showQnsIndicator(index) {
      if (this.qnsIdicatorIndex !== index) {
        this.qnsIdicatorIndex = index;
      } else {
        this.qnsIdicatorIndex = null;
      }
    },
    resetSortBy() {
      this.sortText = "Select";
      this.diffiSortTrigger = true;
      this.kscClusterSortTrigger = true;
      this.recentlyUploadedSortTrigger = true;
    },
    submitPdfUUID() {
      const courseChapterIds = this.sectionList[this.currSecIndex].CourseChapterIds;
      const data = {
        courseChapterIds,
        questionType: this.sectionList[this.currSecIndex].QuestionTypeId,
      };
      const pdfId = this.pdfUuid;
      MobileApi.getQuestionsFromQuestionBankPdf(pdfId, data, (response) => {
        this.$store.dispatch("showLoader", false);
        this.pdfQuestions = response.data;
        this.showPdfUidModal = true;
      });
    },
    closePdfModal() {
      this.showPdfUidModal = false;
    },
  },
  watch: {
    startDate(newVal) {
      this.testStartDate = moment(newVal).format("YYYY-MM-D HH:mm:ss");
    },
    endDate(newVal) {
      this.testEndDate = moment(newVal).format("YYYY-MM-D HH:mm:ss");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: inherit;
}

.manual-test {
  font-family: "Open Sans" !important;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 100px);
}

/* custom css */
.skyblue-text {
  color: #3751ff;
}

.dark-text {
  color: #333333;
}

.box-border {
  border: 1px solid #e0e4f0;
}

.box-border-bottom {
  border-bottom: 1px solid #e0e4f0;
}

.box-border-right {
  border-right: 1px solid #e0e4f0;
}

.box-shadow {
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
}

/* Main CSS */
.manual-top {
  border-radius: 5px 5px 0 0;
  height: 65px;
  border-bottom: 1px solid #e0e4f0;
}

.main-head {
  margin: 25px 0 10px;
}

.heading {
  margin: 0 37px 0 20px;
}

.heading.active {
  font-size: 15px !important;
  font-weight: 700 !important;
  color: #3751ff;
}

.heading.active::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  background-color: #3751ff;
  left: 0;
  bottom: 0;
  border-radius: 3px;
}

.sections {
  max-width: 815px;
  min-width: 300px;
  width: 100%;
}

.manual-contents {
  /* height: calc(100vh - 200px); */
}

.filter-sec {
  min-width: 215px;
  border-right: 1px solid #e0e4f0;
}

.question-sec {
  width: 100%;
  padding: 15px 19px 15px 22px;
}

.filter-item {
  border-top: 1px solid #e0e4f0;
  /* padding: 5px 20px 5px 15px;
      width: 215px; */
}

.filter-list {
  padding: 20px 0 20px;
  gap: 20px;
}

.filter-list-item {
  gap: 10px 0;
  max-height: 148px;
}

.filter-list label {
  color: #000;
  white-space: break-spaces;
  margin-left: 11px;
}

.manual-qns-btn {
  border: 1px solid #e0e4f0;
  padding: 4px 15px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 5px;
  color: #333333;
  font-weight: 600;
  font-size: 13px;
}

.manual-qns-btn.active {
  background-color: #3751ff !important;
  color: white;
  border-color: #3751ff;
}

.manual-middle {
  padding-right: 20px;
}

.selected-que-detail {
  width: 198px;
  border: 1px solid #e0e4f0;
}

.chapter-content {
  padding: 0 0 15px;
  max-height: 180px;
}

.chapter-detail {
  padding: 15px 0;
  border-bottom: 1px solid #f3f5fc;
}

.chapter-detail:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.chapter-detail:first-child {
  padding-top: 0;
}

.selected-que-detail .top {
  background: #5866c5;
  width: 178px;
  height: 165px;
  border-bottom: 1px solid #e0e4f0;
}

.selected-que-detail .top .top-counter {
  width: 75px;
  height: 32px;
  margin-top: 13px !important;
  line-height: 32px;
}

.selected-que-detail .bottom {
  padding: 20px 15px 0;
  border-bottom: 1px solid #e0e4f0;
}

.selected-que-detail .bottom:last-child {
  border-bottom: none;
}

.sub-counter {
  width: 19px;
  height: 15px;
  background: #3751ff;
  border-radius: 2px;
  text-align: center;
  line-height: 15px;
}

.manual-footer {
  height: 80px;
  border-top: 1px solid #e0e4f0;
  box-shadow: 0px -2px 10px rgb(0 0 0 / 8%);
  border-radius: 0px 0px 5px 5px;
  padding: 20px;
}

.draft-btn {
  width: 179px;
  height: 40px;
  background: #37841c;
  line-height: 40px;
}

.cencel-btn {
  width: 125px;
  height: 40px;
  border: 1px solid #ff7675;
  color: #ff7675;
  line-height: 40px;
}

.filter-indicator::after {
  position: absolute;
  content: "";
  width: 11px;
  height: 11px;
  border-radius: 50%;
  right: -18px;
  top: 5px;
}

.input-filter:nth-child(2) .filter-indicator::after {
  background: #8bc32e;
}

.input-filter:nth-child(3) .filter-indicator::after {
  background: #ffbf44;
}

.input-filter:nth-child(4) .filter-indicator::after {
  background: #ff835ce5;
}

.diff-indicator {
  width: 11px;
  height: 11px;
  border-radius: 50%;
}

.diff-counter:nth-child(1) .diff-indicator {
  background: #8bc32e;
}

.diff-counter:nth-child(2) .diff-indicator {
  background: #ffbf44;
}

.diff-counter:nth-child(3) .diff-indicator {
  background: #ff835ce5;
}

select:focus {
  outline: none;
}

#sort-select {
  background: url("../assets/downarrow-1.svg") no-repeat right;
  -webkit-appearance: none;
}

.sort-by {
  margin-left: 5px;
}

.sort-by::before {
  position: absolute;
  content: "";
  height: 28px;
  width: 1px;
  background-color: #f3f5fc;
  right: -10px;
  top: -4px;
}

.yellow-btn {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 3px;
  border: none;
  outline: none;
  height: 21px;
  font-weight: 600;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  z-index: 20;
}

[type="radio"]:not(:checked),
[type="radio"]:checked,
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: unset !important;
  opacity: 1 !important;
  pointer-events: all !important;
}

.filter-icon {
  font-size: 13px;
  font-weight: 700;
  color: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete-section-modal {
  width: 450px;
  height: 356px;
  background: #ffffff;
  border-radius: 10px;
}

.content-cntr {
  justify-content: center !important;
}

.content-even {
  justify-content: space-evenly !important;
}

.end-cancel-button {
  width: 114px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #37841c;
  border-radius: 4px;
  background: #ffffff;
  color: #37841c;
  cursor: pointer;
}

.end-cancel-button:hover {
  background: #37841c !important;
  color: #ffffff !important;
}

.end-confirm-button {
  width: 114px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #ff7675;
  border-radius: 4px;
  color: #ff7675;
  cursor: pointer;
}

.end-confirm-button:hover {
  background: #ff7675 !important;
  color: #ffffff !important;
}

.mesage-body {
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.publish-btn {
  width: 179px;
  height: 40px;
  background: #f3f5fc;
  border-radius: 5px;
  font-family: "Open Sans";
  font-style: normal;
  line-height: 40px;
  color: #3751ff !important;
}

.publish-btn.disable {
  background-color: #f1f1f1;
  pointer-events: none;
  color: #8e8e8e !important;
}

.concept-modal {
  max-width: 1173px !important;
  min-width: 650px !important;
  width: 100% !important;
}

.publish-head {
  position: relative;
  width: 100%;
  background: #f3f5fc;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}

.publish-close {
  background-color: #ff7675 !important;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
}

.modal-content {
  padding: 10px;
}

.select-all {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e4f0;
  border-radius: 4px;
  background: #ffffff;
  width: 113px;
  height: 40px;
}

input[type="text"]:not(.browser-default) {
  width: 650px;
  /* border-bottom: none !important; */
  height: 39px !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 4px;
  /* margin: 0px !important; */
}

input[type="number"]:not(.browser-default) {
  width: 190px;
  border-bottom: none !important;
  height: 39px !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 4px;
  /* margin: 0px !important; */
}

.course-button {
  height: 28px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 18px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #333333;
  cursor: pointer;
  background: #f3f5fc;
}

.selected {
  background: #3751ff !important;
  color: #ffffff !important;
}

.publish-modal-button {
  width: 130px;
  height: 40px;
  background: #37841c;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  margin-right: 30px;
  cursor: pointer;
}

.column {
  flex-direction: column !important;
}

.duration-button {
  height: 28px;
  background: #f3f5fc;
  border: 1px solid #e0e4f0;
  border-radius: 18px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #333333;
  cursor: pointer;
}

.date-column {
  height: 39px !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 4px !important;
  width: 310px !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  top: 3px;
}

.tooltiptext {
  visibility: hidden;
  background-color: #f44336;
  color: white;
  text-align: center;
  padding: 4px 10px;
  border-radius: 3px;
  position: absolute;
  z-index: 1;
  left: 35px;
  bottom: -7px;
  white-space: break-spaces;
  text-align: center;
  width: 190px;
  text-align: left;
  border-radius: 4px;
  font-size: 12px;
  transition: 0.3s all ease;
  font-weight: 600;
}

.tooltiptext::before {
  position: absolute;
  width: 10px;
  height: 10px;
  content: "";
  background: #f44336;
  left: 0;
  top: 17px;
  margin-left: -4px;
  -webkit-backdrop-filter: #f44336;
  backdrop-filter: #f44336;
  transform: rotate(45deg);
  background: #f44336;
  visibility: hidden;
  transition: 0.3s all ease;
}

.tooltip:hover .tooltiptext,
.tooltip:hover .tooltiptext::before {
  visibility: visible;
}

.previewBody_content {
  width: 100%;
  height: 100%;
  border: 1px solid #e0e4f0;
  padding-left: 5px;
  padding-right: 5px;
  background: #f3f5fc !important;
  border-top: none;
  border-bottom: none;
  padding: 13px 15px 10px !important;
}

.select-box {
  width: 100%;
  margin-left: 10px;
  position: relative;
}

.select-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.select-dropdown {
  position: absolute;
  width: 100%;
  top: 26px;
  background: white;
  left: 0;
  border: 1px solid #e0e4f0;
  border-radius: 5px;
  z-index: 99;
}

.select-dropdown p {
  margin: 0;
  padding: 0 5px;
  cursor: pointer;
}

.select-dropdown p:hover {
  color: #3751ff;
}

.qns-list {
  height: calc(100vh - 360px);
}

.question-header {
  height: 16px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
  margin: 0 7px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 20px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a9a9a98f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: 0px;
  background-color: #4787f3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #a9a9a98f;
}

input:focus + .slider {
  box-shadow: 0 0 1px #a9a9a98f;
}

input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
  background-color: #f3f3f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f3f3f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #fcbc5c;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media screen and (max-width: 1230px) {
  .qns-list {
    height: calc(100vh - 390px);
  }
}

.modalSlide-leave-active {
  transition: all 0.8s ease-in-out;
}

.modalSlide-enter {
  transform: translateX(100%);
}

.modalSlide-enter-active {
  transition: all 0.1s ease-in-out;
  transform: translateX(100%);
}

.modalSlide-leave-to {
  transform: translateX(100%);
}
.course-btn-container {
  max-height: 300px;
  overflow-y: auto;
}
</style>
