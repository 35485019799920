<template>
  <div class="create-test container">
    <!-- <h3 class="dark-text fs-18 fw-700" style="margin: 20px 0 12px !important;">Most Used Templates</h3>
    <InstituteTemplate/> -->
    <h3 class="fs-18 fw-700" v-if="instituteTestId === null"></h3>
    <!-- <InstituteTemplate @templateDetails="getTemplateDetails($event)" v-if="isAutomatic === null && instituteTestId === null"/> -->
    <div class="main-container">
      <div v-if="instituteTestId === null">
        <div >
        <CourseSelectionComponentVue @courseDetails="getCourseContentDetails($event)"
          @closeCourseDetails="closeCourseComponent($event)" @batchList="getBatchList($event)" @closeComponent="openTest($event)" />
        </div>
        <div v-if="courseContentDetails.length !== 0 && closeCourse !== null">
          <TestDetailsComponentVue :key="closeCourse" :courseComponentClosed="closeCourse" :courseDetails="JSON.stringify(courseContentDetails)"
            @testDetails="getTestDetails($event)" @closeTestPart="showTestComponent($event)" @closeTest="openSection($event)"/>
        </div>
        <div v-if="courseContentDetails.length !== 0 && testDetailList.length !== 0 && closeTest !== null" >
          <CreateTestSection :key="closeTest" :closeTestComponent="closeTest"
            :courseDetails="JSON.stringify(courseContentDetails)" :testDetails="JSON.stringify(testDetailList)"
            @closeManageSection="showManageSectionComponent($event)" @testCreationData="getTestData($event)" @closeSection="openBatch($event)" />
        </div>
        <div v-if="testDetails1 !== '' && closeSection !== null">
          <BatchSelectComponentVue :key="closeSection" :closeSectionComponent="closeSection" :batchList="JSON.stringify(batchList)"
            :testData="JSON.stringify(testDetails1)" @closeBatchSection="showBatchSelectComponent($event)"
            @selectedBatchIds="getBatchIds($event)" @testFinalData="getTestFinalData($event)" @closeBatch="openPublish($event)"/>
        </div>
        <div v-if="batchList.length !== 0 && testFinalData_New !== '' && closeBatch !== null">
          <PublishTestComponentVue :key="closeBatch"
            :instituteTestData="JSON.stringify(testFinalData_New)" @instituteTestId="getInstituteTestId($event)" @isManual="getisManualForInsituteTest($event)" @questionData ="getAutomaticQuestionData($event)" />
        </div>
      </div>
      <div v-if="instituteTestId === null" class="scroll-horizontal" :style="`max-width: ${screenWidth}px`">
        <div class="bottom-bar flex" v-if="showPublishTest === false">
          <div class="bottom-bar-button flex between" v-if="showTest === undefined">
            <div class="bar-button"><span class="mt4">2</span></div>
            <span class="bar-text mt4">Test Details</span>
          </div>
          <div class="bottom-bar-button flex between" v-if="showManageSection === undefined">
            <div class="break-line" v-if="this.showTest === undefined"></div>
            <div class="bar-button"><span class="mt4">3</span></div>
            <span class="bar-text mt4">Manage Sections</span>
          </div>
          <div class="bottom-bar-button flex between" v-if="showBatchSelect === undefined">
            <div class="break-line" v-if="this.showManageSection === undefined"></div>
            <div class="bar-button"><span class="mt4">4</span></div>
            <span class="bar-text mt4">Select Batches</span>
          </div>
          <div class="bottom-bar-button flex between">
            <div class="break-line" v-if="showBatchSelect === undefined"></div>
            <div class="bar-button"><span class="mt4">5</span></div>
            <span class="bar-text mt4">Test Setup & Publish</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isManual === 0 && instituteTestId >0">
        <AutomaticQuesGenerationComponentVue :instituteTestId="JSON.stringify(instituteTestId)" :courseDetails="JSON.stringify(courseContentDetails)" :questionDetailsList="JSON.stringify(autoQuestionData)" @goBackData="getTestCancelProps($event)" />
      </div>
      <div v-if="isManual === 1 && instituteTestId >0 ">
        <ManualTestPreview :instituteTestId="JSON.stringify(instituteTestId)"  @goBackData="getTestCancelProps($event)" />
      </div>
      <div v-if="isMobileDevice === true">
        <Modal3 :show="alertModalPopup === true" :showHeader="false" :showCloseBtn="false" @close="alertModalPopup = false">
          <template v-slot:body>
          <div class="mobile-device-popup">
            <div class="flex" style="justify-content: center">
              <img style="height: 60px; width: 60px" :src="require(`../assets/computer-svg.svg`)" />
            </div>
            <div class="flex" style="justify-content: center">
              <span class="fs-16 fw-600" style="">For better experience use desktop browser.</span>
            </div>
          </div>
        </template>
        </Modal3>
      </div>
  </div>
</template>

<script>
import CourseSelectionComponentVue from "../components/CourseSelectionComponent.vue";
import TestDetailsComponentVue from "../components/TestDetailsComponent.vue";
import CreateTestSection from "../components/CreateTestSection.vue";
import BatchSelectComponentVue from "../components/BatchSelectComponent.vue";
import PublishTestComponentVue from "../components/PublishTestComponent.vue";
import AutomaticQuesGenerationComponentVue from "./AutomaticQuesGenerationComponent.vue";
import ManualTestPreview from "./ManualTestPreview.vue";
// import InstituteTemplate from "../components/InstituteTemplate.vue";
import Modal3 from "../components/Modal3.vue";
// eslint-disable-next-line import/no-cycle

export default {
  data() {
    return {
      showCourse: true,
      showTest: undefined,
      showManageSection: undefined,
      showBatchSelect: undefined,
      showPublishTest: false,
      courseContentDetails: [],
      testDetailList: [],
      batchList: [],
      batchIdList: [],
      courseComponentOpenClick: 0,
      testDetails1: "",
      testFinalData_New: "",
      isManual: null,
      instituteTestId: null,
      oldInstituteTestId: null,
      closeCourse: null,
      closeTest: null,
      closeSection: null,
      closeBatch: null,
      isMobileDevice: false,
      alertModalPopup: true,
      autoQuestionData: [],
      screenWidth: "",
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#F6F8FB");
  },
  watch: {
    getCourseContentDetails(data) {
      this.courseContentDetails = data;
      console.log("content", this.courseContentDetails);
    },
  },
  activated() {
    this.screenWidth = window.innerWidth - 50;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobileDevice = true;
    } else {
      this.isMobileDevice = false;
    }
  },
  mounted() {
    this.screenWidth = window.innerWidth - 50;
    this.emitter.on("open-course-component", (data) => {
      // console.log("course-open", data);
      this.showCourse = data.showCourse;
      this.showTest = data.showTest;
      this.showBatchSelect = data.showBatchSelect;
      this.showManageSection = data.showManageSection;
      this.showPublishTest = data.showPublishTest;
    });
    this.emitter.on("open-test-component", (data) => {
      // console.log("test-opne", data);
      this.showCourse = data.showCourse;
      this.showTest = data.showTest;
      this.showBatchSelect = data.showBatchSelect;
      this.showManageSection = data.showManageSection;
      this.showPublishTest = data.showPublishTest;
    });
    this.emitter.on("open-section-component", (data) => {
      // console.log("section-open", data);
      this.showCourse = data.showCourse;
      this.showTest = data.showTest;
      this.showBatchSelect = data.showBatchSelect;
      this.showManageSection = data.showManageSection;
      this.showPublishTest = data.showPublishTest;
    });
    this.emitter.on("open-batch-component", (data) => {
      // console.log("batch-open", data);
      this.showCourse = data.showCourse;
      this.showTest = data.showTest;
      this.showBatchSelect = data.showBatchSelect;
      this.showManageSection = data.showManageSection;
      this.showPublishTest = data.showPublishTest;
    });
  },
  components: {
    CourseSelectionComponentVue,
    TestDetailsComponentVue,
    CreateTestSection,
    BatchSelectComponentVue,
    PublishTestComponentVue,
    AutomaticQuesGenerationComponentVue,
    ManualTestPreview,
    // InstituteTemplate,
    Modal3,
  },
  methods: {
    getTestCancelProps(event) {
      this.showCourse = event.showCourse;
      this.showTest = event.showTest;
      this.showManageSection = event.showBatchSelect;
      this.showBatchSelect = event.showBatchSelect;
      this.showPublishTest = event.showPublishTest;
      this.instituteTestId = event.instituteTestId;
      this.oldInstituteTestId = event.oldInstituteTestId;
      this.isManual = null;
    },
    getCourseContentDetails(data) {
      this.courseContentDetails = data;
    },
    getTestDetails(data) {
      this.testDetailList = data;
    },
    getBatchList(data) {
      this.batchList = data;
    },
    getBatchIds(data) {
      this.batchIdList = data;
    },
    getTestData(data) {
      this.testDetails1 = data;
    },
    getInstituteTestId(data) {
      this.instituteTestId = Number(data);
      this.showCourse = false;
      this.showTest = undefined;
      this.showManageSection = undefined;
      this.showBatchSelect = undefined;
      this.showPublishTest = undefined;
    },
    getisManualForInsituteTest(data) {
      this.isManual = data;
    },
    getTestFinalData(data) {
      this.testFinalData_New = data;
      if (this.showPublishTest === false) {
        this.showPublishTest = true;
      }
    },
    closeCourseComponent(data) {
      this.showCourse = data;
      if (this.showCourse === false) {
        this.showTest = true;
      }
    },
    showTestComponent(data) {
      this.showTest = data;
      if (this.showTest === false) {
        this.showManageSection = true;
      }
    },
    showManageSectionComponent(data) {
      this.showManageSection = data;
      if (this.showManageSection === false) {
        this.showBatchSelect = true;
      }
    },
    showBatchSelectComponent(data) {
      this.showBatchSelect = data;
      if (this.showBatchSelect === false) {
        this.showPublishTest = true;
      }
    },
    openTest(data) {
      this.closeCourse = data;
      this.showTest = true;
    },
    openSection(data) {
      this.closeTest = data;
      this.showManageSection = true;
    },
    openBatch(data) {
      this.closeSection = data;
      this.showBatchSelect = true;
    },
    openPublish(data) {
      this.closeBatch = data;
      this.showPublishTest = true;
    },
    getTemplateDetails(template) {
      console.log("template: ", template);
    },
    getAutomaticQuestionData(data) {
      this.autoQuestionData = data;
    },
  },
};
</script>

<style  scoped>
/* .create-test{
    font-family: 'Open-Sans' !important;
} */
.fs-15 {
  font-size: 15px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fw-700 {
  font-weight: 700 !important;
}

span {
  margin: 0;
}

.mt4 {
  margin-top: 4px !important;
}

.mt-2 {
  margin-top: -2px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.pd20 {
  padding: 20px !important;
}

.flex {
  display: flex !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-end {
  justify-content: flex-end !important;
}

.between {
  justify-content: space-between !important;
}

.fs-12 {
  font-size: 12 !important;
}

.fs-14 {
  font-size: 14 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.main-container {
  background: #FFFFFF;
  border: 1px solid #E0E4F0;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 5px;
  /* margin-top: 40px; */
}

.bottom-bar {
  height: 65px;
  background: #FFFFFF;
  padding: 15px;
}

.bar-button {
  width: 33px;
  height: 33px;
  border: 1px solid #E0E4F0;
  border-radius: 3px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #3751FF;
  margin-right: 12px;
}

.bar-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  padding-right: 60px;
}

.break-line {
  height: 41px;
  border-left: 2px solid #E0E4F0;
  margin-right: 15px;
  margin-top: -3px;
}
.mobile-device-popup{
  width: 336px;
  height: 188px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
