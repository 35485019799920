<template>
  <div
    class="close-component-heading flex pointer"
    v-if="showCourse === false"
    @click="openCourseComponent()"
  >
    <button class="close-section-button">1</button>
    <span class="close-section-heading">Select Content:</span>
    <div class="flex fs-12 scroll-horizontal" style="margin-top: 2px; padding-left: 15px">
      <div v-if="displayProperties.courseName !== ''">
        <span class="fw-600">Course</span>&ensp;
        <span class="fw-400">{{ displayProperties.courseName }}</span>
      </div>
      <div class="seperator" v-if="displayProperties.className.length !== 0"></div>
      <div v-if="displayProperties.className.length !== 0">
        <span class="fw-600">Class</span>&ensp;
        <span
          class="fw-400 fs-12 blk-text"
          v-for="(data, index) in displayProperties.className"
          :key="index"
          >{{ data }} &nbsp;</span
        >
      </div>
      <div class="seperator" v-if="displayProperties.subjectsName.length !== 0"></div>
      <div v-if="displayProperties.subjectsName.length !== 0">
        <span class="fw-600">Subjects</span>&ensp;
        <span
          class="fw-400 fs-12 blk-text"
          v-for="(data, index) in displayProperties.subjectsName"
          :key="index"
          >{{ data }} &nbsp;</span
        >
      </div>
      <div class="seperator" v-if="displayProperties.topicsName.length !== 0"></div>
      <div v-if="displayProperties.topicsName.length !== 0">
        <span class="fw-600">Topics</span>&ensp;
        <span
          class="fw-400 fs-12 blk-text"
          v-for="(data, index) in displayProperties.topicsName"
          :key="index"
          >{{ data.name }} &nbsp;</span
        >
      </div>
      <div class="seperator" v-if="displayProperties.chaptersName.length !== 0"></div>
      <div v-if="displayProperties.chaptersName.length !== 0">
        <span class="fw-600">Chapters</span>&ensp;
        <span
          class="fw-400 fs-12 blk-text"
          v-for="(data, index) in displayProperties.chaptersName"
          :key="index"
          >{{ data.name }} &nbsp;</span
        >
      </div>
    </div>
  </div>
  <div class="main-section" v-if="showCourse === true">
    <div class="component-heading flex flex-row">
      <button class="section-button">1</button>
      <div class="flex flex-column mt-2 scroll-horizontal">
        <span class="content-heading">Select Content</span>
        <div class="flex fs-12 mt-2 scroll-horizontal" :style="`max-width: ${screenWidth}px`">
          <div class="flex" v-if="displayProperties.courseName !== ''">
            <span class="fw-600">Course</span>&ensp;
            <span class="fw-400">{{ displayProperties.courseName }}</span>
          </div>
          <div class="seperator" v-if="displayProperties.className.length !== 0"></div>
          <div class="flex" v-if="displayProperties.className.length !== 0">
            <span class="fw-600">Class</span>&ensp;
            <span
              class="fw-400 fs-12 blk-text"
              v-for="(data, index) in displayProperties.className"
              :key="index"
              >{{ data }} &nbsp;</span
            >
          </div>
          <div class="seperator" v-if="displayProperties.subjectsName.length !== 0"></div>
          <div class="flex" v-if="displayProperties.subjectsName.length !== 0">
            <span class="fw-600">Subjects</span>&ensp;
            <span
              class="fw-400 fs-12 blk-text"
              v-for="(data, index) in displayProperties.subjectsName"
              :key="index"
              >{{ data }} &nbsp;</span
            >
          </div>
          <div class="seperator" v-if="displayProperties.topicsName.length !== 0"></div>
          <div class="flex" v-if="displayProperties.topicsName.length !== 0">
            <span class="fw-600">Topics</span>&ensp;
            <span
              class="fw-400 fs-12 blk-text"
              v-for="(data, index) in displayProperties.topicsName"
              :key="index"
              >{{ data.name }} &nbsp;</span
            >
          </div>
          <div class="seperator" v-if="displayProperties.chaptersName.length !== 0"></div>
          <div class="flex" v-if="displayProperties.chaptersName.length !== 0">
            <span class="fw-600">Chapters</span>&ensp;
            <span
              class="fw-400 fs-12 blk-text"
              v-for="(data, index) in displayProperties.chaptersName"
              :key="index"
              >{{ data.name }} &nbsp;</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row content-main-section">
      <div class="col s12 l3" style="border-right: 1px solid #e0e4f0">
        <p class="widgets-icon" @click="isMobileNavigationVisible = true">
          <span class="material-icons">widgets</span>
        </p>
        <div
          class="content-left-section flex nav-section-mobile"
          :class="{ 'active-nav': isMobileNavigationVisible }"
          style="flex-direction: column; height: 625px"
        >
          <div style="position: relative; width: 100%" v-if="isMobileNavigationVisible">
            <p class="close-nav-icon" @click="isMobileNavigationVisible = false">
              <span class="material-icons">close</span>
            </p>
          </div>
          <div
            class="content-selection-button"
            :class="{ active: selectedSection === 1 }"
            @click="viewCourse()"
          >
            <span class="fs-14 fw-600">Course</span>
            <div>
              <span class="fw-400 fs-12 blk-text">{{ displayProperties.courseName }}</span>
            </div>
          </div>
          <div
            class="content-selection-button"
            :class="{ active: selectedSection === 2 }"
            @click="viewClass()"
          >
            <span class="fs-14 fw-600">Class</span>
            <div class="flex scroll-horizontal">
              <span
                class="fw-400 fs-12 blk-text"
                v-for="(data, index) in displayProperties.className"
                :key="index"
                >{{ data }} &nbsp;</span
              >
            </div>
          </div>
          <div
            class="content-selection-button"
            :class="{ active: selectedSection === 3 }"
            @click="viewSubject()"
          >
            <span class="fs-14 fw-600">Subjects</span>
            <div class="flex scroll-horizontal">
              <span
                class="fw-400 fs-12 blk-text"
                style="padding-right: 5px"
                v-for="(data, index) in displayProperties.subjectsName"
                :key="index"
                >{{ data }} &nbsp;</span
              >
            </div>
          </div>
          <div
            class="content-selection-button"
            :class="{ active: selectedSection === 4 }"
            @click="viewTopics()"
          >
            <span class="fs-14 fw-600">Topics</span>
            <div class="flex scroll-horizontal">
              <span
                class="fw-400 fs-12 blk-text"
                v-for="(data, index) in displayProperties.topicsName"
                :key="index"
                >{{ data.name }} &nbsp;</span
              >
            </div>
          </div>
          <div
            class="content-selection-button"
            :class="{ active: selectedSection === 5 }"
            @click="viewChapters()"
          >
            <span class="fs-14 fw-600">Chapters</span>
            <div class="flex">
              <span
                class="fw-400 fs-12 blk-text"
                v-for="(data, index) in displayProperties.chaptersName"
                :key="index"
                >{{ data.name }} &nbsp;</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col s12 l9 content-right-section flex flex-column" style="height: 625px">
        <div class="content-search">
          <input type="text" style="max-width: 93%" placeholder="Search..." v-model="searchValue" />
          <img
            class="search_img"
            :src="require(`../assets/Search.svg`)"
            alt="Search"
            style="position: absolute; top: 10px; right: 10px"
          />
        </div>
        <div class="pd20" v-if="selectedSection === 1">
          <p class="instruction-line" v-if="filterCourseList.length > 0">Please select a course.</p>
          <div class="scroll-section">
            <button
              class="course-button"
              :class="{ selected: selectCourseDetails.courseId === data.CourseId }"
              v-for="(data, index) in filterCourseList"
              :value="data.CourseId"
              :key="index"
              @click="selectCourse(data)"
            >
              {{ data.CourseName }}
            </button>
          </div>
          <div>
            <button
              class="next-button mt30 pointer"
              :class="{ deactive: selectCourseDetails.courseId === null }"
              @click="nextSection()"
            >
              Next
            </button>
          </div>
        </div>
        <div class="pd20" v-if="selectedSection === 2">
          <p class="instruction-line" v-if="filterClassList.length > 0">
            Please select class.<span v-if="filterClassList.length > 1">
              You can select multiple classes.</span
            >
          </p>
          <button
            class="course-button"
            :class="{ selected: selectCourseDetails.classes.includes(data) }"
            v-for="(data, index) in filterClassList"
            :value="data.ClassId"
            :key="index"
            @click="selectClass(data)"
          >
            {{ data.DisplayName }}
          </button>
          <div>
            <button
              class="next-button mt30"
              :class="{ deactive: selectCourseDetails.classes.length === 0 }"
              @click="nextSection()"
            >
              Next
            </button>
          </div>
        </div>
        <div class="pd20" v-if="selectedSection === 3 && selectCourseDetails.classes.length > 0">
          <p class="instruction-line" v-if="filterSubjectList.length > 0">
            Please select subject.<span v-if="filterSubjectList.length > 1 && !isSelfTestMode">
              You can select multiple subjects.</span
            >
          </p>
          <button
            class="course-button"
            :class="{ selected: this.selectCourseDetails.subjects.includes(sub) }"
            v-for="(sub, index) in filterSubjectList"
            :value="sub.SubjectId"
            :key="index"
            @click="selectSubjects(sub)"
          >
            {{ sub.SubjectName }}
          </button>
          <div>
            <button
              class="next-button mt30"
              :class="[
                {
                  deactive:
                    selectCourseDetails.subjects.length === 0 ||
                    selectCourseDetails.classes.length === 0,
                },
              ]"
              @click="nextSection()"
            >
              Next
            </button>
          </div>
        </div>
        <div
          class="pd20"
          v-show="
            selectedSection === 4 &&
            selectCourseDetails.classes.length > 0 &&
            selectCourseDetails.subjects.length > 0
          "
        >
          <p class="instruction-line" v-if="filterTopicList.length > 0 && !isSelfTestMode">
            Please select at least 1 topic from each subject. You can select multiple topics.
          </p>
          <p class="instruction-line" v-if="filterTopicList.length > 0 && isSelfTestMode">
            Please select at least 1 topic .
          </p>
          <div class="scroll-section">
            <div v-for="(data, index) in filterTopicList" :key="index">
              <div class="flex flex-between items-center" style="padding-right: 20px">
                <div>
                  <p class="subject-name">{{ data.subjectName }}</p>
                  <p class="fs-12 dark-text fw-600 italic m-0" v-if="data.topicList.length === 0">
                    Topic not avaliable.
                  </p>
                </div>
                <div class="select-all" v-if="data.topicList.length > 0" name="topics">
                  <input
                    type="checkbox"
                    :checked="isAllTopicSelected(index)"
                    :id="index + 'topic'"
                    class="pointer"
                    @change="selectAllTopics($event, data)"
                    name="topics"
                  />
                  <label :for="index + 'topic'" class="pointer fw-400 fs-13 dark-text"
                    >Select All</label
                  >
                </div>
              </div>
              <div class="flex mt10" style="flex-wrap: wrap">
                <button
                  class="course-button"
                  :class="{ selected: selectCourseDetails.topics.includes(topic) }"
                  v-for="(topic, index1) in data.topicList"
                  :key="index1"
                  @click="selectTopics(topic, data.subjectName, data.subjectId)"
                >
                  {{ topic.TopicName }}
                </button>
              </div>
            </div>
          </div>
          <div>
            <button
              class="next-button mt30"
              :class="{
                deactive:
                  validateTopics() ||
                  selectCourseDetails.subjects.length === 0 ||
                  selectCourseDetails.classes.length === 0,
              }"
              @click="nextSection()"
            >
              Next
            </button>
          </div>
        </div>
        <div class="pd20" v-show="selectedSection === 5 && topicList.length > 0">
          <p class="instruction-line" v-if="filterChapterList.length > 0 && !isSelfTestMode">
            Please select at least 1 chapter from each subject. You can select multiple chapters.
          </p>
          <p class="instruction-line" v-if="filterTopicList.length > 0 && isSelfTestMode">
            Please select at least 1 chapter .
          </p>
          <div class="scroll-section">
            <div v-for="(data, index1) in filterChapterList" :key="index1" class="mt10">
              <div class="flex flex-between items-center" style="padding-right: 20px">
                <p class="subject-name" v-if="data.topicList.length > 0">{{ data.subjectName }}</p>
                <div class="select-all" v-if="data.topicList.length > 0">
                  <input
                    type="checkbox"
                    :id="index1 + 'chapter'"
                    class="pointer"
                    :checked="isAllChapterSelected(index1)"
                    @change="selectAllChapters($event, data, index1)"
                    name="chapter"
                  />
                  <label :for="index1 + 'chapter'" class="pointer fw-400 fs-13 dark-text"
                    >Select All</label
                  >
                </div>
              </div>
              <div
                class="flex mt10 flex-column"
                v-for="(topic, index2) in data.topicList"
                :key="index2"
              >
                <div>
                  <img :src="require('../assets/dot-point.svg')" style="margin-right: 10px" />
                  <span class="topic-name">{{ topic.topicName }}</span>
                </div>
                <p
                  class="fs-12 dark-text fw-600 italic m-0"
                  style="margin-left: 17px"
                  v-if="topic.chapterList.length === 0"
                >
                  Chapter not matched.
                </p>
                <div class="flex mt10" style="flex-wrap: wrap">
                  <button
                    class="course-button"
                    :class="{ selected: selectCourseDetails.chapters.includes(chapter) }"
                    v-for="(chapter, index) in topic.chapterList"
                    :key="index"
                    @click="selectChapters(chapter, data.subjectName, index1)"
                  >
                    {{ chapter.ChapterName }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              class="next-button mt30"
              :class="{
                deactive:
                  validateChapters() ||
                  validateTopics() ||
                  selectCourseDetails.subjects.length === 0 ||
                  selectCourseDetails.classes.length === 0,
              }"
              @click="nextSection()"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
    <Modal2
      :show="warningModal"
      :showHeader="false"
      :showCloseBtn="false"
      @close="warningModal = false"
    >
      <template v-slot:body>
        <div class="warning-modal flex flex-column items-center">
          <div class="flex content-cntr" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" />
          </div>
          <span class="flex content-cntr fs-20 fw-600 ln-27" style="margin-bottom: 8px"
            >Warning</span
          >
          <div class="flex content-cntr" style="flex-direction: column">
            <p
              class="mesage-body fs-13 fw-400 ln-17 flex content-cntr"
              style="white-space: pre-line"
            >
              If you made any Changes now All your changes will be vanished !!
            </p>
          </div>
          <button class="cancel-button fs-13 fw-600 ln-18" @click="warningModal = false">Ok</button>
          <!-- <div class="flex content-even" style="padding:35px">
            <button class="end-cancel-button fs-13 fw-600 ln-18"
              @click="deleteSection()">YES, DELETE IT</button>
            <button class="end-confirm-button fs-13 fw-600 ln-18"
              @click="deleteSectionModal = false">NO, GO BACK</button>
          </div> -->
        </div>
      </template>
    </Modal2>
  </div>
</template>

<script>
import Api from "../Api";
import MobileApi from "../Mobileapi";
import Modal2 from "./Model2.vue";

export default {
  data() {
    return {
      createInstituteTestParams: null,
      affiliationId: null,
      courseList: [],
      classList: [],
      selectedClassList: [],
      subjectList: [],
      batchList: [],
      topicList: [],
      chapterList: [],
      subjectArr: [],
      topicsArr: [],
      mainChapterList: [],
      selectedTopicList: [],
      selectedChapterList: [],
      chapterCountList: [],
      selectedChapters: [],
      selectCourseDetails: {
        courseName: "",
        courseId: null,
        affilationCourseId: null,
        classes: [],
        subjects: [],
        topics: [],
        chapters: [],
        subjectTopicChapterList: [],
      },
      displayProperties: {
        courseName: "",
        className: [],
        subjectsName: [],
        topicsName: [],
        chaptersName: [],
      },
      userDetails: [],
      selectedSection: null,
      count: 0,
      searchValue: "",
      isDeactiveButton: true,
      showCourse: true,
      totalChapterCount: null,
      subjectTopicChapterList: [],
      completeSubjectList: [],
      warningModal: false,
      selectedSubject: "",
      userProfileData: [],
      teacherDashTopicDetail: null,
      isMobileNavigationVisible: false,
      screeWidth: "",
    };
  },
  props: {
    isSelfTestMode: Boolean,
  },
  created() {
    this.createInstituteTestParams = JSON.parse(localStorage.getItem("CreateInstituteTestParams"));
    localStorage.removeItem("CreateInstituteTestParams");

    const courseIdProps = Number(this.createInstituteTestParams?.courseIdProps);
    this.screenWidth = window.innerWidth - 50;
    Api.getProfile((profile) => {
      this.userProfileData = profile;
      if (this.userProfileData !== null || this.userProfileData !== undefined) {
        this.selectedSection = 1;
        const user = this.$store.getters.user;
        this.userDetails = user;
        this.affiliationId = this.userDetails.AffiliationId;
        const data = {
          onlyCourses: 1,
          courseId: 0,
        };
        MobileApi.getCourseMetaData(this.affiliationId, data, (response) => {
          this.courseList = response.data[0];
          this.classList = response.data[1];
          this.completeSubjectList = response.data[2];
          if (courseIdProps) {
            const courseDetail = this.filterCourseList.find(
              (item) => item.CourseId === courseIdProps,
            );
            this.selectCourse(courseDetail);
          }
          this.selectDefaultCourseForTest();
        });
      }
    });
  },
  mounted() {
    this.screenWidth = window.innerWidth - 50;
  },
  computed: {
    filterCourseList() {
      return this.courseList.filter((course) =>
        course.CourseName.toLowerCase().includes(this.searchValue.toLowerCase()),
      );
    },
    filterClassList() {
      return this.selectedClassList.filter((classes) =>
        classes.DisplayName.toLowerCase().includes(this.searchValue.toLowerCase()),
      );
    },
    filterSubjectList() {
      return this.subjectList.filter((subject) =>
        subject.SubjectName.toLowerCase().includes(this.searchValue.toLowerCase()),
      );
    },
    filterTopicList() {
      return this.topicList.map((item) => ({
        ...item,
        topicList: item.topicList.filter((topic) =>
          topic.TopicName.toLowerCase().includes(this.searchValue.toLowerCase()),
        ),
      }));
    },
    filterChapterList() {
      return this.mainChapterList.map((item) => ({
        ...item,
        topicList: item.topicList.map((item1) => ({
          ...item1,
          chapterList: item1.chapterList.filter((chapter) =>
            chapter.ChapterName.toLowerCase().includes(this.searchValue.toLowerCase()),
          ),
        })),
      }));
    },
  },
  components: {
    Modal2,
  },
  methods: {
    selectDefaultCourseForTest() {
      if (this.isSelfTestMode) {
        // const user = this.$store.getters.user;
        this.courseList = this.courseList.filter(
          (a) => a.CourseId === this.userProfileData.CourseId,
        );
        if (this.courseList != null && this.courseList.length > 0) {
          this.selectCourse(this.courseList[0]);
        }
      }
    },
    isAllChapterSelected(index) {
      let returnData = false;
      if (this.chapterCountList.length > 0) {
        if (
          this.chapterCountList[index].chapters.length === this.totalChapterCount[index].chapterList
        ) {
          returnData = true;
        }
      }
      return returnData;
    },
    isAllTopicSelected(index) {
      let returnData = false;
      for (let i = 0; i < this.topicList.length; i += 1) {
        if (index === i) {
          for (let j = 0; j < this.mainChapterList.length; j += 1) {
            if (this.topicList[i].subjectId === this.mainChapterList[j].subjectId) {
              if (this.topicList[i].topicList.length === this.mainChapterList[j].topicList.length) {
                returnData = true;
                break;
              }
            }
          }
        }
      }
      return returnData;
    },
    viewCourse() {
      this.selectedSection = 1;
      this.searchValue = "";
    },
    viewClass() {
      this.selectedSection = 2;
      this.searchValue = "";
    },
    viewSubject() {
      this.selectedSection = 3;
      this.searchValue = "";
    },
    viewTopics() {
      this.selectedSection = 4;
      this.searchValue = "";
    },
    viewChapters() {
      this.selectedSection = 5;
      this.searchValue = "";
    },
    nextSection() {
      this.selectedSection += 1;
      this.searchValue = "";
      if (this.selectedSection === 5) {
        if (this.displayProperties.topicsName.length !== 0) {
          this.displayProperties.topicsName = [];
        }
        if (this.mainChapterList.length !== 0) {
          this.mainChapterList.forEach((obj) => {
            this.displayProperties.topicsName.push({
              name: `${obj.subjectName.slice(0, 3)}-${obj.topicList.length}`,
            });
          });
        }
      }
      if (this.selectedSection > 5) {
        this.emitter.emit("change-course-component-data", {
          dataChanged: true,
        });
        this.selectedSection = 1;
        this.count += 1;
        if (this.displayProperties.chaptersName.length !== 0) {
          this.displayProperties.chaptersName = [];
        }
        if (this.chapterCountList.length !== 0) {
          this.chapterCountList.forEach((obj) => {
            this.displayProperties.chaptersName.push({
              name: `${obj.subjectName.slice(0, 3)}-${obj.chapters.length}`,
            });
          });
        }
        this.showCourse = false;
        this.emitter.emit("close-course-component", {
          showCourse: false,
          showTest: true,
          showBatchSelect: undefined,
          showManageSection: undefined,
          showPublishTest: false,
        });
        this.emitter.emit("batch-list-data", {
          batchList: JSON.stringify(this.batchList),
        });
        this.$emit("closeComponent", this.count);
        this.$emit("courseDetails", this.selectCourseDetails);
        this.$emit("batchList", this.batchList);
        this.$emit("closeCourseDetails", false);
      }
    },
    selectCourse(data) {
      this.selectedClassList = this.classList.filter((obj) => obj.CourseId === data.CourseId);
      this.selectCourseDetails = {
        courseName: "",
        courseId: null,
        affilationCourseId: null,
        classes: [],
        subjects: [],
        topics: [],
        chapters: [],
        subjectTopicChapterList: [],
      };
      this.displayProperties = {
        courseName: "",
        className: [],
        subjectsName: [],
        topicsName: [],
        chaptersName: [],
      };
      this.chapterList = [];
      this.mainChapterList = [];
      this.chapterCountList = [];
      this.topicsArr = [];
      this.subjectArr = [];
      this.selectCourseDetails.courseId = data.CourseId;
      this.selectCourseDetails.courseName = data.CourseName;
      this.selectCourseDetails.affilationCourseId = data.AffiliationCourseId;
      this.displayProperties.courseName = data.CourseName;
      this.selectedClassList = this.classList.filter((obj) => obj.CourseId === data.CourseId);
      const obj = {
        onlyCourses: 0,
        courseId: data.CourseId,
      };
      MobileApi.getCourseMetaData(this.affiliationId, obj, (response) => {
        this.subjectList = response.data[0];
        this.batchList = response.data[1];
        const classIdProps = Number(this.createInstituteTestParams?.classIdProps);
        if (classIdProps) {
          this.viewClass();
          const classDetail = this.filterClassList.find((item) => item.ClassId === classIdProps);
          this.selectClass(classDetail);
        }
      });
    },
    selectClass(data) {
      this.selectCourseDetails.subjects = [];
      this.displayProperties.subjectsName = [];
      this.selectCourseDetails.topics = [];
      this.selectCourseDetails.chapters = [];
      this.displayProperties.topicsName = [];
      this.displayProperties.chaptersName = [];
      this.mainChapterList = [];
      this.topicList = [];
      if (this.selectCourseDetails.classes.includes(data)) {
        const index = this.selectCourseDetails.classes.indexOf(data);
        const index2 = this.displayProperties.className.indexOf(data.DisplayName);
        this.displayProperties.className.splice(index2, 1);
        this.selectCourseDetails.classes.splice(index, 1);
      } else {
        this.displayProperties.className.push(data.DisplayName);
        this.selectCourseDetails.classes.push(data);
      }
      this.chapterList = [];
      this.mainChapterList = [];
      this.chapterCountList = [];
      this.topicsArr = [];
      this.subjectArr = [];
      const subIdProps = Number(this.createInstituteTestParams?.subIdProps);
      if (subIdProps) {
        this.viewSubject();
        const subjectDetail = this.filterSubjectList.find((item) => item.SubjectId === subIdProps);
        this.selectSubjects(subjectDetail);
      }
    },
    selectSubjects(data) {
      if (this.isSelfTestMode) {
        this.selectCourseDetails.subjects = [];
        this.displayProperties.subjectsName = [];
        this.selectCourseDetails.topics = [];
        this.selectCourseDetails.chapters = [];
        this.displayProperties.topicsName = [];
        this.displayProperties.chaptersName = [];
        this.selectedTopicList = [];
        this.chapterList = [];
        this.mainChapterList = [];
        this.chapterCountList = [];
        this.topicsArr = [];
        this.selectedSubject = Number(data.subjectId);
        this.selectCourseDetails.subjects.push(data);
        this.displayProperties.subjectsName.push(data.SubjectName);
        this.topicList = [];
        if (this.selectCourseDetails.classes.length === 1) {
          this.topicList.push({
            subjectName: data.SubjectName,
            subjectId: data.SubjectId,
            topicList: data.topicsList.filter(
              (obj) => obj.ClassId === this.selectCourseDetails.classes[0].ClassId,
            ),
          });
        } else {
          this.topicList.push({
            subjectName: data.SubjectName,
            subjectId: data.SubjectId,
            topicList: data.topicsList,
          });
        }
      } else {
        this.mainChapterList = [];
        this.selectedTopicList = [];
        this.subjectArr = [];
        this.selectCourseDetails.topics = [];
        this.selectCourseDetails.chapters = [];
        this.displayProperties.topicsName = [];
        this.displayProperties.chaptersName = [];
        this.chapterList = [];
        this.mainChapterList = [];
        this.chapterCountList = [];
        this.topicsArr = [];
        this.subjectArr = [];

        if (this.selectCourseDetails.subjects.includes(data) || this.isSelfTestMode) {
          // const index = this.selectCourseDetails.subjectsId.indexOf(data.SubjectId);
          const index1 = this.selectCourseDetails.subjects.indexOf(data);
          const index2 = this.displayProperties.subjectsName.indexOf(data.SubjectName);
          const index3 = this.topicList.findIndex((obj) => obj.subjectName === data.SubjectName);
          // this.selectCourseDetails.subjectsId.splice(index, 1);
          this.selectCourseDetails.subjects.splice(index1, 1);
          this.displayProperties.subjectsName.splice(index2, 1);
          this.topicList.splice(index3, 1);
        } else {
          this.selectCourseDetails.subjects.push(data);
          this.displayProperties.subjectsName.push(data.SubjectName);
          if (this.selectCourseDetails.classes.length === 1) {
            this.topicList.push({
              subjectName: data.SubjectName,
              subjectId: data.SubjectId,
              topicList: data.topicsList.filter(
                (obj) => obj.ClassId === this.selectCourseDetails.classes[0].ClassId,
              ),
            });
          } else {
            this.topicList.push({
              subjectName: data.SubjectName,
              subjectId: data.SubjectId,
              topicList: data.topicsList,
            });
          }
        }
      }
      const topicIdProps = Number(this.createInstituteTestParams?.topicIdProps);
      if (topicIdProps) {
        this.viewTopics();
        this.teacherDashTopicDetail = this.filterTopicList[0].topicList.find(
          (item) => item.TopicId === topicIdProps,
        );
        if (this.teacherDashTopicDetail) {
          this.selectTopics(
            this.teacherDashTopicDetail,
            this.teacherDashTopicDetail.SubjectName,
            this.teacherDashTopicDetail.SubjectId,
          );
        }
      }
    },
    setSubjectSingleSelectClass(sub) {
      return !this.isSelfTestMode
        ? this.selectCourseDetails.subjects.includes(sub)
        : this.selectedSubject === sub.SubjectId;
    },
    selectTopics(data, subjectName, subjectId) {
      // eslint-disable-next-line no-unused-vars
      this.chapterCountList = [];
      this.totalChapterCount = [];
      this.displayProperties.topicsName = [];
      this.displayProperties.chaptersName = [];
      this.selectCourseDetails.chapters = [];
      this.topicsArr = [];
      if (this.selectCourseDetails.topics.includes(data)) {
        const index = this.selectCourseDetails.topics.indexOf(data);
        const index2 = this.chapterList.findIndex((item) => item.topicName === data.TopicName);
        this.selectCourseDetails.topics.splice(index, 1);
        this.chapterList.splice(index2, 1);
      } else {
        this.selectCourseDetails.topics.push(data);
        // eslint-disable-next-line object-shorthand, object-curly-newline
        this.chapterList.push({
          topicName: data.TopicName,
          subjectName,
          subjectId,
          chapterList: data.chapterList,
        });
        // eslint-disable-next-line object-curly-spacing, object-shorthand

        // eslint-disable-next-line object-curly-spacing, object-shorthand
        this.subjectArr.push({
          subjectName,
          subjectId,
          topicName: data.TopicName,
        });
      }
      let newList = [];
      this.subjectArr = [
        ...new Map(this.subjectArr.map((item) => [item.topicName, item])).values(),
      ];
      this.chapterList = [
        ...new Map(this.chapterList.map((item) => [item.topicName, item])).values(),
      ];
      let uniqueArray = this.subjectArr.map((item) => ({
        subjectName: item.subjectName,
        subjectId: item.subjectId,
      }));
      uniqueArray = [...new Map(uniqueArray.map((item) => [item.subjectName, item])).values()];
      let topicArr = [];
      uniqueArray.forEach((obj) => {
        // eslint-disable-next-line no-const-assign
        topicArr = [];
        this.chapterList.forEach((arr) => {
          if (arr.subjectName === obj.subjectName) {
            topicArr.push({
              topicName: arr.topicName,
              chapterList: arr.chapterList,
            });
          }
        });
        newList.push({
          subjectName: obj.subjectName,
          subjectId: obj.subjectId,
          topicList: topicArr,
        });
      });
      this.mainChapterList = newList.map((item) => ({
        ...item,
        topicList: [...new Map(item.topicList.map((item1) => [item1.topicName, item1])).values()],
      }));
      this.selectCourseDetails.topics = [
        ...new Map(this.selectCourseDetails.topics.map((item) => [item.TopicName, item])).values(),
      ];

      this.selectCourseDetails.subjectTopicChapterList = this.mainChapterList;
      this.selectedTopicList = this.mainChapterList.map((item) => ({ topicList: item.topicList }));
      // eslint-disable-next-line no-const-assign
      uniqueArray = [];
      // eslint-disable-next-line no-const-assign
      newList = [];
      if (this.displayProperties.topicsName.length > 0) {
        this.displayProperties.topicsName = [];
      }
      this.selectedChapterList = this.selectedTopicList.map((item) =>
        item.topicList.map((item2) => ({ chapterList: item2.chapterList })),
      );
      let chapterList = [];
      const chapterCount = [];
      this.selectedChapterList.forEach((item) => {
        chapterList = [];
        let count = 0;
        item.forEach((item2) => {
          count += item2.chapterList.length;
        });
        chapterList = count;
        chapterCount.push({ chapterList });
      });
      this.totalChapterCount = chapterCount;
      const chapterIdProps = Number(this.createInstituteTestParams?.chapterIdProps);
      if (chapterIdProps) {
        if (this.teacherDashTopicDetail) {
          this.viewChapters();
        }
        const chapterDetail = this.filterChapterList[0].topicList[0].chapterList.find(
          (item) => item.ChapterId === chapterIdProps,
        );
        if (chapterDetail) {
          this.selectChapters(chapterDetail, chapterDetail.SubjectName);
        }
      }
    },
    selectChapters(data, subjectName, index) {
      this.displayProperties.chaptersName = [];
      this.chapterCountList = [];
      if (this.selectCourseDetails.chapters.includes(data)) {
        const index1 = this.selectCourseDetails.chapters.indexOf(data);
        this.selectCourseDetails.chapters.splice(index1, 1);
        const index2 = this.topicsArr.findIndex((item) => item.chapter === data.ChapterId);
        this.topicsArr.splice(index2, 1);
      } else {
        this.selectCourseDetails.chapters.push(data);
        this.topicsArr.push({
          subject: subjectName,
          chapter: data.ChapterId,
          topicName: data.TopicName,
        });
      }
      let newList = [];
      let topicArr = [];
      let uniqueArray = this.subjectArr.map((item) => ({
        subjectName: item.subjectName,
        subjectId: item.subjectId,
      }));
      uniqueArray = [...new Map(uniqueArray.map((item) => [item.subjectName, item])).values()];
      uniqueArray.forEach((obj) => {
        topicArr = [];
        this.topicsArr.forEach((arr) => {
          if (arr.subject === obj.subjectName) {
            if (arr.subject === obj.subjectName) {
              topicArr.push({ chapterId: arr.chapter, topicName: arr.topicName });
            }
          }
        });
        newList.push({
          subjectName: obj.subjectName,
          chapters: topicArr,
        });
      });
      this.chapterCountList = newList;
      this.selectedChapters = this.chapterCountList[0].chapters;
      uniqueArray = [];
      newList = [];
      if (this.displayProperties.chaptersName.length > 0) {
        this.displayProperties.chaptersName = [];
      }
      this.isAllChapterSelected(index);
    },
    validateTopics() {
      const topicList = this.topicList.filter((item) => item.topicList.length > 0);
      if (this.selectCourseDetails.topics.length > 0) {
        const uniqueSubject = [
          ...new Set(this.selectCourseDetails.topics.map((item) => item.SubjectId)),
        ];
        if (uniqueSubject.length === topicList.length) {
          return false;
        }
        return true;
      }
      return true;
    },
    validateChapters() {
      const topicList = this.topicList.filter((item) => item.topicList.length > 0);
      if (this.selectCourseDetails.chapters.length > 0) {
        const uniqueSubject = [
          ...new Set(this.selectCourseDetails.chapters.map((item) => item.SubjectId)),
        ];
        if (uniqueSubject.length === topicList.length) {
          return false;
        }
        return true;
      }
      return true;
    },
    selectAllTopics(event, topicData) {
      this.displayProperties.topicsName = [];
      this.selectCourseDetails.chapters = [];
      this.totalChapterCount = [];
      this.chapterCountList = [];
      this.topicsArr = [];
      if (event.target.checked) {
        const selectTopics = topicData.topicList.flat();
        selectTopics.forEach((item) => {
          this.selectCourseDetails.topics.push(item);
          this.chapterList.push({
            topicName: item.TopicName,
            subjectName: topicData.subjectName,
            subjectId: topicData.subjectId,
            chapterList: item.chapterList,
          });
          this.subjectArr.push({
            subjectName: topicData.subjectName,
            subjectId: topicData.subjectId,
            topicName: item.TopicName,
          });
        });
      } else {
        this.mainChapterList.forEach((item) => {
          if (item.subjectName === topicData.subjectName) {
            item.topicList.forEach((item1) => {
              const index1 = this.selectCourseDetails.topics.findIndex(
                (item2) => item2.TopicName === item1.topicName,
              );
              if (index1 > -1) {
                this.selectCourseDetails.topics.splice(index1, 1);
              }
              const index2 = this.subjectArr.findIndex(
                (item2) => item2.topicName === item1.topicName,
              );
              if (index2 > -1) {
                this.subjectArr.splice(index2, 1);
              }
              const index3 = this.chapterList.findIndex(
                (item2) => item2.topicName === item1.topicName,
              );
              if (index3 > -1) {
                this.chapterList.splice(index3, 1);
              }
            });
          }
        });
      }
      let newList = [];
      this.subjectArr = [
        ...new Map(this.subjectArr.map((item) => [item.topicName, item])).values(),
      ];
      this.chapterList = [
        ...new Map(this.chapterList.map((item) => [item.topicName, item])).values(),
      ];
      this.selectCourseDetails.topics = [
        ...new Map(this.selectCourseDetails.topics.map((item) => [item.TopicName, item])).values(),
      ];
      let uniqueArray = this.subjectArr.map((item) => ({
        subjectName: item.subjectName,
        subjectId: item.subjectId,
      }));
      uniqueArray = [...new Map(uniqueArray.map((item) => [item.subjectName, item])).values()];
      let topicArr = [];
      uniqueArray.forEach((obj) => {
        topicArr = [];
        this.chapterList.forEach((arr) => {
          if (arr.subjectName === obj.subjectName) {
            topicArr.push({
              topicName: arr.topicName,
              chapterList: arr.chapterList,
            });
          }
        });
        newList.push({
          subjectName: obj.subjectName,
          subjectId: obj.subjectId,
          topicList: topicArr,
        });
      });
      this.mainChapterList = newList.map((item) => ({
        ...item,
        topicList: [...new Map(item.topicList.map((item1) => [item1.topicName, item1])).values()],
      }));
      this.selectCourseDetails.subjectTopicChapterList = this.mainChapterList;
      uniqueArray = [];
      newList = [];
      if (this.displayProperties.topicsName.length > 0) {
        this.displayProperties.topicsName = [];
      }
      this.selectedTopicList = this.mainChapterList.map((item) => ({ topicList: item.topicList }));
      this.selectedChapterList = this.selectedTopicList.map((item) =>
        item.topicList.map((item2) => ({ chapterList: item2.chapterList })),
      );
      let chapterList = [];
      const chapterCount = [];
      this.selectedChapterList.forEach((item) => {
        chapterList = [];
        let count = 0;
        item.forEach((item2) => {
          count += item2.chapterList.length;
        });
        chapterList = count;
        chapterCount.push({ chapterList });
      });
      this.totalChapterCount = chapterCount;
    },
    selectAllChapters(event, chapterData, index) {
      this.displayProperties.chaptersName = [];
      if (event.target.checked) {
        const topicList = chapterData.topicList.flat();
        topicList.forEach((item) => {
          item.chapterList.forEach((item2) => {
            this.selectCourseDetails.chapters.push(item2);
            this.topicsArr.push({
              subject: chapterData.subjectName,
              chapter: item2.ChapterId,
              topicName: item2.TopicName,
            });
          });
        });
      } else {
        this.chapterCountList.forEach((item1) => {
          if (item1.subjectName === chapterData.subjectName) {
            item1.chapters.forEach((chapterDetail) => {
              const index1 = this.selectCourseDetails.chapters.findIndex(
                (item2) => item2.TopicName === chapterDetail.topicName,
              );
              if (index1 > -1) {
                this.selectCourseDetails.chapters.splice(index1, 1);
              }
              const index2 = this.topicsArr.findIndex(
                (item2) => item2.topicName === chapterDetail.topicName,
              );
              if (index2 > -1) {
                this.topicsArr.splice(index1, 1);
              }
            });
          }
        });
      }

      const newList = [];
      let topicArr = [];
      let uniqueArray = this.subjectArr.map((item) => ({
        subjectName: item.subjectName,
        subjectId: item.subjectId,
      }));
      uniqueArray = [...new Map(uniqueArray.map((item) => [item.subjectName, item])).values()];
      uniqueArray.forEach((obj) => {
        topicArr = [];
        this.topicsArr.forEach((arr) => {
          if (arr.subject === obj.subjectName) {
            topicArr.push({ chapterId: arr.chapter, topicName: arr.topicName });
          }
        });
        newList.push({
          subjectName: obj.subjectName,
          chapters: topicArr,
        });
      });
      this.chapterCountList = newList;
      if (this.displayProperties.chaptersName.length > 0) {
        this.displayProperties.chaptersName = [];
      }
      this.isAllChapterSelected(index);
    },
    openCourseComponent() {
      this.showCourse = true;
      if (!this.isSelfTestMode) {
        this.warningModal = true;
      }
      this.emitter.emit("open-course-component", {
        showCourse: true,
        showTest: undefined,
        showBatchSelect: undefined,
        showManageSection: undefined,
        showPublishTest: false,
      });
    },
  },
};
</script>

<style scoped>
.row {
  margin-bottom: 0px !important;
}
span {
  margin: 0;
}

.mt-2 {
  margin-top: -2px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.pd20 {
  padding: 20px !important;
}

.flex {
  display: flex !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-end {
  justify-content: flex-end !important;
}

.fs-12 {
  font-size: 12 !important;
}

.fs-14 {
  font-size: 14 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.skyblue-text {
  color: #3751ff;
}

.dark-text {
  color: #333333;
}

.main-section {
  border-bottom: 1px solid #e0e4f0 !important;
}

.close-component-heading {
  height: 48px;
  background: #ffffff;
  border-bottom: 1px solid #e0e4f0;
  padding-left: 22px;
  padding-top: 14px;
  overflow-x: hidden;
}

.close-section-button {
  width: 20px;
  height: 20px;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #3751ff;
  background: #ffffff;
}

.close-section-heading {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #3751ff;
  margin-left: 18px;
  min-width: 100px;
}

.blk-text {
  color: #404458 !important;
}

.createtest {
  min-width: 500px;
  width: 100%;
  border-radius: 5px;
  margin-right: 20px;
}

.component-heading {
  background: #ffffff;
  height: 65px;
  padding: 15px;
  border-bottom: 1px solid #e0e4f0;
}

.section-button {
  border: none;
  width: 33px;
  height: 33px;
  background: #3751ff;
  border-radius: 3px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-right: 12px;
}

.content-heading {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #3751ff;
}

.content-main-section {
  height: 626px !important;
}

.content-left-section {
  background: #ffffff;
  /* width: 307px !important; */
}

.content-right-section {
  background: #f7f8fd;
  width: 100%;
}

.content-selection-button {
  max-width: 275px;
  /* width: 90%; */
  height: 50px;
  background: #f7f8fd;
  border-radius: 4px;
  margin-top: 10px;
  padding-top: 8px;
  padding-left: 15px;
  position: relative;
  border: none;
  cursor: pointer;
}

.content-selection-button.active {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #3751ff;
}

.content-selection-button.active::after {
  position: absolute;
  width: 3px;
  height: 50px;
  content: "";
  background: #3751ff;
  right: 0;
  top: 0;
  border-radius: 0 2px 2px 0;
}

.content-selection-button.active::before {
  position: absolute;
  width: 4px;
  height: 4px;
  content: "";
  background: #3751ff;
  right: -2px;
  top: 23px;
  margin-left: -4px;
  -webkit-backdrop-filter: #3751ff;
  backdrop-filter: #3751ff;
  transform: rotate(45deg);
  background: #3751ff;
}

.content-search {
  margin-left: 10px;
  margin-right: 10px;
  height: 40px;
  border: 1px solid #e0e4f0;
  background: white;
  border-radius: 4px;
  margin-top: 20px;
  justify-content: center;
  align-content: center;
  position: relative;
  border: none;
}

.course-button {
  min-height: 28px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 18px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #333333;
  cursor: pointer;
}

.selected {
  background: #3751ff !important;
  color: #ffffff;
}

.next-button {
  width: 100px;
  height: 28px;
  background: #37841c;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.seperator {
  width: 0px;
  height: 15px;
  border-left: 2px solid #e0e4f0;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 3px;
}

.subject-name {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #3751ff;
}

.subject-name-header {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
}

.topic-name {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #3751ff;
}

.next-button.deactive {
  background: grey !important;
  color: #ffffff !important;
  cursor: not-allowed !important;
  pointer-events: none !important;
}

input[type="text"]:not(.browser-default):focus:not([readonly]) {
  box-shadow: none;
  border-bottom: none;
  height: 39px;
}

input[type="text"]:not(.browser-default):focus:not([readonly]) {
  border-bottom: none !important;
  height: 39px;
}

input[type="text"]:not(.browser-default) {
  padding-right: 41px;
  max-width: 200px;
  width: 100%;
  border-bottom: none !important;
  height: 39px !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  /* margin: 0px !important; */
}

input[type="number"]:not(.browser-default) {
  border-bottom: none;
  width: 95%;
  /* margin: 0px !important; */
  height: 39px !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
}

input[type="number"]:not(.browser-default):focus:not([readonly]) {
  border-bottom: none;
  box-shadow: none;
}

input[type="date"]:not(.browser-default) {
  border-bottom: none;
  width: 92% !important;
}

input[type="date"]:not(.browser-default):focus:not([readonly]) {
  border-bottom: none;
  box-shadow: none;
  width: 92% !important;
}

input[type="time"]:not(.browser-default) {
  border-bottom: none;
  width: 92% !important;
}

input[type="time"]:not(.browser-default):focus:not([readonly]) {
  border-bottom: none;
  box-shadow: none;
  width: 92% !important;
}

.scroll-section {
  max-height: 420px;
  overflow-y: auto;
}

.scroll-section::-webkit-scrollbar {
  width: 6px;
}

.scroll-section::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-section::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.instruction-line {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  color: #333333;
  margin-bottom: 13px;
  margin-top: 0;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: unset !important;
  opacity: 1 !important;
  pointer-events: all !important;
  height: 50px;
  width: 18px;
  margin-right: 10px;
}

.select-all {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e4f0;
  height: 35px;
  border-radius: 4px;
  background: #ffffff;
  width: 100px;
}

.warning-modal {
  width: 450px;
  height: 356px;
  background: #ffffff;
  border-radius: 10px;
}

.content-cntr {
  justify-content: center !important;
}

.content-even {
  justify-content: space-evenly !important;
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

.widgets-icon {
  display: none;
}
.end-cancel-button {
  width: 114px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #37841c;
  border-radius: 4px;
  background: #ffffff;
  color: #37841c;
  cursor: pointer;
}

.end-cancel-button:hover {
  background: #37841c !important;
  color: #ffffff !important;
}

.end-confirm-button {
  width: 114px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #ff7675;
  border-radius: 4px;
  color: #ff7675;
  cursor: pointer;
}

.end-confirm-button:hover {
  background: #ff7675 !important;
  color: #ffffff !important;
}

.mesage-body {
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.cancel-button {
  width: 125px;
  padding-right: 5px;
  padding-left: 5px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #ff7675;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ff7675;
  cursor: pointer;
  margin-top: 10px;
}
@media only screen and (max-width: 1000px) {
  .nav-section-mobile {
    /* flex-wrap: wrap;
    flex-direction: row;
    gap: 20px; */
    position: absolute;
    top: 149px;
    left: -307px;
    z-index: 10;
    background-color: #fff;
    padding: 10px;
    transition: all 0.3s ease-in-out;
    height: 625px !important;
    border-right: 1px solid #e0e4f0;
    /* min-height: 100% !important; */
    /* overflow-y: hidden; */
  }
  .nav-section-mobile.active-nav {
    left: 0 !important;
  }
  .widgets-icon {
    display: block;
    position: absolute;
    top: 149px;
    left: 0;
    background-color: #3751ff;
    border-radius: 0 50% 50% 0;
    width: 35px;
    height: 35px;
    padding: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .close-nav-icon {
    position: absolute;
    top: -20px;
    right: -45px;
    background-color: #3751ff;
    border-radius: 0 50% 50% 0;
    width: 35px;
    height: 35px;
    padding: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .content-right-section {
    padding-top: 30px;
  }
  .content-selection-button {
    width: 200px;
  }
}
</style>
