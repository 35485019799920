<template>
  <div class="manage-section" :style="`width: ${screenWidth}`">
    <div class="manage-section-closed manage-section-header pointer" v-if="showManageSection === false"
      @click="openSectionComponent()">
      <div style="padding: 0;">
        <p class="fs-13 fw-700 index-num blue-font">3</p>
      </div>
      <p class="fs-14 fw-600 blue-font">Manage Sections:</p>
      <div class="flex scroll-horizontal" style="max-width: 990px;width: 100%">
        <div class="manage-section-header content flex" v-for="(data, index) in testData.parts" :key="index">
          <p class="key border-right" style="padding: 0 15px 0 0;white-space: pre">Section<span class="value">{{
            data.sectionName }}</span></p>
          <p class="key border-right" style="white-space: pre">Subjects<span class="value">{{ data.subjectName }}</span>
          </p>
          <p class="key border-right" style="white-space: pre">Chapters<span class="value">{{
            `${data.subjectName}-${data.courseChapterIds.length}` }}</span></p>
          <p class="key border-right" style="white-space: pre">Question Type<span class="value">{{ data.questionTypeName
          }}</span></p>
          <p class="key" style="white-space: pre">Difficulty Level<span class="value">{{ data.difficultyName }}</span></p>
        </div>
      </div>
    </div>
    <div v-if="showManageSection === true">
      <div class="manage-section-open manage-section-header" @click="isManageSectionClosed = true">
        <div style="padding: 0;">
          <p class="fs-13 fw-700 index-num white-font">3</p>
        </div>
        <div>
          <p class="fs-16 fw-700 blue-font">Manage Sections:</p>
          <div class="flex scroll-horizontal" :style="`max-width: ${screenWidth}px`">
            <div class="manage-section-header content flex" v-for="(data, index) in testData.parts" :key="index">
              <p class="key border-right" style="padding: 0 15px 0 0;white-space: pre">Section<span class="value">{{
                data.sectionName }}</span></p>
              <p class="key border-right" style="white-space: pre">Subjects<span class="value">{{ data.subjectName
              }}</span>
              </p>
              <p class="key border-right" style="white-space: pre">Chapters<span class="value">{{
                `${data.subjectName}-${data.courseChapterIds.length}` }}</span></p>
              <p class="key border-right" style="white-space: pre">Question Type<span class="value">{{
                data.questionTypeName }}</span></p>
              <p class="key" style="white-space: pre">Difficulty Level<span class="value">{{ data.difficultyName }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="manage-section-content">
        <div style="overflow-x: scroll">
          <div class="test-details-row">
            <div class="row-title-box white-bg">
              <p class="fw-700 fs-14 blue-font">Test Details</p>
              <p class="fw-400 fs-10 blue-font scroll-horizontal">{{ testData.title }}</p>
            </div>
            <div class="test-details-content">
              <div class="field gray-bg active">
                <p>{{ updateTestDetailTotalMarks }}</p>
              </div>
              <div class="field gray-bg active">
                <p>{{ updateTestDetailTotalQuestion }}</p>
              </div>
              <div class="field gray-bg active">
                <p>{{ updateTestDetailMaximumAttempts }}</p>
              </div>
            </div>
          </div>
          <div class="section-box ">
            <div class="section-box-headings">
              <div class="row-title-box"></div>

              <div style="display: flex; gap: 15px;">
                <div class="section-heading">
                  <p>Total Marks</p>
                </div>
                <div class="section-heading">
                  <p>Total Questions</p>
                </div>
                <div class="section-heading">
                  <p>Maximum Attempts</p>
                </div>
                <div class="section-heading">
                  <p>Marks Per Question</p>
                </div>
                <div class="section-heading">
                  <p>Negative Marks</p>
                </div>
                <div class="section-heading" style="width: 148px;">
                  <p>Section Chapters</p>
                </div>
              </div>
            </div>
            <div class="section-content-box">
              <div class="section-content-row" v-for="(section, index) in this.testData.parts" :key="index">
                <div class="row-title-box white-bg" :class="{ error: checkSectionError(index) }">
                  <p class="fw-700 fs-14 blue-font">Section {{ index + 1 }}</p>
                  <p class="fw-400 fs-10 blue-font">{{ section.sectionName }}</p>
                </div>
                <div class="test-details-content">
                  <div class="field gray-bg active">
                    <p>{{ section.totalMarks }}</p>
                  </div>
                  <div class="field white-bg" :class="{ active: this.testData.parts[index].noOfQuestions > 0 }">
                    <input type="number" :value="section.noOfQuestions" @change="updateTotalQuestion($event, index)">
                  </div>
                  <div class="field white-bg"
                    :class="{ active: this.testData.parts[index].allowedAttempts < this.testData.parts[index].noOfQuestions && this.testData.parts[index].allowedAttempts > 0 || this.testData.parts[index].allowedAttempts === this.testData.parts[index].noOfQuestions && this.testData.parts[index].allowedAttempts > 0 }">
                    <input type="number" :value="section.allowedAttempts" @change="updateMaximumAttempts($event, index)">
                  </div>
                  <div class="field white-bg" :class="{ active: this.testData.parts[index].marksPerQuestion > 0 }">
                    <input type="number" :min="1"
                      :value="(section.marksPerQuestion.toString()).length > 3 ? (section.marksPerQuestion.toString()).slice(0, 3) : section.marksPerQuestion"
                      @change="updateMarksPerQuestion($event, index)">
                  </div>
                  <div class="field white-bg"
                    :class="{ active: this.testData.parts[index].negativeMarks > 0 || this.testData.parts[index].negativeMarks === 0 }">
                    <input type="number" :min="0"
                      :value="(section.negativeMarks.toString()).length > 3 ? (section.negativeMarks.toString()).slice(0, 3) : section.negativeMarks"
                      @change="updateNegativeMarks($event, index)">
                  </div>
                  <div style="width: 148px;">
                    <p class="fs-12 fw-600 blue-font truncate" style="width: 100%;">{{ section.chapters.length > 0 ?
                      section.chapters[0].ChapterName : '' }}</p>
                    <p class="fs-12 fw-600" style="color: #8E8E8E;" v-if="section.chapters.length > 1">+{{
                      section.chapters
                      ? section.chapters.length - 1 : '' }} more</p>
                  </div>
                  <div class="chapter-section">
                    <p></p>
                    <img :src="require(`../assets/edit-icon.svg`)" alt=""
                      @click="openEditSectionModal(JSON.stringify(section), index)">
                    <img v-if="this.testData.parts.length > 1" :src="require(`../assets/delete-icon.svg`)" alt=""
                      @click="deleteSectionHandler(section, index)">
                  </div>
                  <div class="chapter-section">
                    <a @click="move(index, index - 1)" :class="{ disable: index === 0 }"
                      title="UP">
                      <span class="material-icons material-symbols-outlined fs-20 pointer">arrow_upward</span>
                    </a>
                    <a @click="move(index,index+1)"
                      :class="{ disable: this.testData.parts.length - 1 }" title="DOWN">
                      <span class="material-icons material-symbols-outlined fs-20 pointer">arrow_downward
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <button class="generate-section-button" @click="generateSection()">
            <img :src="require(`../assets/AddContent.svg`)" alt="">
            <span>Add New Section</span>
          </button>
        </div>
        <div class="btn-section">
          <button class="green-bg" :class="{ disable: disableNext }" @click="next()">Next</button>
          <!-- <button class="blue-bg" @click="saveTemplate()">Save Template</button> -->
        </div>
      </div>
    </div>
    <Modal2 name="sectionModal" :show="sectionModalPopUp" :showHeader="false" :showCloseBtn="false"
      @close="sectionModalPopUp = false">
      <template v-slot:body>
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-header">
                <p v-if="modalDataList.isAutoGenerated === true" class="fs-16 fw-700 blue-font">Edit Section
                  {{ selectedTestPartIndex + 1 }}</p>
                <p v-if="modalDataList.isAutoGenerated === false" class="fs-16 fw-700 blue-font">Add Section
                  {{ selectedTestPartIndex + 1 }}</p>
                <input class="fs-16 fw-400 blue-font section-name-field" v-model="sectionName"
                  placeholder="Section Name" />
              </div>
              <div class="modal-container-inner">
                <div v-if="!modalDataList.isAutoGenerated" class="modal-subject-section modal-section">
                  <p class="fw-700 fs-14px dark-font mb10">Subject</p>
                  <button class="course-button"
                    :class="{ selected: modalDataList.subjects !== null && modalDataList.subjects.length > 0 && modalDataList.subjects[0].SubjectId === subject.SubjectId }"
                    v-for="(subject, index) in this.courseContentDetails.subjects" :key="index"
                    @click="selectSubject(subject)">{{ subject.SubjectName ? subject.SubjectName : "" }}
                  </button>
                  <span style="font-size:11px;font-style: italic;color:red" v-if="subjectError === true">Please select the
                    subject</span>
                </div>
                <div class="modal-chapter-section modal-section">
                  <div class="modal-cs-header">
                    <p class="fw-700 fs-14px dark-font">Chapters</p>
                    <div class="right">
                      <div class="search-box">
                        <input type="text" v-model="searchChapterText">
                        <img :src="require(`../assets/search-blue-icon.svg`)" alt="">
                      </div>
                      <div>
                        <span style="background-color: #E0E4F0;width: 1px; height: 25px; display: flex;"></span>
                      </div>
                      <div class="select-all-box">
                        <input type="checkbox" id="selectAll" class="pointer"
                          :checked="selectedChapterList.length === modalDataList.chapters.length && selectedChapterList.length > 0"
                          @change="selectAllChapter($event, modalDataList.subjectTopicChapterDetails.topicList)">
                        <label for="selectAll" class="fw-600 fs-12px dark-font pointer">Select All</label>
                      </div>
                    </div>
                  </div>
                  <span style="font-size:11px;font-style: italic;color:red" v-if="chapterError === true">Please select the
                    chapter</span>
                  <div class="mt10 chapter-list">
                    <span class="subject-name-header">{{ modalDataList.subjectTopicChapterDetails.subjectName !== null ||
                      modalDataList.subjectTopicChapterDetails.subjectName !== undefined ?
                      modalDataList.subjectTopicChapterDetails.subjectName : "" }}</span>
                    <div class="flex mt10 flex-column"
                      v-for="(topic, index) in modalDataList.subjectTopicChapterDetails.topicList" :key="index">
                      <div>
                        <img :src="require('../assets/dot-point.svg')" style="margin-right: 10px;" />
                        <span class="topic-name">{{ topic.topicName }}</span>
                      </div>
                      <div class="flex mt10" style="flex-wrap: wrap">
                        <button class="course-button"
                          :class="{ selected: modalDataList.chapters.some((a) => a.CourseChapterId === chapter.CourseChapterId) }"
                          v-for="(chapter, index) in filteredChapterList(topic.chapterList)" :key="index"
                          @click="selectChapters(chapter)">{{ chapter.ChapterName }}</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-questiontype-section modal-section">
                  <p class="fw-700 fs-14px dark-font">Question Type</p>
                  <span style="font-size:11px; font-style: italic; color:red" v-if="questionError === true">Please select
                    the Question Type</span>
                  <div class="questiontype-btns">
                    <div class="questiontype-btns-inner section-btn-inner">
                      <button @click="selectQuestionType(data)" class="duration-button"
                        :class="{ selected: data.QuestionTypeId === modalDataList.questionType }"
                        v-for="(data, index) in modalDataList.questionTypes" :key="index">{{ data.QuestionType }}</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-btns">
                <button class="green-bg white-font" @click="updateTestSectionModalData()">{{ modalDataList.isAutoGenerated
                  === true ? "Update" : "Add" }}</button>
                <button class="cancel-btn" @click="sectionModalPopUp = false">Cancel</button>
              </div>
              <img :src="require(`../assets/red-cross-box.svg`)" alt="" class="close-icon"
                @click="sectionModalPopUp = false; selectedChapterList = [];">
            </div>
          </div>
        </div>
      </template>
    </Modal2>
    <Modal2 :show="showErrorModal" :showHeader="false" :showCloseBtn="false" @close="showErrorModal = false;"
      :radius="'5px !important'">
      <template v-slot:body>
        <div style="padding: 25px; text-align: center; max-width: 450px; min-width: 320px;" class="flex items-center">
          <!-- Waring icon -->
          <span class="material-icons material-symbols-outlined red-text" style="font-size: 60px;">error</span>
          <p class="fs-18 red-text fw-600"
            style="white-space: break-spaces; margin-left: 10px !important; text-align: left;">{{ sectionPartErrorMsg ===
              "" ? editSectionErrorMsg !== "" ? editSectionErrorMsg : '' : sectionPartErrorMsg }}</p>
        </div>
      </template>
    </Modal2>
    <Modal2 :show="deleteSectionModal" :showHeader="false" :showCloseBtn="false" @close="deleteSectionModal = false">
      <template v-slot:body>
        <div class="delete-section-modal">
          <div class="flex content-cntr" style="margin-bottom: 20px;padding-top:30px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" />
          </div>
          <span class="flex content-cntr fs-20 fw-600 ln-27" style="margin-bottom: 8px">Delete Section</span>
          <div class="flex content-cntr" style="flex-direction: column">
            <p class=" mesage-body fs-13 fw-400 ln-17 flex content-cntr" style="white-space: pre-line;">
              Are you sure you want to delete this section?
            </p>
            <p class=" mesage-body fs-13 fw-400 ln-17 flex content-cntr" style="white-space: pre-line;">
              {{ selectedSectionName }}
            </p>
          </div>
          <div class="flex content-even" style="padding:35px">
            <button class="end-cancel-button fs-13 fw-600 ln-18" @click="deleteSection()">YES, DELETE IT</button>
            <button class="end-confirm-button fs-13 fw-600 ln-18" @click="deleteSectionModal = false">NO, GO BACK</button>
          </div>
        </div>
      </template>
    </Modal2>

  </div>
</template>

<script>
import Mobileapi from "../Mobileapi";
import Modal2 from "./Model2.vue";

export default {
  props: {
    isManageSectionOpen: {
      type: Boolean,
    },
    courseDetails: String,
    testDetails: String,
    closeTestComponent: Number,
  },
  data() {
    return {
      sectionModalPopUp: false,
      counter: 1,
      courseContentDetails: [],
      testMarksDetails: [],
      class: "",
      testPartDetailsList: [],
      isManageSectionClosed: true,
      searchChapterText: "",
      modalDataList: [],
      subjectQuestionTypeList: [],
      subjectTopicChapterList: [],
      selectedTestPartIndex: null,
      totalMarks: null,
      totalQuestions: null,
      testData: {
        courseId: "",
        affiliationCourseId: "",
        classes: "",
        subjects: "",
        topics: "",
        chapters: "",
        courseChapterIds: "",
        batches: "",
        totalQuestions: "",
        totalMarks: "",
        duration: "",
        startDateTime: "",
        endDateTime: "",
        title: "",
        excludeUsedQuestions: 1,
        isDraft: 1,
        isTemplate: 0,
        questionGeneration: 0,
        parts: [
        ],
      },
      showErrorModal: false,
      sectionPartErrorMsg: "",
      editSectionErrorMsg: "",
      showManageSection: true,
      count: null,
      closeComponent: this.closeTestComponent,
      noOfQnsError: false,
      sectionIndex: null,
      isError: null,
      isSectionError: null,
      selectedChapterList: [],
      sectionName: "",
      deleteSectionModal: false,
      selectedIndex: null,
      selectedSectionName: "",
      subjectError: false,
      chapterError: false,
      questionError: false,
      dataChanged: false,
      screenWidth: null,
    };
  },
  beforeUnmount() {
    this.closeComponent = undefined;
  },
  mounted() {
    this.screenWidth = (window.innerWidth - 60);
    console.log(this.screenWidth);
    this.emitter.on("close-test-component", (data) => {
      this.showCourse = data.showCourse;
      this.showTest = data.showTest;
      this.showBatchSelect = data.showBatchSelect;
      this.showManageSection = data.showManageSection;
      this.showPublishTest = data.showPublishTest;
    });
    this.emitter.on("open-course-component", (data) => {
      this.showCourse = data.showCourse;
      this.showTest = data.showTest;
      this.showBatchSelect = data.showBatchSelect;
      this.showManageSection = data.showManageSection;
      this.showPublishTest = data.showPublishTest;
    });
    this.emitter.on("open-test-component", (data) => {
      this.showCourse = data.showCourse;
      this.showTest = data.showTest;
      this.showBatchSelect = data.showBatchSelect;
      this.showManageSection = data.showManageSection;
      this.showPublishTest = data.showPublishTest;
    });
    this.emitter.on("change-course-component-data", (data) => {
      this.dataChanged = data.dataChanged;
      if (this.dataChanged === true) {
        this.testData = {
          courseId: "",
          affiliationCourseId: "",
          classes: "",
          subjects: "",
          topics: "",
          chapters: "",
          courseChapterIds: "",
          batches: "",
          totalQuestions: "",
          totalMarks: "",
          duration: "",
          startDateTime: "",
          endDateTime: "",
          title: "",
          excludeUsedQuestions: 1,
          isDraft: 1,
          isTemplate: 0,
          questionGeneration: 0,
          parts: [],
        };
        this.courseContentDetails = [];
        this.testMarksDetails = [];
        this.subjectQuestionTypeList = [];
        this.subjectTopicChapterList = [];
        if (this.courseDetails !== undefined) {
          this.courseContentDetails = JSON.parse(this.courseDetails);
        }
        if (this.testDetails !== undefined) {
          this.testMarksDetails = JSON.parse(this.testDetails);
          this.testData.title = this.testMarksDetails.testName;
          if (this.testMarksDetails.questionType !== null && this.testMarksDetails.questionType.length > 0) {
            this.testMarksDetails.questionType.forEach((questionType) => {
              let subjectQuestionTypeDetail = "";
              this.courseContentDetails.subjectTopicChapterList.forEach((subjectData) => {
                subjectQuestionTypeDetail = {
                  questionTypeDetail: questionType, subjectDetails: subjectData,
                };
                this.subjectQuestionTypeList.push(subjectQuestionTypeDetail);
              });
            });
            for (let i = 0; i < this.subjectQuestionTypeList.length; i += 1) {
              const testPartDetail = {
                questionType: this.subjectQuestionTypeList[i].questionTypeDetail.QuestionTypeId,
                questionTypeName: this.subjectQuestionTypeList[i].questionTypeDetail.QuestionType,
                noOfQuestions: 10,
                marksPerQuestion: 1,
                negativeMarks: 0,
                allowedAttempts: 10,
                questions: [],
                customQuestions: [],
                chapters: [],
                topics: [],
                subjects: this.subjectQuestionTypeList[i].subjectDetails.subjectId,
                subjectName: this.subjectQuestionTypeList[i].subjectDetails.subjectName,
                courseChapterIds: [],
                difficultyLevelId: this.testMarksDetails.difficultyLevel,
                difficultyName: this.testMarksDetails.difficultyName,
                sectionName: `${this.subjectQuestionTypeList[i].subjectDetails.subjectName}-${this.subjectQuestionTypeList[i].questionTypeDetail.QuestionType}`,
                subjectTopicChapterDetails: this.subjectQuestionTypeList[i].subjectDetails,
                questionTypes: this.testMarksDetails.questionType,
                isAutoGenerated: true,
                totalMarks: 10,
                totalQuestions: 10,
              };
              testPartDetail.subjectTopicChapterDetails.topicList.forEach((topic) => {
                const res = topic.chapterList.filter((f) => this.courseContentDetails.chapters.some((item) => item.CourseChapterId === f.CourseChapterId));
                if (res !== null && res.length > 0) {
                  res.forEach((chapterData) => {
                    testPartDetail.chapters.push(chapterData);
                    testPartDetail.courseChapterIds.push(chapterData.CourseChapterId);
                    testPartDetail.topics.push(chapterData);
                  });
                }
              });
              this.testData.parts.push(testPartDetail);
            }
          }
        }
      }
    });
    this.emitter.on("test-details-changed", (data) => {
      this.dataChanged = data.isDataChanged;
      if (this.dataChanged === true) {
        this.testData = {
          courseId: "",
          affiliationCourseId: "",
          classes: "",
          subjects: "",
          topics: "",
          chapters: "",
          courseChapterIds: "",
          batches: "",
          totalQuestions: "",
          totalMarks: "",
          duration: "",
          startDateTime: "",
          endDateTime: "",
          title: "",
          excludeUsedQuestions: 1,
          isDraft: 1,
          isTemplate: 0,
          questionGeneration: 0,
          parts: [],
        };
        this.courseContentDetails = [];
        this.testMarksDetails = [];
        this.subjectQuestionTypeList = [];
        this.subjectTopicChapterList = [];
        if (this.courseDetails !== undefined) {
          this.courseContentDetails = JSON.parse(this.courseDetails);
        }
        if (this.testDetails !== undefined) {
          this.testMarksDetails = JSON.parse(this.testDetails);
          this.testData.title = this.testMarksDetails.testName;
          if (this.testMarksDetails.questionType !== null && this.testMarksDetails.questionType.length > 0) {
            this.testMarksDetails.questionType.forEach((questionType) => {
              let subjectQuestionTypeDetail = "";
              this.courseContentDetails.subjectTopicChapterList.forEach((subjectData) => {
                subjectQuestionTypeDetail = {
                  questionTypeDetail: questionType, subjectDetails: subjectData,
                };
                this.subjectQuestionTypeList.push(subjectQuestionTypeDetail);
              });
            });
            for (let i = 0; i < this.subjectQuestionTypeList.length; i += 1) {
              const testPartDetail = {
                questionType: this.subjectQuestionTypeList[i].questionTypeDetail.QuestionTypeId,
                questionTypeName: this.subjectQuestionTypeList[i].questionTypeDetail.QuestionType,
                noOfQuestions: 10,
                marksPerQuestion: 1,
                negativeMarks: 0,
                allowedAttempts: 10,
                questions: [],
                customQuestions: [],
                chapters: [],
                topics: [],
                subjects: this.subjectQuestionTypeList[i].subjectDetails.subjectId,
                subjectName: this.subjectQuestionTypeList[i].subjectDetails.subjectName,
                courseChapterIds: [],
                difficultyLevelId: this.testMarksDetails.difficultyLevel,
                difficultyName: this.testMarksDetails.difficultyName,
                sectionName: `${this.subjectQuestionTypeList[i].subjectDetails.subjectName}-${this.subjectQuestionTypeList[i].questionTypeDetail.QuestionType}`,
                subjectTopicChapterDetails: this.subjectQuestionTypeList[i].subjectDetails,
                questionTypes: this.testMarksDetails.questionType,
                isAutoGenerated: true,
                totalMarks: 10,
                totalQuestions: 10,
              };
              testPartDetail.subjectTopicChapterDetails.topicList.forEach((topic) => {
                const res = topic.chapterList.filter((f) => this.courseContentDetails.chapters.some((item) => item.CourseChapterId === f.CourseChapterId));
                if (res !== null && res.length > 0) {
                  res.forEach((chapterData) => {
                    testPartDetail.chapters.push(chapterData);
                    testPartDetail.courseChapterIds.push(chapterData.CourseChapterId);
                    testPartDetail.topics.push(chapterData);
                  });
                }
              });
              this.testData.parts.push(testPartDetail);
            }
          }
        }
      }
    });
  },
  created() {
    this.screenWidth = (window.innerWidth - 60);
    console.log(this.screenWidth);
    if (this.courseDetails !== undefined) {
      this.courseContentDetails = JSON.parse(this.courseDetails);
    }
    if (this.testDetails !== undefined) {
      this.testMarksDetails = JSON.parse(this.testDetails);
      this.testData.title = this.testMarksDetails.testName;
      if (this.testMarksDetails.questionType !== null && this.testMarksDetails.questionType.length > 0) {
        this.testMarksDetails.questionType.forEach((questionType) => {
          let subjectQuestionTypeDetail = "";
          this.courseContentDetails.subjectTopicChapterList.forEach((subjectData) => {
            subjectQuestionTypeDetail = {
              questionTypeDetail: questionType, subjectDetails: subjectData,
            };
            this.subjectQuestionTypeList.push(subjectQuestionTypeDetail);
          });
        });
        for (let i = 0; i < this.subjectQuestionTypeList.length; i += 1) {
          const testPartDetail = {
            questionType: this.subjectQuestionTypeList[i].questionTypeDetail.QuestionTypeId,
            questionTypeName: this.subjectQuestionTypeList[i].questionTypeDetail.QuestionType,
            noOfQuestions: 10,
            marksPerQuestion: 1,
            negativeMarks: 0,
            allowedAttempts: 10,
            questions: [],
            customQuestions: [],
            chapters: [],
            topics: [],
            subjects: this.subjectQuestionTypeList[i].subjectDetails.subjectId,
            subjectName: this.subjectQuestionTypeList[i].subjectDetails.subjectName,
            courseChapterIds: [],
            difficultyLevelId: this.testMarksDetails.difficultyLevel,
            difficultyName: this.testMarksDetails.difficultyName,
            sectionName: `${this.subjectQuestionTypeList[i].subjectDetails.subjectName}-${this.subjectQuestionTypeList[i].questionTypeDetail.QuestionType}`,
            subjectTopicChapterDetails: this.subjectQuestionTypeList[i].subjectDetails,
            questionTypes: this.testMarksDetails.questionType,
            // testCategoryId: this.testMarksDetails.testCategoryId,
            isAutoGenerated: true,
            totalMarks: 10,
            totalQuestions: 10,
          };
          testPartDetail.subjectTopicChapterDetails.topicList.forEach((topic) => {
            const res = topic.chapterList.filter((f) => this.courseContentDetails.chapters.some((item) => item.CourseChapterId === f.CourseChapterId));
            if (res !== null && res.length > 0) {
              res.forEach((chapterData) => {
                testPartDetail.chapters.push(chapterData);
                testPartDetail.courseChapterIds.push(chapterData.CourseChapterId);
                testPartDetail.topics.push(chapterData);
              });
            }
          });
          this.testData.parts.push(testPartDetail);
        }
      }
    }
  },
  computed: {
    updateTestDetailTotalMarks() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.totalMarks = this.testData.parts.reduce((total, obj) => Number(obj.marksPerQuestion * obj.allowedAttempts) + total, 0);
      return this.totalMarks;
    },
    updateTestDetailTotalQuestion() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.totalQuestions = this.testData.parts.reduce((total, obj) => Number(obj.noOfQuestions) + total, 0);
      return this.testData.parts.reduce((total, obj) => Number(obj.noOfQuestions) + total, 0);
    },
    updateTestDetailMaximumAttempts() {
      return this.testData.parts.reduce((total, obj) => Number(obj.allowedAttempts) + total, 0);
    },
    disableNext() {
      let isError = false;
      for (let i = 0; i < this.testData.parts.length; i += 1) {
        if (this.testData.parts[i].noOfQuestions === 0 || this.testData.parts[i].noOfQuestions < 0) {
          isError = true;
          break;
        } if (this.testData.parts[i].allowedAttempts > this.testData.parts[i].noOfQuestions || this.testData.parts[i].allowedAttempts < 1) {
          isError = true;
          break;
        } if (this.testData.parts[i].marksPerQuestion === 0 || this.testData.parts[i].marksPerQuestion < 0) {
          isError = true;
          break;
        } if (this.testData.parts[i].negativeMarks < 0) {
          isError = true;
          break;
        }
      }
      return isError;
    },
  },
  components: {
    Modal2,
  },
  methods: {
    move(from, to) {
      // eslint-disable-next-line vue/no-mutating-props
      this.testData.parts.splice(to, 0, this.testData.parts.splice(from, 1)[0]);
    },
    selectAllChapter(event, chapter) {
      this.modalDataList.chapters = [];
      this.modalDataList.courseChapterIds = [];
      if (event.target.checked) {
        let courseChapterList = chapter.map((item) => item.chapterList.map((item2) => item2));
        courseChapterList = courseChapterList.flat();
        this.modalDataList.chapters = courseChapterList;
        this.modalDataList.courseChapterIds = courseChapterList.map((item) => item.CourseChapterId);
        const chapters = this.modalDataList.chapters.map((item) => [item.ChapterId]);
        const data = {
          ChapterIds: chapters.join(","),
          TopicId: 0,
          SubjectId: 0,
          CourseId: 0,
          ClassId: 0,
          UserId: 0,
        };
        Mobileapi.getQuestionTypeAvailableForTest(data, (response) => {
          this.modalDataList.questionTypes = response.data;
          // this.selectQuestionType(this.questionList[0]);
          // this.testDetailsData.questionType.push(this.questionList[0]);
        });
      }
      this.modalDataList.questionType = null;
      this.modalDataList.questionTypeName = "";
    },
    filteredChapterList(chapters) {
      return chapters.filter((chapter) => chapter.ChapterName.toLowerCase().includes(this.searchChapterText.toLowerCase()));
    },
    // eslint-disable-next-line no-unused-vars
    openEditSectionModal(sectionData, index) {
      this.subjectError = false;
      this.chapterError = false;
      this.questionError = false;
      this.selectedChapterList = [];
      this.questionError = false;
      this.modalDataList = JSON.parse(sectionData);
      this.sectionName = this.modalDataList.sectionName;
      this.selectedTestPartIndex = index;
      this.sectionModalPopUp = true;
      if (Object.values(this.modalDataList.subjectTopicChapterDetails).length > 0 && this.modalDataList.subjectTopicChapterDetails.topicList !== undefined) {
        let courseChapterList = this.modalDataList.subjectTopicChapterDetails.topicList.map((item) => item.chapterList.map((item2) => item2));
        courseChapterList = courseChapterList.flat();
        this.selectedChapterList = courseChapterList;
      }
    },
    selectChapters(chapterData) {
      if (this.modalDataList.chapters.some((a) => chapterData.ChapterName === a.ChapterName)) {
        const index = this.modalDataList.chapters.findIndex((obj) => obj.ChapterName === chapterData.ChapterName);
        const index1 = this.modalDataList.courseChapterIds.indexOf(chapterData.CourseChapterId);
        const index2 = this.modalDataList.topics.findIndex((obj) => obj.ChapterName === chapterData.ChapterName);
        if (index > -1) {
          this.modalDataList.chapters.splice(index, 1);
        }
        if (index1 > -1) {
          this.modalDataList.courseChapterIds.splice(index1, 1);
        }
        if (index2 > -1) {
          this.modalDataList.topics.splice(index2, 1);
        }
      } else {
        this.modalDataList.chapters.push(chapterData);
        this.modalDataList.courseChapterIds.push(chapterData.CourseChapterId);
        this.modalDataList.topics.push(chapterData);
      }
      this.modalDataList.questionType = null;
      this.modalDataList.questionTypeName = "";
      const chapters = this.modalDataList.chapters.map((item) => [item.ChapterId]);
      const data = {
        ChapterIds: chapters.join(","),
        TopicId: 0,
        SubjectId: 0,
        CourseId: 0,
        ClassId: 0,
        UserId: 0,
      };
      Mobileapi.getQuestionTypeAvailableForTest(data, (response) => {
        this.modalDataList.questionTypes = response.data;
        // this.selectQuestionType(this.questionList[0]);
        // this.testDetailsData.questionType.push(this.questionList[0]);
      });
    },
    selectSubject(subject) {
      this.modalDataList.subjects = [];
      this.modalDataList.subjectName = subject.SubjectName;
      this.modalDataList.subjects.push(subject);
      this.courseContentDetails.subjectTopicChapterList.forEach((element) => {
        if (element.subjectId === subject.SubjectId) { this.modalDataList.subjectTopicChapterDetails = element; }
      });
      let courseChapterList = this.modalDataList.subjectTopicChapterDetails.topicList.map((item) => item.chapterList.map((item2) => item2));
      courseChapterList = courseChapterList.flat();
      this.selectedChapterList = courseChapterList;
    },
    selectQuestionType(questionType) {
      if (this.sectionName === "") {
        this.sectionName = `${this.modalDataList.subjectTopicChapterDetails.subjectName}-${questionType.QuestionType}`;
        this.modalDataList.sectionName = `${this.modalDataList.subjectTopicChapterDetails.subjectName}-${questionType.QuestionType}`;
      } else if (this.sectionName !== `${this.modalDataList.subjectTopicChapterDetails.subjectName}-${questionType.QuestionType}` && this.modalDataList.questionTypeName !== questionType.QuestionType) {
        this.modalDataList.sectionName = `${this.modalDataList.subjectTopicChapterDetails.subjectName}-${questionType.QuestionType}`;
        this.sectionName = `${this.modalDataList.subjectTopicChapterDetails.subjectName}-${questionType.QuestionType}`;
      }
      this.modalDataList.questionType = questionType.QuestionTypeId;
      this.modalDataList.questionTypeName = questionType.QuestionType;
      // this.sectionName = `${this.modalDataList.subjectTopicChapterDetails.subjectName}-${questionType.QuestionType}`;
    },
    selectDifficultyLevel(event) {
      const level = Number(event.target.value);
      let data = {};
      if (level === 1) {
        data = {
          difficultyLevel: 1,
          difficultyGroup: "Easy",
        };
      }
      if (level === 2) {
        data = {
          difficultyLevel: 2,
          difficultyGroup: "Medium",
        };
      }
      if (level === 3) {
        data = {
          difficultyLevel: 3,
          difficultyGroup: "Hard",
        };
      }
      this.modalDataList.difficultyLevelId = data.difficultyLevel;
    },
    updateTestSectionModalData() {
      if (this.modalDataList.subjects.length === 0 && this.modalDataList.isAutoGenerated === false) {
        this.subjectError = true;
      } else if (this.modalDataList.chapters.length === 0 && this.modalDataList.isAutoGenerated === false) {
        this.subjectError = false;
        this.chapterError = true;
      } else if (this.modalDataList.questionTypeName === "" && this.modalDataList.isAutoGenerated === false) {
        this.subjectError = false;
        this.chapterError = false;
        this.questionError = true;
      } else if (this.modalDataList.subjects.length !== 0 && this.modalDataList.chapters.length !== 0 && this.modalDataList.questionTypeName !== "") {
        if (this.modalDataList.sectionName === "") {
          this.modalDataList.sectionName = `${this.modalDataList.subjectTopicChapterDetails.subjectName}-${this.modalDataList.questionTypeName}`;
          this.sectionName = `${this.modalDataList.subjectTopicChapterDetails.subjectName}-${this.modalDataList.questionTypeName}`;
        }
        if (this.modalDataList.isAutoGenerated === false) {
          this.modalDataList.isAutoGenerated = true;
        }
        if (this.sectionName !== this.modalDataList.sectionName) {
          this.modalDataList.sectionName = this.sectionName;
        }
        if (this.selectedTestPartIndex >= 0) {
          if ((this.testData.parts.length - 1) < this.selectedTestPartIndex) {
            this.testData.parts.push(this.modalDataList);
            this.sectionModalPopUp = false;
          } else {
            this.testData.parts[this.selectedTestPartIndex] = this.modalDataList;
            this.sectionModalPopUp = false;
          }
        }
      }
    },
    generateSection() {
      // this.counter += 1;
      const testPartDetail = {
        questionType: "",
        questionTypeName: "",
        noOfQuestions: 10,
        marksPerQuestion: 1,
        negativeMarks: 0,
        allowedAttempts: 10,
        questions: [],
        customQuestions: [],
        chapters: [],
        topics: [],
        subjects: [],
        subjectName: "",
        courseChapterIds: [],
        difficultyLevelId: this.testMarksDetails.difficultyLevel,
        difficultyName: this.testMarksDetails.difficultyName,
        sectionName: "",
        subjectTopicChapterDetails: {},
        isAutoGenerated: false,
        questionTypes: this.testMarksDetails.questionType,
        totalMarks: 10,
        totalQuestions: 10,
      };
      if (testPartDetail !== undefined) {
        // this.testData.parts.push(testPartDetail);
        this.selectedTestPartIndex = this.testData.parts.length;
        this.openEditSectionModal(JSON.stringify(testPartDetail), this.selectedTestPartIndex);
      }
    },
    deleteSectionHandler(section, index) {
      this.deleteSectionModal = true;
      this.selectedSectionName = section.sectionName;
      this.selectedIndex = index;
    },
    deleteSection() {
      this.testData.parts.splice(this.selectedIndex, 1);
      this.deleteSectionModal = false;
    },
    checkTotalQnsError(index) {
      if (this.testData.parts[index].noOfQuestions > 0) {
        // this.isError = false;
        return false;
      }
      // this.isError = true;
      return true;
    },
    checkMaxAttmptError(index) {
      if (this.testData.parts[index].allowedAttempts > this.testData.parts[index].noOfQuestions) {
        this.isError = true;
        return true;
      }
      this.isError = false;
      return false;
    },
    checkMarksPerQnsError(index) {
      if (this.testData.parts[index].marksPerQuestion === 0 || this.testData.parts[index].marksPerQuestion < 0) {
        this.isError = true;
        return true;
      }
      this.isError = false;
      return false;
    },
    checkNegativeMarksError(index) {
      if (this.testData.parts[index].negativeMarks < 0) {
        this.isError = true;
        return true;
      }
      this.isError = false;
      return false;
    },
    checkSectionError(index) {
      if (this.testData.parts[index].subjects === null) {
        this.isSectionError = true;
        return true;
      } if (this.testData.parts[index].chapters.length === 0) {
        this.isSectionError = true;
        return true;
      } if (this.testData.parts[index].questionType === "") {
        this.isSectionError = true;
        return true;
      }
      this.isSectionError = false;
      return false;
    },
    updateTotalQuestion(event, index) {
      this.testData.parts[index].totalQuestions = Number(event.target.value);
      this.testData.parts[index].noOfQuestions = Number(event.target.value);
      this.testData.parts[index].allowedAttempts = Number(event.target.value);
      this.testData.parts[index].totalMarks = this.testData.parts[index].allowedAttempts * this.testData.parts[index].marksPerQuestion;
    },
    updateMarksPerQuestion(event, index) {
      const limit = event.target.value.length;
      if (limit > 3) {
        const data = event.target.value.slice(0, 3);
        this.testData.parts[index].marksPerQuestion = Number(data);
        this.testData.parts[index].totalMarks = this.testData.parts[index].allowedAttempts * this.testData.parts[index].marksPerQuestion;
      } else {
        this.testData.parts[index].marksPerQuestion = Number(event.target.value);
        this.testData.parts[index].totalMarks = this.testData.parts[index].allowedAttempts * this.testData.parts[index].marksPerQuestion;
      }
    },
    updateMaximumAttempts(event, index) {
      this.testData.parts[index].allowedAttempts = Number(event.target.value);
      this.testData.parts[index].totalMarks = this.testData.parts[index].allowedAttempts * this.testData.parts[index].marksPerQuestion;
    },
    updateNegativeMarks(event, index) {
      const limit = event.target.value.length;
      if (limit > 3) {
        const data = event.target.value.slice(0, 3);
        this.testData.parts[index].negativeMarks = Number(data);
      } else {
        this.testData.parts[index].negativeMarks = Number(event.target.value);
      }
    },
    next() {
      if (this.courseContentDetails.classes.length === 1) {
        this.class = this.courseContentDetails.classes[0].ClassId;
      } else {
        const Class = this.courseContentDetails.classes.map((item) => [item.ClassId]);
        this.class = Class.join(",");
        // this.class = this.class.join(",");
      }
      this.testData.courseId = this.courseContentDetails.courseId;
      this.testData.affiliationCourseId = this.courseContentDetails.affilationCourseId;
      this.testData.classes = String(this.class);
      const subjects = this.courseContentDetails.subjects.map((item) => item.SubjectId);
      this.testData.subjects = subjects.join(",");
      // this.testData.subjects = this.courseContentDetails.subjects;
      let topics = [];
      topics = this.courseContentDetails.topics.map((item) => item.TopicId);
      const topicIds = [...new Set(topics)];
      this.testData.topics = topicIds.join(",");
      // this.testData.topics = this.courseContentDetails.topics;
      const chapters = this.courseContentDetails.chapters.map((item) => item.ChapterId);
      const chapterIds = [...new Set(chapters)];
      this.testData.chapters = chapterIds.join(",");
      const courseChapters = this.courseContentDetails.chapters.map((item) => item.CourseChapterId);
      const courseChapterIds = [...new Set(courseChapters)];
      // this.testData.chapters = this.courseContentDetails.chapters;
      this.testData.courseChapterIds = courseChapterIds.join(",");
      this.testData.duration = this.testMarksDetails.duration;
      this.testData.totalQuestions = this.totalQuestions;
      this.testData.totalMarks = this.totalMarks;
      this.testData.startDateTime = this.testMarksDetails.startDate;
      this.testData.endDateTime = this.testMarksDetails.endDate;
      this.testData.isTemplate = 1;
      this.testData.title = this.testMarksDetails.testName;
      this.testData.testCategoryId = this.testMarksDetails.testCategoryId;
      this.showManageSection = false;
      this.count += 1;
      this.emitter.emit("close-section-component", {
        showCourse: false,
        showTest: false,
        showManageSection: false,
        showBatchSelect: true,
        showPublishTest: false,
      });
      this.emitter.emit("change-manage-section-data", {
        isDataChanged: true,
        testData: JSON.stringify(this.testData),
      });
      this.$emit("closeSection", this.count);
      this.$emit("closeManageSection", false);
      this.$emit("testCreationData", this.testData);
    },
    saveTemplate() {
      if (this.sectionPartErrorMsg === "") {
        if (this.courseContentDetails.classes.length === 1) {
          this.class = this.courseContentDetails.classes[0].ClassId;
        } else {
          const Class = this.courseContentDetails.classes.map((item) => [item.ClassId]);
          this.class = Class.join(",");
          // this.class = this.class.join(",");
        }
        this.testData.courseId = this.courseContentDetails.courseId;
        this.testData.affiliationCourseId = this.courseContentDetails.affilationCourseId;
        this.testData.classes = String(this.class);
        const subjects = this.courseContentDetails.subjects.map((item) => [item.SubjectId]);
        this.testData.subjects = subjects.join(",");
        // this.testData.subjects = this.courseContentDetails.subjects;
        const topics = this.courseContentDetails.topics.map((item) => item.TopicId);
        const topicIds = [...new Set(topics)];
        this.testData.topics = topicIds.join(",");
        // this.testData.topics = this.courseContentDetails.topics;
        const chapters = this.courseContentDetails.chapters.map((item) => item.ChapterId);
        const chapterIds = [...new Set(chapters)];
        this.testData.chapters = chapterIds.join(",");
        const courseChapters = this.courseContentDetails.chapters.map((item) => item.CourseChapterId);
        const courseChapterIds = [...new Set(courseChapters)];
        // this.testData.chapters = this.courseContentDetails.chapters;
        this.testData.courseChapterIds = courseChapterIds.join(",");
        this.testData.duration = this.testMarksDetails.duration;
        this.testData.totalQuestions = this.totalQuestions;
        this.testData.totalMarks = this.totalMarks;
        this.testData.startDateTime = this.testMarksDetails.startDate;
        this.testData.endDateTime = this.testMarksDetails.endDate;
        this.testData.isTemplate = 1;
        this.testData.title = this.testMarksDetails.testName;
        this.testData.parts.forEach((item) => {
          if (item.subjects.length === 0) {
            this.showErrorModal = true;
            this.editSectionErrorMsg = "Please select subject.";
          } else if (item.chapters.length === 0) {
            this.showErrorModal = true;
            this.editSectionErrorMsg = "Please select chapter.";
          } else if (item.questionType === "") {
            this.showErrorModal = true;
            this.editSectionErrorMsg = "Please select question type.";
          } else {
            this.editSectionErrorMsg = "";
            this.showErrorModal = false;
          }
        });
        if (this.editSectionErrorMsg === "" && this.showErrorModal === false) {
          Mobileapi.addUpdateInstituteTestV2(this.testData, (response) => {
            this.templateData = response.data;
          });
          this.$emit("closeManageSection", false);
          this.$emit("testCreationData", this.testData);
        }
      } else {
        this.showErrorModal = true;
      }
    },
    openSectionComponent() {
      this.showManageSection = true;
      this.emitter.emit("open-section-component", {
        showCourse: false,
        showTest: false,
        showManageSection: true,
        showBatchSelect: undefined,
        showPublishTest: false,
      });
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&display=swap');

* {
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.023);
  background-color: #F9F9F9;
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #F9F9F9;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #999FB7;
}

.mt-2 {
  margin-top: -2px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.pd20 {
  padding: 20px !important;
}

.flex {
  display: flex !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-end {
  justify-content: flex-end !important;
}

.black-font {
  color: #000;
}

.white-font {
  color: #fff;
}

.blue-font {
  color: #3751FF;
}

.dark-font {
  color: #333;
}

.mb10 {
  margin-bottom: 10px !important;
}

.gray-font {
  color: #676767;
}

.gray-bg {
  background-color: #e3e3e3;
}

.white-bg {
  background-color: #fff;
}

.green-bg {
  background-color: #37841C;
}

.green-bg.disable {
  background: grey !important;
  color: #FFFFFF !important;
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.blue-bg {
  background-color: #3751FF;
}

.manage-section {
  width: 100%;
  background-color: #fff;
}

.manage-section-closed {
  display: flex;
  width: 100%;
  padding: 14px 22px;
  align-items: center;
  border-bottom: 1px solid #E0E4F0;
  white-space: nowrap;
}

.manage-section-closed>* {
  display: flex;
  align-items: center;
  padding: 0 15px;
  gap: 10px;
}

.border-right {
  border-right: 1px solid #E0E4F0;
}

.manage-section-closed .index-num {
  border: 1px solid #E0E4F0;
  border-radius: 3px;
  padding: 1px 6px;
}

.manage-section-open {
  display: flex;
  width: 100%;
  padding: 15px;
  gap: 12px;
  align-items: center;
  border-bottom: 1px solid #E0E4F0;
}

.manage-section-open .index-num {
  background-color: #3751FF;
  border-radius: 3px;
  padding: 6px 11px;
}

.manage-section-open .content {
  display: flex;
}

.content p {
  display: flex;
  gap: 10px;
  padding: 0 15px;
  align-items: center;
}

.manage-section-header .key {
  color: #333;
  font-size: 12px;
  font-weight: 600;
}

.manage-section-header .value {
  color: #676767;
  font-size: 12px;
  font-weight: 400;
}

.manage-section-content {
  background-color: #F7F8FD;
  padding: 24px 35px;
}

.test-details-row {
  display: flex;
  gap: 18px;
  margin-bottom: 11px;
}

.test-details-content {
  display: flex;
  gap: 15px;
  align-items: center;
}

.field {
  height: 44px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #E0E4F0; */
  border: 1px solid red;
  border-radius: 4px;
  color: #333;
}

/* .field.error{
border: 1px solid red;
} */
.field.active {
  border: 1px solid #E0E4F0;
}

.field input {
  text-align: center;
  border: none;
  margin: 0;
  color: #333;
}

/* input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
} */

.field input:focus {
  border: none !important;
  box-shadow: none !important;
}

.row-title-box {
  min-width: 180px;
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  padding: 5px 12px;
}

.row-title-box.error {
  border: 1px solid red;
}

.section-box-headings {
  display: flex;
  gap: 18px;
  margin-bottom: 18px;
}

.section-heading {
  width: 80px;
  height: 44px;
  display: flex;
  align-items: flex-end;
}

.section-content-box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}

.section-heading p {
  color: #333;
  font-size: 11px;
  font-weight: 700;
}

.section-content-row {
  display: flex;
  gap: 18px;
}

input:focus {
  border-bottom: 1px solid #3751FF !important;
  box-shadow: 0 1px 0 0 #3751FF !important;
}

.chapter-section {
  display: flex;
  align-items: center;
  flex: 1;
  cursor: pointer;
  gap: 10px;
}

.chapter-section img {
  width: 24px;
  height: 24px;
}

.generate-section-button {
  background-color: #FFFFFF;
  border-radius: 4px;
  height: 50px;
  width: 180px;
  padding: 15px 18px;
  display: flex;
  align-items: center;
  gap: 12px;
  border: none;
  cursor: pointer;
}

.generate-section-button span {
  font-size: 14px;
  font-weight: 600;
  color: #3751ff;
}

.generate-section-button img {
  width: 15px;
  height: 15px;
}

.btn-section {
  display: flex;
  gap: 20px;
  margin-top: 37px;
}

.btn-section button {
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  padding: 6px 29px;
  color: #fff;
  border: none;
  cursor: pointer;
}

/* section modal  */

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: table;
  transition: opacity 0.3s ease;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 100%;
  width: 50%;
  height: 75%;
  margin: 0px auto;
  padding: 14px 24px 24px 24px;
  background-color: #fff;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 5px;
  border: 1px solid #E0E4F0;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  position: relative;
}

.modal-container-inner {
  max-height: 390px;
  overflow-y: auto;
  border-radius: 5px;
  border-bottom: 1px solid #E0E4F0;
}

.modal-container-inner::-webkit-scrollbar-track {
  background-color: transparent;
}

.modal-container-inner::-webkit-scrollbar-thumb {
  opacity: 0;
  visibility: hidden;
}

.modal-container-inner::-webkit-scrollbar {
  background-color: transparent;
}

.modal-container:hover .modal-container-inner::-webkit-scrollbar-thumb {
  opacity: 1;
  visibility: visible;
}

.modal-container:hover .modal-container-inner::-webkit-scrollbar-track {
  background-color: #F9F9F9;
}

.modal-container:hover .modal-container-inner::-webkit-scrollbar {
  background-color: #F9F9F9;
}

.modal-header {
  display: flex;
  flex-direction: row;
  gap: 17px;
  align-items: center;
  margin-bottom: 15px;
}

.modal-header p:nth-child(2) {
  border: 1px solid #E0E4F0;
  border-radius: 4px;
  padding: 9px 15px;
}

.modal-section {
  background: #F7F8FD;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 15px;
}

.subject-btns,
.chapter-btns,
.questiontype-btns {
  /* max-height: 100px; */
  margin-top: 16px;
}

.section-btn-inner {
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.subject-btns-inner,
.questiontype-btns-inner {
  max-height: 70px;
}

.chapter-btns-inner {
  max-height: 110px;
}

.section-btn-inner button {
  padding: 6px 15px;
  font-size: 12px;
  height: 28px;
  font-weight: 600;
  background-color: #fff;
  border: none;
  border-radius: 18px;
  cursor: pointer;
}

.modal-cs-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.modal-cs-header .right {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
}

.search-box {
  display: flex;
  padding: 12px;
  background: #FFFFFF;
  border: 1px solid #E0E4F0;
  border-radius: 4px;
  align-items: center;
  height: 40px;
}

.search-box input {
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
}

.search-box img {
  width: 17px;
  height: 17px;
}

.select-all-box {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #FFFFFF;
  border: 1px solid #E0E4F0;
  border-radius: 4px;
  padding: 11px;
}

.select-all-box input {
  border-radius: 3px;
  width: 18px;
  height: 18px;
}

.modal-btns {
  display: flex;
  gap: 10px;
  position: absolute;
  bottom: 20px;
  left: 40px;
}

.modal-btns button {
  padding: 6px 25px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.modal-btns .cancel-btn {
  border: 1px solid #EF727A;
  color: #EF727A;
  background-color: #fff;
}

.close-icon {
  position: absolute;
  top: 20px;
  right: 24px;
  cursor: pointer;
}

.sectionModal-enter {
  opacity: 0;
}

.sectionModal-leave-active {
  opacity: 0;
}

.sectionModal-enter .modal-container,
.sectionModal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -ms-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.subject-name-header {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
}

.course-button {
  height: 28px;
  background: #FFFFFF;
  border: 1px solid #E0E4F0;
  border-radius: 18px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #333333;
}

.duration-button {
  height: 28px;
  background: #FFFFFF;
  border: 1px solid #E0E4F0 !important;
  border-radius: 18px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #333333;
}

.topic-name {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #3751FF;
}

.selected {
  background: #3751FF !important;
  color: #FFFFFF;
  ;
}

[type=checkbox]:not(:checked),
[type=checkbox]:checked {
  position: relative;
  opacity: 1;
  pointer-events: visible;
}

[type=checkbox]:focus {
  border: none !important;
  box-shadow: none !important;
}

.section-name-field {
  width: 300px;
  border: 1px solid #EEEEEE;
  border-radius: 5px;
  padding-left: 15px;
  height: 40px;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.delete-section-modal {
  width: 450px;
  height: 356px;
  background: #FFFFFF;
  border-radius: 10px;
}

.content-cntr {
  justify-content: center !important;
}

.content-even {
  justify-content: space-evenly !important;
}

.end-cancel-button {
  width: 114px;
  height: 34px;
  background: #FFFFFF;
  border: 1px solid #37841C;
  border-radius: 4px;
  background: #FFFFFF;
  color: #37841C;
  cursor: pointer;
}

.end-cancel-button:hover {
  background: #37841C !important;
  color: #FFFFFF !important;
}

.end-confirm-button {
  width: 114px;
  height: 34px;
  background: #FFFFFF;
  border: 1px solid #FF7675;
  border-radius: 4px;
  color: #FF7675;
  cursor: pointer;
}

.end-confirm-button:hover {
  background: #FF7675 !important;
  color: #FFFFFF !important;
}

.mesage-body {
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
}

@media screen and (max-width: 720px) {
  .modal-container {
    width: 90%;
    height: 80%;
    padding: 14px 18px 18px 18px;
  }

  .section-name-field {
    width: 200px;
  }

  .modal-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .modal-cs-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .modal-section {
    background: #F7F8FD;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    width: 300px;
  }

  .course-button {
    min-height: 35px !important;
    /* max-width: 200px; */
    white-space: break-spaces;
  }

  .publish-modal-button {
    width: 130px;
    height: 40px;
    background: #37841C;
    border-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    border: none;
    margin-right: 30px;
    cursor: pointer;
  }
}
</style>
