<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    class="close-component-heading flex pointer"
    v-if="showBatchSelect === false"
    @click="openBatchComponent()"
  >
    <button class="close-section-button">4</button>
    <span class="close-section-heading">Select Batches:</span>
    <div class="flex fs-12" style="margin-top: 2px; padding-left: 15px">
      <div
        v-if="displayBatchName.length !== 0"
        class="scroll-horizontal"
        v-dragscroll="true"
        style="max-width: 1010px; width: 100%"
      >
        <span class="fw-600">Batch</span>&ensp;
        <span class="fw-400 fs-12 blk-text" v-for="(data, index) in displayBatchName" :key="index"
          >{{ data }} &nbsp;</span
        >
      </div>
    </div>
  </div>
  <div v-if="showBatchSelect === true">
    <div class="component-heading flex flex-row">
      <button class="section-button">4</button>
      <div class="flex flex-column mt-2">
        <span class="content-heading">Select Batches</span>
      </div>
    </div>
    <div
      class="col s12 l9 content-right-section flex flex-column"
      style="background: #f7f8fd; padding-top: 20px; padding-left: 45px"
    >
      <div v-if="courseBatchList.length > 0">
        <div class="content-search">
          <input type="text" style="max-width: 93%" placeholder="Search..." v-model="searchValue" />
          <img
            class="search_img"
            :src="require(`../assets/Search.svg`)"
            alt="Search"
            style="position: absolute; top: 10px; right: 10px"
          />
        </div>
        <div class="course-btn-container" v-if="filteredBatchList.length > 0">
          <div class="scroll-section">
            <div v-for="(data, index1) in filteredBatchList" :key="index1" class="mt10">
              <div class="flex flex-between items-center" style="padding-right: 20px">
                <p class="subject-name" v-if="data.batches.length > 0">
                  {{ data.AffiliationName }}
                </p>
                <div class="select-all" v-if="data.batches.length > 0">
                  <input
                    type="checkbox"
                    :id="index1 + 'chapter'"
                    class="pointer"
                    :checked="checkIsAllBatchSelected(index1, data.AffiliationId)"
                    @change="selectAllBatch($event, data, index1, data.AffiliationId)"
                    name="chapter"
                  />
                  <label :for="index1 + 'chapter'" class="pointer fw-400 fs-13 dark-text"
                    >Select All</label
                  >
                </div>
              </div>
              <div class="flex mt10" style="flex-wrap: wrap">
                <button
                  class="course-button"
                  :class="{ selected: batchIdList.includes(topic) }"
                  v-for="(topic, index) in data.batches"
                  :key="index"
                  @click="onSelectBatch(topic, data.Name, index1)"
                >
                  {{ topic.Name }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="fs-13 dark-text fw-600 italic m-0" style="margin-left: 16px" v-else>
        Batches are not avaliable for selected course.
      </p>
      <div style="padding: 15px">
        <button
          class="next-button pointer"
          :class="{ deactive: batchIdList.length === 0 }"
          @click="next()"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      courseBatchList: [],
      batchIdList: [],
      displayBatchName: [],
      searchValue: "",
      testMainData: "",
      showBatchSelect: true,
      count: null,
      closeComponent: this.closeSectionComponent,
      batchListNew: [],
    };
  },
  props: {
    closeSectionComponent: Number,
    batchList: String,
    testData: String,
  },
  beforeUnmount() {
    this.closeComponent = undefined;
  },
  mounted() {
    this.emitter.on("close-section-component", (data) => {
      this.showCourse = data.showCourse;
      this.showTest = data.showTest;
      this.showBatchSelect = data.showBatchSelect;
      this.showManageSection = data.showManageSection;
      this.showPublishTest = data.showPublishTest;
    });
    this.emitter.on("open-course-component", (data) => {
      this.showCourse = data.showCourse;
      this.showTest = data.showTest;
      this.showBatchSelect = data.showBatchSelect;
      this.showManageSection = data.showManageSection;
      this.showPublishTest = data.showPublishTest;
    });
    this.emitter.on("open-test-component", (data) => {
      this.showCourse = data.showCourse;
      this.showTest = data.showTest;
      this.showBatchSelect = data.showBatchSelect;
      this.showManageSection = data.showManageSection;
      this.showPublishTest = data.showPublishTest;
    });
    this.emitter.on("open-section-component", (data) => {
      this.showCourse = data.showCourse;
      this.showTest = data.showTest;
      this.showBatchSelect = data.showBatchSelect;
      this.showManageSection = data.showManageSection;
      this.showPublishTest = data.showPublishTest;
    });
    this.emitter.on("change-manage-section-data", (data) => {
      this.testMainData = "";
      this.batchIdList = [];
      this.displayBatchName = [];
      if (data.isDataChanged === true) {
        this.testMainData = JSON.parse(data.testData);
      }
    });
    this.emitter.on("batch-list-data", (data) => {
      this.courseBatchList = JSON.parse(data.batchList);
    });
  },
  created() {
    this.batchIdList = [];
    if (this.batchList !== undefined) {
      this.courseBatchList = JSON.parse(this.batchList);
    }
    if (this.testData !== undefined) {
      this.testMainData = JSON.parse(this.testData);
    }
    const batchIdProps = Number(this.$route.params.batchIdProps);
    if (batchIdProps) {
      const batchDetail = this.filteredBatchList.find((item) => item.Id === batchIdProps);
      if (batchDetail) {
        this.selectBatches(batchDetail);
      }
    }
    this.groupBatchesByAffiliation(this.courseBatchList);
  },
  computed: {
    filteredBatchList() {
      return this.batchListNew.map((item) => ({
        ...item,
        batches: item.batchList.filter((chapter) =>
          chapter.Name.toLowerCase().includes(this.searchValue.toLowerCase()),
        ),
      }));
    },
  },
  methods: {
    selectBatches(data) {
      if (this.batchIdList.includes(data.Id)) {
        const index = this.batchIdList.indexOf(data.Id);
        const index1 = this.displayBatchName.indexOf(data.Name);
        this.batchIdList.splice(index, 1);
        this.displayBatchName.splice(index1, 1);
      } else {
        this.batchIdList.push(data.Id);
        this.displayBatchName.push(data.Name);
      }
      this.testMainData.batches = this.batchIdList.join(",");
    },
    selectAllBatches(event) {
      if (event.target.checked) {
        this.batchIdList = this.courseBatchList.map((obj) => obj.Id);
        this.testMainData.batches = this.batchIdList.join(",");
        this.displayBatchName = this.courseBatchList.map((obj) => obj.Name);
      } else {
        this.batchIdList = [];
        this.testMainData.batches = "";
        this.displayBatchName = [];
      }
    },
    next() {
      this.showBatchSelect = false;
      this.count += 1;
      this.emitter.emit("close-batch-component", {
        showCourse: false,
        showTest: false,
        showManageSection: false,
        showBatchSelect: false,
        showPublishTest: true,
      });
      this.emitter.emit("changes-batch-data", {
        isDataChanged: true,
        testMainData: JSON.stringify(this.testMainData),
      });
      this.$emit("closeBatch", this.count);
      this.$emit(
        "selectedBatchIds",
        this.batchIdList.map((a) => a.Id),
      );
      this.$emit("closeBatchSection", false);
      this.$emit("testFinalData", this.testMainData);
    },
    openBatchComponent() {
      this.showBatchSelect = true;
      this.emitter.emit("open-batch-component", {
        showCourse: false,
        showTest: false,
        showManageSection: false,
        showBatchSelect: true,
        showPublishTest: false,
      });
    },
    groupBatchesByAffiliation(chapterlist) {
      const batchListTemp = {};

      // eslint-disable-next-line no-restricted-syntax
      for (const chapter of chapterlist) {
        const affiliationId = chapter.AffiliationId;

        if (!batchListTemp[affiliationId]) {
          batchListTemp[affiliationId] = {
            AffiliationId: affiliationId,
            AffiliationName: chapter.AffiliationName,
            batchList: [],
          };
        }

        batchListTemp[affiliationId].batchList.push({
          Id: chapter.Id,
          Code: chapter.Code,
          Name: chapter.Name,
          AffiliationId: affiliationId,
        });
      }

      this.batchListNew = Object.values(batchListTemp);
    },
    checkIsAllBatchSelected(index, clusterId) {
      debugger;
      let returnData = false;
      const batchListTemp = this.batchIdList.filter((a) => a.AffiliationId === clusterId);
      const batchObj = this.batchListNew.filter((a) => a.AffiliationId === clusterId)[0];
      // ///this.tempClusterList = this.clusterListNew.filter((chapter) => batchListTemp.includes(chapter.Id));
      // const subjectChapters = this.tempClusterList.find((a) => a.CourseChapterId === clusterId);
      // const selectedChapters = this.selectedKscClusterIds.filter((a) => a.CourseChapterId === clusterId);
      if (
        batchListTemp != null &&
        batchObj.batchList != null &&
        batchObj.batchList.length > 0 &&
        batchListTemp.length > 0
      ) {
        if (batchObj.batchList.length === batchListTemp.length) {
          returnData = true;
        }
      }
      return returnData;
    },
    selectAllBatch(event, chapterData, index, subjectId) {
      debugger;
      if (event.target.checked) {
        const topicList = chapterData.batchList.flat();
        topicList.forEach((item) => {
          if (!this.batchIdList.includes(item)) {
            this.batchIdList.push(item);
          }
        });
        this.displayBatchName = this.batchIdList.map((obj) => obj.Name);
        this.testMainData.batches = this.batchIdList.map((obj) => obj.Id).join(",");
      } else {
        // eslint-disable-next-line no-plusplus, no-lonely-if
        if (this.batchIdList.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let i = this.batchIdList.length - 1; i >= 0; i--) {
            if (this.batchIdList[i].AffiliationId === subjectId) {
              this.batchIdList.splice(i, 1);
            }
          }
        }
        this.displayBatchName = [];
        this.testMainData.batches = "";
      }
      this.checkIsAllBatchSelected(index, chapterData.AffiliationId);
    },
    onSelectBatch(selectedBatch, index) {
      debugger;
      this.chapterCountList = [];
      if (this.batchIdList.includes(selectedBatch)) {
        const index1 = this.batchIdList.indexOf(selectedBatch);
        const index2 = this.displayBatchName.indexOf(selectedBatch.Name);
        this.displayBatchName.splice(index2, 1);
        this.batchIdList.splice(index1, 1);
      } else {
        this.batchIdList.push(selectedBatch);
        this.displayBatchName.push(selectedBatch.Name);
      }
      this.testMainData.batches = this.batchIdList.map((obj) => obj.Id).join(",");
      this.checkIsAllBatchSelected(index, selectedBatch.AffiliationId);
    },
  },
};
</script>

<style scoped>
span {
  margin: 0;
}

.mt-2 {
  margin-top: -2px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.pd20 {
  padding: 20px !important;
}

.flex {
  display: flex !important;
}

.flex-row {
  flex-direction: row !important;
}

.content-btw {
  justify-content: space-between;
}

.flex-column {
  flex-direction: column !important;
}

.flex-end {
  justify-content: flex-end !important;
}

.fs-12 {
  font-size: 12 !important;
}

.fs-14 {
  font-size: 14 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.main-section {
  border: 1px solid #e0e4f0;
}

.close-component-heading {
  height: 48px;
  background: #ffffff;
  border-bottom: 1px solid #e0e4f0;
  padding-left: 22px;
  padding-top: 14px;
  overflow-x: hidden;
}

.close-section-button {
  width: 20px;
  height: 20px;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #3751ff;
  background: #ffffff;
}

.close-section-heading {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #3751ff;
  margin-left: 18px;
  min-width: 100px;
}

.blk-text {
  color: #404458 !important;
}

.createtest {
  min-width: 500px;
  width: 100%;
  border-radius: 5px;
  margin-right: 20px;
}

.component-heading {
  background: #ffffff;
  height: 65px;
  padding: 15px;
  border-bottom: 1px solid #e0e4f0;
}

.section-button {
  border: none;
  width: 33px;
  height: 33px;
  background: #3751ff;
  border-radius: 3px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-right: 12px;
}

.content-heading {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #3751ff;
}

.content-main-section {
  height: 626px;
}

.content-left-section {
  background: #ffffff;
  width: 307px !important;
  border-right: 1px solid #e0e4f0;
}

.content-right-section {
  background: #f7f8fd;
  width: 100%;
}

.content-selection-button {
  width: 223px;
  height: 50px;
  background: #f7f8fd;
  border-radius: 4px;
  margin-top: 10px;
  margin-right: 24px;
  padding-top: 8px;
  padding-left: 15px;
  position: relative;
  border: none;
}

.content-selection-button.active {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #3751ff;
}

.content-selection-button.active::after {
  position: absolute;
  width: 3px;
  height: 50px;
  content: "";
  background: #3751ff;
  right: 0;
  top: 0;
  border-radius: 0 2px 2px 0;
}

.course-button {
  height: 28px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 18px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #333333;
  cursor: pointer;
}

.selected {
  background: #3751ff !important;
  color: #ffffff;
}

.next-button {
  width: 100px;
  height: 28px;
  background: #37841c;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.next-button.deactive {
  background: grey !important;
  color: #ffffff !important;
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.seperator {
  width: 0px;
  height: 28px;
  border-left: 2px solid #e0e4f0;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 3px;
}

.subject-name {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #3751ff;
}

.subject-name-header {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
}

.topic-name {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #3751ff;
}

.select-all-box {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 4px;
  padding: 11px;
  height: 40px;
}

.select-all-checkbox {
  border-radius: 3px;
  width: 18px;
  height: 18px;
  border: 2px solid #e0e4f0 !important;
  border-radius: 3px;
}

::-webkit-scrollbar {
  display: none;
}

input[type="text"]:not(.browser-default):focus:not([readonly]) {
  box-shadow: none;
  border-bottom: none;
  height: 39px;
}

input[type="text"]:not(.browser-default):focus:not([readonly]) {
  border-bottom: none !important;
  height: 39px;
}

input[type="text"]:not(.browser-default) {
  padding-right: 41px;
  width: 100%;
  border-bottom: none !important;
  height: 39px !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  /* margin: 0px !important; */
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: unset !important;
  opacity: 1 !important;
  pointer-events: all !important;
  /* height: 50px;
    width: 18px;
    margin-right: 10px; */
}

input[type="number"]:not(.browser-default) {
  border-bottom: none;
  width: 95%;
  /* margin: 0px !important; */
  height: 39px !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
}

input[type="number"]:not(.browser-default):focus:not([readonly]) {
  border-bottom: none;
  box-shadow: none;
}

input[type="date"]:not(.browser-default) {
  border-bottom: none;
  width: 92% !important;
}

input[type="date"]:not(.browser-default):focus:not([readonly]) {
  border-bottom: none;
  box-shadow: none;
  width: 92% !important;
}

input[type="time"]:not(.browser-default) {
  border-bottom: none;
  width: 92% !important;
}

input[type="time"]:not(.browser-default):focus:not([readonly]) {
  border-bottom: none;
  box-shadow: none;
  width: 92% !important;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: unset !important;
  opacity: 1 !important;
  pointer-events: all !important;
  height: 50px;
  width: 18px;
  margin-right: 10px;
}

.select-all {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e4f0;
  border-radius: 4px;
  background: #ffffff;
  width: 113px;
  height: 40px;
}

.course-btn-container {
  max-height: 300px;
  overflow-y: auto;
}

.course-button {
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 18px;
  padding: 5px 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #333333;
  cursor: pointer;
}

.content-search {
  margin-left: 10px;
  margin-right: 10px;
  height: 40px;
  border: 1px solid #e0e4f0;
  background: white;
  border-radius: 4px;
  margin-top: 20px;
  justify-content: center;
  align-content: center;
  position: relative;
  border: none;
}
</style>
