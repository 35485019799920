<template>
    <div class="close-test-details flex pointer items-center" v-if="showTest === false" @click="openTestComponent()">
        <button class="close-section-button">2</button>
        <span class="close-section-heading">Test Details:</span>
        <div class="flex fs-12 scroll-horizontal items-center" style="">
            <div v-if="testDisplayProperties.testName !== ''" class="flex items-center">
                <span class="fw-600 nowrap" >Test Name</span>&ensp;
                <span class="fw-400 nowrap">{{this.testName}}</span>
            </div>
            <div class="seperator" v-if="testDisplayProperties.duration !== ''"></div>
            <div v-if="testDisplayProperties.duration !== ''"  class="flex items-center">
                <span class="fw-600" >Duration</span>&ensp;
                <span class="fw-400 nowrap">{{testDisplayProperties.duration}}</span>
            </div>
            <div class="seperator" v-if="testDisplayProperties.questionType.length !== 0 "></div>
            <div v-if="testDisplayProperties.questionType.length !== 0"  class="flex items-center">
                <span class="fw-600 nowrap">Question Type</span>&ensp;
                <span class="fw-400 nowrap fs-12 blk-text" v-for="(data,index) in testDisplayProperties.questionType" :key="index">{{data}} &nbsp;</span>
            </div>
            <div class="seperator" v-if="testDisplayProperties.difficultyLevel.length !== 0 "></div>
            <div v-if="testDisplayProperties.difficultyLevel.length !== 0 "  class="flex items-center">
                <span class="fw-600 nowrap">Difficulty Level</span>&ensp;
                <span class="fw-400 nowrap">{{testDisplayProperties.difficultyLevel}}</span>
            </div>
        </div>
    </div>
    <div class="test-details" v-if="showTest === true">
        <div class="testdetails-heading flex flex-row">
            <button class="test-section-button">2</button>
            <div class="flex flex-column mt-2">
                <span class="test-heading">Test Details</span>
                <div class="flex fs-12 mt-2 scroll-horizontal items-center" :style="`max-width: ${screenWidth}px`" >
                    <div v-if="testDisplayProperties.testName !== ''" class="flex items-center">
                        <span class="fw-600" >Test Name:</span>&ensp;
                        <span class="fw-400">{{this.testName}}</span>
                    </div>
                    <div class="seperator" v-if="testDisplayProperties.duration !== ''"></div>
                    <div v-if="testDisplayProperties.duration !== ''" class="flex items-center">
                        <span class="fw-600" >Duration:</span>&ensp;
                        <span class="fw-400">{{testDisplayProperties.duration}} mins</span>
                    </div>
                    <div class="seperator" v-if="testDisplayProperties.questionType.length !== 0 " ></div>
                    <div v-if="testDisplayProperties.questionType.length !== 0" class="flex items-center">
                        <span class="fw-600" >Question Type:</span>&ensp;
                        <span class="fw-400 fs-12 blk-text" v-for="(data,index) in testDisplayProperties.questionType" :key="index">{{data}} &nbsp;</span>
                    </div>
                    <div class="seperator" v-if="testDisplayProperties.difficultyLevel.length !== 0 "></div>
                    <div v-if="testDisplayProperties.difficultyLevel.length !== 0 " class="flex items-center">
                        <span class="fw-600" >Difficulty Level</span>&ensp;
                        <span class="fw-400">{{testDisplayProperties.difficultyLevel}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="test-main-section">
            <div class="section-container flex flex-column">
                <div class="flex flex-column">
                    <span class="fs-14 fw-700 mb8">Test Name <span class="red-text">*</span></span>
                    <input type="text" placeholder="Test Name" id="test-input" v-model="testName"/>
                </div>
                <div class="flex flex-column">
                  <span class="fs-14 fw-700 mb8">Test Category</span>
                  <select  class="school-select pointer" id="select-input"  v-model="testCategoryId"  @change="selectTestCategory()">
                    <option value=null selected disabled>Select Test Category</option>
                    <option v-for="category in categoryList" :key="category.InstituteTestCategoryId" :value="category.InstituteTestCategoryId">
                        {{ category.CategoryValue }}
                    </option>
                  </select>
                </div>
                <div class="mt20">
                    <span class="fs-14 fw-700 mb8">Duration <span class="red-text">*</span></span>
                    <div class="flex flex-row mt8 flex-wrap">
                        <button class="duration-button" :class="{selected: testDisplayProperties.duration === '30'}" @click="selectTime('30')" value="30">30min</button>
                        <button class="duration-button" :class="{selected: testDisplayProperties.duration === '45'}" @click="selectTime('45')" value="45">45min</button>
                        <button class="duration-button" :class="{selected: testDisplayProperties.duration === '60'}" @click="selectTime('60')" value="60">60min</button>
                        <button class="duration-button" :class="{selected: testDisplayProperties.duration === '90'}" @click="selectTime('90')" value="90">1hr 30min</button>
                        <button class="duration-button" :class="{selected: testDisplayProperties.duration === '120'}" @click="selectTime('120')" value="120">2hrs</button>
                        <button class="duration-button" :class="{selected: testDisplayProperties.duration === '180'}" @click="selectTime('180')" value="180">3hrs</button>
                        <button class="duration-button" :class="{selected: testDisplayProperties.duration === '200'}" @click="selectTime('200')" value="200">3hr 20min</button>
                    </div>
                </div>
                <div class="mt20 ">
                    <span class="fs-14 fw-700 mb8">Question Type <span class="red-text">*</span></span>
                    <div class="flex flex-row mt8">
                      <div v-if="questionList.length > 0">
                        <button class="duration-button" :class="{selected: testDetailsData.questionType.includes(ques)}" @click="selectQuestionType(ques)" v-for="(ques,index) in questionList" :key="index">{{ques.QuestionType}}</button>
                      </div>
                      <h1 class="fs-13 fw-600 gray-text m-0" v-else>There is no any Question type for selected chapters, please choose another chapter.</h1>
                    </div>
                </div>
                <div class="mt20">
                    <span class="fs-14 fw-700 mb8">Difficulty Level <span class="red-text">*</span></span>
                    <div class="flex flex-row mt8">
                        <button class="duration-button" :class="{selected: testDetailsData.difficultyName === 'Easy'}" @click="selectDifficultyLevel(1)" value="1">Easy</button>
                        <button class="duration-button" :class="{selected: testDetailsData.difficultyName === 'Medium'}" @click="selectDifficultyLevel(2)" value="2">Medium</button>
                        <button class="duration-button" :class="{selected: testDetailsData.difficultyName === 'Hard'}" @click="selectDifficultyLevel(3)" value="3">Hard</button>
                    </div>
                </div>
                <div class="mt20">
                    <button class="next-button" :class="{deactive: testDetailsData.difficultyLevel === null || testName === '' || testDetailsData.duration === '' || testDetailsData.questionType.length === 0 || questionList.length === 0}" @click="next()">Next</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import Mobileapi from "../Mobileapi";

export default {
  data() {
    return {
      categoryList: [],
      testCategoryId: null,
      testName: "",
      questionList: [],
      chapterIds: [],
      data: [],
      startDate: moment().add(30, "minutes").toDate(),
      endDate: moment().add(1, "days").toDate(),
      startdate: moment(this.startDate).format("DD-MM-YYYY HH:MM:SS"),
      enddate: moment(this.endDate).add(1, "days").format("DD-MM-YYYY HH:MM:SS"),
      testDisplayProperties: {
        testName: "",
        duration: "",
        questionType: [],
        difficultyLevel: "",
      },
      testDetailsData: {
        testName: "",
        testCategoryId: null,
        duration: "",
        questionType: [],
        difficultyLevel: null,
        difficultyName: "",
        startDate: "",
        endDate: "",
      },
      showTest: true,
      closeComponent: this.courseComponentClosed,
      count: null,
      isDataChanged: false,
      screenWidth: "",
    };
  },
  props: {
    courseComponentClosed: Number,
    courseDetails: String,
  },
  mounted() {
    this.screenWidth = (window.innerWidth - 60);
    this.emitter.on("close-course-component", (data) => {
      this.showCourse = data.showCourse;
      this.showTest = data.showTest;
      this.showBatchSelect = data.showBatchSelect;
      this.showManageSection = data.showManageSection;
      this.showPublishTest = data.showPublishTest;
    });
    this.emitter.on("open-course-component", (data) => {
      this.showCourse = data.showCourse;
      this.showTest = data.showTest;
      this.showBatchSelect = data.showBatchSelect;
      this.showManageSection = data.showManageSection;
      this.showPublishTest = data.showPublishTest;
    });
  },
  created() {
    //
    this.screenWidth = (window.innerWidth - 60);
    if (this.courseDetails !== undefined) {
      this.$store.dispatch("showLoader", true);
      this.data = JSON.parse(this.courseDetails);
      this.testName = `${this.data.courseName}: | ${this.data.subjects.map((obj) => obj.SubjectName).join(", ")} - ${moment(new Date()).format("D MMM")}, 30 mins`;
      this.selectTime("30");
      this.selectDifficultyLevel(1);
      if (this.data.length !== 0) {
        this.chapterIds = (this.data.chapters.map((obj) => obj.ChapterId));
        this.chapterIds = [...new Set(this.chapterIds)];
      }
      const data = {
        ChapterIds: this.chapterIds.join(","),
        TopicId: 0,
        SubjectId: 0,
        CourseId: 0,
        ClassId: 0,
        UserId: 0,
      };
      Mobileapi.getQuestionTypeAvailableForTest(data, (response) => {
        // eslint-disable-next-line eqeqeq
        if (response.message === "Success") {
          this.$store.dispatch("showLoader", false);
          this.questionList = response.data;
          this.selectQuestionType(this.questionList[0]);
        } else {
          this.questionList = [];
          this.$store.dispatch("showLoader", false);
        }
      });
      Mobileapi.getTestCategory((response) => {
        if (response.responseCode === 200) {
          this.categoryList = response.data;
        }
      });
    }
  },
  // this.testDetailsData.questionType.push(this.questionList[0]);
  methods: {
    // showDetails(data) {
    //   console.log(JSON.parse(data));
    // },
    selectTime(duration) {
      this.testName = `${this.data.courseName}: | ${this.data.subjects.map((obj) => obj.SubjectName).join(", ")} - ${moment(new Date()).format("D MMM")}, ${duration} mins`;
      this.testDetailsData.testName = this.testName;
      this.testDisplayProperties.testName = this.testName;
      this.testDetailsData.duration = duration;
      this.testDisplayProperties.duration = duration;
    },
    selectTestCategory() {
      this.testDetailsData.testCategoryId = this.testCategoryId;
    },
    selectQuestionType(data) {
      this.isDataChanged = true;
      if (this.testDetailsData.questionType.includes(data)) {
        const index = this.testDetailsData.questionType.indexOf(data);
        const index1 = this.testDisplayProperties.questionType.indexOf(data.QuestionType);
        this.testDetailsData.questionType.splice(index, 1);
        this.testDisplayProperties.questionType.splice(index1, 1);
      } else {
        this.testDetailsData.questionType.push(data);
        this.testDisplayProperties.questionType.push(data.QuestionType);
      }
    },
    selectDifficultyLevel(level) {
      let data = {};
      if (level === 1) {
        data = {
          difficultyLevel: 1,
          difficultyGroup: "Easy",
        };
      }
      if (level === 2) {
        data = {
          difficultyLevel: 1,
          difficultyGroup: "Medium",
        };
      }
      if (level === 3) {
        data = {
          difficultyLevel: 2,
          difficultyGroup: "Hard",
        };
      }
      this.testDetailsData.difficultyLevel = data.difficultyLevel;
      this.testDetailsData.difficultyName = data.difficultyGroup;
      this.testDisplayProperties.difficultyLevel = data.difficultyGroup;
    },
    next() {
      this.count += 1;
      if (this.testName !== `${this.data.courseName}: | ${this.data.subjects.map((obj) => obj.SubjectName).join(", ")} - ${moment(new Date()).format("D MMM")}, ${this.testDisplayProperties.duration} mins`) {
        this.testDetailsData.testName = this.testName;
        this.testDisplayProperties.testName = this.testName;
      }
      this.startDate = moment().add(30, "minute").format("DD-MM-YYYY h:mma");
      this.endDate = moment().add(7, "days").format("DD-MM-YYYY h:mma");
      // this.startdate = moment(this.startDate).format("DD-MM-YYYY HH:MM:SS");
      // this.enddate = moment(this.endDate).add(1, "days").format("DD-MM-YYYY HH:MM:SS");
      this.testDetailsData.startDate = this.startdate;
      this.testDetailsData.endDate = this.enddate;
      this.testDetailsData.testCategoryId = this.testCategoryId;
      this.showTest = false;
      this.emitter.emit("close-test-component", {
        showCourse: false,
        showTest: false,
        showBatchSelect: undefined,
        showManageSection: true,
        showPublishTest: false,
      });
      if (this.isDataChanged === true) {
        this.emitter.emit("test-details-changed", {
          isDataChanged: true,
          courseContentDetails: this.data,
          testData: this.testDetailsData,
        });
      }
      this.$emit("closeTest", this.count);
      this.$emit("testDetails", this.testDetailsData);
      this.$emit("closeTestPart", false);
      this.isDataChanged = false;
    },
    openTestComponent() {
      this.showTest = true;
      this.emitter.emit("open-test-component", {
        showCourse: false,
        showTest: true,
        showBatchSelect: undefined,
        showManageSection: undefined,
        showPublishTest: false,
      });
    },
  },
  beforeUnmount() {
    this.closeComponent = undefined;
  },
};
</script>

<style scoped>
span{
    margin: 0;
}
.mt-2{
    margin-top: -2px !important;
}
.mt10 {
    margin-top: 10px !important;
}
.mt20{
    margin-top: 20px !important;
}
.mt8{
    margin-top: 8px !important;
}
.pd20{
    padding: 20px !important;
}
.flex {
    display: flex !important;
}
.flex-row{
    flex-direction: row !important;
}
.flex-column{
    flex-direction: column !important;
}
.flex-end{
    justify-content: flex-end !important;
}
.flex-wrap{
  flex-wrap: wrap;
}
.fs-12 {
    font-size: 12 !important;
}
.fs-14 {
    font-size: 14 !important;
}
.fw-400{
    font-weight: 400 !important;
}
.fw-600{
    font-weight: 600 !important;
}
.fw-700{
    font-weight: 700 !important;
}
.mb8{
    margin-bottom: 8px !important;
}
.close-test-details{
    height: 48px;
    background: #FFFFFF;
    border-bottom: 1px solid #E0E4F0;
    padding-left: 22px;
    /* padding-top: 14px; */
}
.close-section-button{
    width: 20px;
    height: 20px;
    border: 1px solid #E0E4F0;
    border-radius: 3px;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #3751FF;
    background: #FFFFFF;
}
.close-section-heading{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #3751FF;
    margin-left: 18px;
    min-width: 100px;
}
.testdetails-heading{
    background: #FFFFFF;
    height: 65px;
    padding: 15px;
    border-bottom: 1px solid #E0E4F0;
}
.test-section-button{
    border: none;
    width: 33px;
    height: 33px;
    background: #3751FF;
    border-radius: 3px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    margin-right: 12px;
}
.test-heading{
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #3751FF;
}
.seperator{
    width: 0px;
    height: 15px;
    border-left: 2px solid #E0E4F0;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 3px;
}
.test-main-section {
    /* width: 938px; */
    height: 536px;
    background: #F7F8FD;
    border-left: 1px solid #E0E4F0;
    border-right: 1px solid #E0E4F0;
    border-bottom: 1px solid #E0E4F0;
}
.section-container{
    padding-left: 65px;
    padding-top: 20px;
}
.duration-button{
    height: 28px;
    background: #FFFFFF;
    border: 1px solid #E0E4F0;
    border-radius: 18px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-right:10px;
    margin-bottom: 10px;
    color: #333333;
    cursor: pointer;
}
.next-button {
    width: 100px;
    height: 28px;
    background: #37841C;
    border-radius: 5px;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
}

.next-button.deactive {
  background: grey !important;
  color: #FFFFFF !important;
  cursor: not-allowed !important;
  pointer-events: none !important;
}
.selected{
    background: #3751FF !important;
    color: #FFFFFF;;
}
input[type=text]:not(.browser-default):focus:not([readonly]) {
    box-shadow: none;
    border-bottom: none;
    height: 39px;
}

input[type=text]:not(.browser-default):focus:not([readonly]) {
    border-bottom: none !important;
    height: 39px;
}

input[type=text]:not(.browser-default) {
    padding-right: 41px;
    max-width: 200px;
    width: 100%;
    border-bottom: none !important;
    height: 39px !important;
    color: black !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    padding-left: 12px !important;
    background: #FFFFFF;
    border: 1px solid #E0E4F0;
    border-radius: 4px;
    /* margin: 0px !important; */
}
#test-input{
  padding-right: 12px;
    max-width: 234px;
    border-bottom: 1px solid #E0E4F0 !important;
}
.school-select {
  height: 28px;
  padding: 0;
  padding-left: 12px;
  color: #333333 !important;
  font-size: 12px;
  font-weight: 600;
  background: #FFFFFF;
  border: 1px solid #E0E4F0 !important;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 5px;
  margin-right: 10px;
  max-width: 260px;
}
select {
  border: 1px solid #808080 !important;
  background-color: white !important;
  height: 43px;
  border-radius: 4px;
  font-size: 14px;
  color: #808080;
  display: block;
}
select:focus {
  outline: none;
}
#select-input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url("../assets/Vector4.svg") no-repeat right;
  background-size: 38px 6px;
  height: 43px;
  padding-left: 7px;
  line-height: 20px;
}
option:disabled {
  color: rgb(170, 170, 170) !important;
  font-weight: 500 !important;
}
select {
  overflow: hidden;
}
select::-webkit-scrollbar {
  width: 0;
}
select option {
  color: #333333 !important;
}
</style>
